.section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 800;
  background-color: rgb(4, 4, 16, 0.8);
}

.content {
  background: #e8e8e8;
  color: #000;
  font-size: 0.2rem;
  border-radius: 0.2rem;
  padding: 0.2rem;
  width: 85%;
  left: 0;
  right: 0;
  margin: auto;
}

.top .content {
  margin-top: 0.6rem;
}

.top .arrow {
  background: url('./arrow-up.svg') no-repeat center;
  background-size: contain;
  width: 0.4rem;
  height: 0.6rem;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
}

.bottom .content {
  position: fixed;
  bottom: 0.65rem;
}

.bottom .arrow {
  background: url('./arrow-down.svg') no-repeat;
  background-size: contain;
  width: 0.4rem;
  height: 0.6rem;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}