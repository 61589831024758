.wrapper .content {
  width: 100vw;
  background-color: #fff;
  position: relative;
  padding-bottom: calc(var(--fixed-footer-bottom) + 0.12rem);
}

@supports (padding: max(0px)) {
  .wrapper {
    padding-bottom: max(var(--fixed-footer-bottom), 8px);
  }
}

.wrapper .header {
  width: 100%;
  height: 0.4rem;
  position: relative;
}

.wrapper .header .close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.16rem;
  padding: 0.12rem;
}

.wrapper  .scrollContent {
  max-height: calc(75vh - 0.4rem);
  overflow-y: scroll;
  padding-bottom: 0.7rem;
}

.bodyClass {
  overflow: hidden !important;
}

.fixedBar {
  position: fixed;
  left: 0;
  bottom: 0;
}

.sizeChartPadding {
  padding: 0.24rem;
}