.wrapper {
  background: white;
  position: relative;
}

.wrapper .postImageGroup {
  margin: 0;
  padding: 0.1rem 0.12rem;
}

.wrapper .postWrapper .image {
  position: relative;
  width: 1.3rem;
  height: 2.32rem;
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .postWrapper .image::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0.36rem;
  height: 0.36rem;
  background: url('https://img.flamingo.shop/p/show/e59a3adca3ae4a6f921b66e8f6418b78.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .postWrapper.box:not(:last-of-type) {
  margin-right: 0.08rem !important;
}

.wrapper .postImageGroup::-webkit-scrollbar {
  display: none;
}