.starOptionName {
  display: inline-flex;
  align-items: center;
  font-size: 0.12rem;
}

.starOptionName.inDesktop {
  font-size: 0.14rem;
}

.starIcon {
  margin-left: 0.01rem;
  font-size: 0.12rem;
  color: #ffaa00;
}