.mask {
  background: var(--neutral900);
  opacity: 0.9;
}

.wrapper {
  position: relative;
  -webkit-animation: showAnimation 0.6s linear;
  animation: showAnimation 0.6s linear;
}

.wrapper.scaleAnimation {
  -webkit-animation: showScaleAnimation 1s linear;
  animation: showScaleAnimation 1s linear;
}

@-webkit-keyframes showAnimation {
  from {
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showAnimation {
  from {
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes showScaleAnimation {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes showScaleAnimation {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.wrapper .closeIcon {
  width: 0.36rem;
  height: 0.36rem;
  background: url('https://img.flamingo.shop/p/show/4f85865a726443e8a2ec2c83e1c99a8a.png') no-repeat center center;
  background-size: 100%;
  position: absolute;
  top: -0.5rem;
  right: 0.12rem;
  z-index: 1;
}

.wrapper .drawContent {
  position: relative;
  width: 2.9rem;
  height: 2.9rem;
  background: url('https://img.flamingo.shop/p/show/761c546a90cf4940a523bc7a3889342c.png') no-repeat center center;
  background-size: 100%;
}

.wrapper .drawContent::after {
  position: absolute;
  left: 0.27rem;
  top: -1rem;
  content: ' ';
  width: 2.42rem;
  height: 1.82rem;
  background: url('https://img.flamingo.shop/p/show/ba271bca7ff0457884644db73e8d8c66.png') no-repeat center center;
  background-size: 100%;
}

.wrapper .drawContent .content {
  position: absolute;
  left: 0.31rem;
  top: 0.25rem;
  width: 2.38rem;
  height: 2.42rem;
  background: url('https://img.flamingo.shop/p/show/33bd319ac4ba42338a4ccd3442ef8fd1.png') no-repeat center center;
  background-size: 100%;
  z-index: 1;
}

.wrapper.de .drawContent .content {
  background: url('https://img.flamingo.shop/p/show/bda1180010fc4a6b9ea30f2e5f704aa6.png') no-repeat center center;
  background-size: 100%;
}

.wrapper .drawContent .shinning {
  position: absolute;
  left: 0.07rem;
  top: 0.07rem;
  width: 1.35rem;
  height: 1.35rem;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0.1rem;
}

.wrapper .chances {
  color: white;
  font-size: 0.18rem;
  line-height: 0.25rem;
  text-align: center;
  margin: 0.15rem 0 0.05rem 0;
}

.wrapper .chances span {
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  color: #FD6D1C;
  font-weight: 600;
}

@-webkit-keyframes display {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  30% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes display {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  30% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.wrapper .btn {
  position: relative;
  width: 3rem;
  height: 0.6rem;
  border-radius: 0.8rem;
  background: linear-gradient(89.75deg, #FF3951 3.39%, #FE8C3A 90.87%);
  box-shadow: 0 0 0.16rem 0 #FFFFFF inset;
  color: white;
  font-size: 0.2rem;
  font-weight: 600;
  line-height: 0.6rem;
  text-align: center;
  -webkit-animation: display 3s ease;
  animation: display 3s ease;
}

.wrapper .hand {
  position: absolute;
  background: url('https://img.flamingo.shop/p/show/629f5015698d43998cf180a7ae094a44.png') center center no-repeat;
  background-size: contain;
  width: 0.63rem;
  height: 0.74rem;
  left: 2rem;
  top: 0.26rem;
  -webkit-animation: moving 1s ease infinite normal;
  animation: moving 1s ease infinite normal;
}

@-webkit-keyframes moving {
  0% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  20% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  40% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes moving {
  0% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  20% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  40% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.resultWrapper {
  position: relative;
}

.resultWrapper .resultContent {
  width: 3.75rem;
  height: 3.69rem;
  background: url('https://img.flamingo.shop/p/show/753f3308bca141d6b28da3f3e67e0718.png') no-repeat center center;
  background-size: 100%;
  -webkit-animation: zoom 1s ease-in-out forwards;
  animation: zoom 1s ease-in-out forwards;
  opacity: 0;
}

.resultWrapper.de .resultContent {
  background: url('https://img.flamingo.shop/p/show/84e8add0f1f04e2fa289ca166be8cd02.png') no-repeat center center;
  background-size: 100%;
}

.resultWrapper .resultContent.webToAppGiftContent {
  -webkit-animation: none;
  animation: none;
  opacity: 1;
  overflow: hidden;
}

.resultWrapper .resultContent .content {
  position: absolute;
  left: 0.58rem;
  top: 1.3rem;
  width: 2.6rem;
  padding: 0.1rem;
}

.resultWrapper .resultContent .content .title {
  font-size: 0.32rem;
  font-weight: 700;
  line-height: 0.39rem;
  text-align: center;
  color: #FD6D1C;
  margin-bottom: 0.1rem;
}

.resultWrapper .resultContent .content .rule,
.resultWrapper .resultContent .content .time {
  font-size: 0.12rem;
  line-height: 0.17rem;
  text-align: center;
  color: #CF924F;
  margin-bottom: 0.05rem;
}

.resultWrapper .bond {
  position: absolute;
  left: 0;
  top: 0;
  width: 3.56rem;
  height: 3.35rem;
  background: url('https://img.flamingo.shop/p/show/1b21d9575bcf4a2b86a55c01931df7aa.png') no-repeat center center;
  background-size: 100%;
  -webkit-animation: zoomAndFade 0.6s linear;
  animation: zoomAndFade 0.6s linear;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
  opacity: 0;
  z-index: -1;
}

.resultWrapper .bond.delayBond {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  -webkit-transform: translateY(0.3rem);
  transform: translateY(0.3rem);
}

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoom {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes zoomAndFade {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 1;
  }
}

@keyframes zoomAndFade {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 1;
  }
}

.resultWrapper .resultContent.giftContent {
  width: 3.75rem;
  height: 3.69rem;
  background: url('https://img.flamingo.shop/p/show/90dc5440e602429e9ed4838bdca7e5bd.png') no-repeat center center;
  background-size: 100%;
  -webkit-animation: zoom 1s ease-in-out forwards;
  animation: zoom 1s ease-in-out forwards;
  opacity: 0;
}

.resultWrapper.de .resultContent.giftContent {
  background: url('https://img.flamingo.shop/p/show/dc3f5b8fe1524ceb8c508be8aa41754d.png') no-repeat center center;
  background-size: 100%;
}

.resultWrapper .resultContent.giftContent .freeGift {
  position: absolute;
  left: 1.54rem;
  top: 1.5rem;
}

.resultWrapper .resultContent.giftContent .freeGift .giftIcon {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.08rem;
}

.resultWrapper .resultContent.giftContent .freeGift .title {
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 0.2rem;
  text-align: center;
  margin-top: 0.02rem;
  color: var(--neutral900);
}

.resultWrapper .resultContent.couponsContent {
  background: none;
  height: 100%;
}

.resultWrapper .resultContent.couponsContent .content .imageTitle {
  width: 2.33rem;
  height: 0.27rem;
  background: url('https://img.flamingo.shop/p/show/8a3571d93354489b986fcc8febf91234.png') no-repeat center center;
  background-size: contain;
  margin-bottom: 0.13rem;
}

.resultWrapper .resultContent.couponsContent .content {
  position: static;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.resultWrapper .resultContent.couponsContent .content .couponsWrapper {
  max-height: 5rem;
  overflow: scroll;
}

.resultWrapper .btn {
  position: absolute;
  left: 0.68rem;
  top: 2.87rem;
  width: 2.4rem;
  height: 0.48rem;
  border-radius: 0.8rem;
  background: linear-gradient(89.75deg, #FF3951 3.39%, #FE8C3A 90.87%);
  box-shadow: 0 0 0.16rem 0 #FFFFFF inset;
  color: white;
  font-size: 0.2rem;
  font-weight: 600;
  line-height: 0.48rem;
  text-align: center;
}

.resultWrapper .couponsOkBtn {
  margin-top: 0.1rem;
  width: 2.4rem;
  height: 0.48rem;
  border-radius: 0.8rem;
  background: linear-gradient(89.75deg, #FF3951 3.39%, #FE8C3A 90.87%);
  box-shadow: 0 0 0.16rem 0 #FFFFFF inset;
  color: white;
  font-size: 0.2rem;
  font-weight: 600;
  line-height: 0.48rem;
  text-align: center;
}

.resultWrapper .closeIcon {
  width: 0.36rem;
  height: 0.36rem;
  background: url('https://img.flamingo.shop/p/show/4f85865a726443e8a2ec2c83e1c99a8a.png') no-repeat center center;
  background-size: 100%;
  position: absolute;
  top: -0.5rem;
  right: 0.12rem;
  z-index: 1;
}

.resultWrapper .resultContent.webToAppGiftContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.resultWrapper .resultContent.webToAppGiftContent {
  background: none;
  height: 100%;
}

.resultWrapper .resultContent.webToAppGiftContent .title {
  width: 2.59rem;
  height: 0.29rem;
  background: url('https://img.flamingo.shop/p/show/8db33e89faef490e9461e73b938d23e1.png') no-repeat center center;
  background-size: 100%;
}

.resultWrapper .resultContent.webToAppGiftContent .giftInfo {
  -webkit-animation: zoom 1s ease-in-out forwards;
  animation: zoom 1s ease-in-out forwards;
}

.resultWrapper .resultContent.webToAppGiftContent .giftWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
}

.resultWrapper .resultContent.webToAppGiftContent .container {
  position: relative;
  width: 100%;
  height: 3.5rem;
}

.resultWrapper .resultContent.webToAppGiftContent .container .bg {
  width: 100%;
  height: 3rem;
  background: url('https://img.flamingo.shop/p/show/aa2461fddb434c14aaad606e06034c45.png') no-repeat center center;
  background-size: 100%;
  -webkit-transform: translateY(0.3rem) scale(2.5);
  transform: translateY(0.3rem) scale(2.5);
  -webkit-animation: bgRotateAnimation 3s linear infinite;
  animation: bgRotateAnimation 3s linear infinite;
}

@-webkit-keyframes bgRotateAnimation {
  0% {
    -webkit-transform: translateY(0.3rem) rotate(0deg) scale(2.5);
    transform: translateY(0.3rem) rotate(0deg) scale(2.5);
  }

  100% {
    -webkit-transform: translateY(0.3rem) rotate(360deg) scale(2.5);
    transform: translateY(0.3rem) rotate(360deg) scale(2.5);
  }
}

@keyframes bgRotateAnimation {
  0% {
    -webkit-transform: translateY(0.3rem) rotate(0deg) scale(2.5);
    transform: translateY(0.3rem) rotate(0deg) scale(2.5);
  }

  100% {
    -webkit-transform: translateY(0.3rem) rotate(360deg) scale(2.5);
    transform: translateY(0.3rem) rotate(360deg) scale(2.5);
  }
}

.resultWrapper .resultContent.webToAppGiftContent .container .gift {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
}

.resultWrapper .resultContent.webToAppGiftContent .desc {
  color: #FFF;
  font-size: 0.15rem;
  margin-top: -0.25rem;
}

.resultWrapper .resultContent.webToAppGiftContent .price {
  margin-top: 0.1rem;
  font-size: 0.38rem;
  font-weight: 500;
}

.resultWrapper .resultContent.webToAppGiftContent .price .currentPrice {
  position: absolute;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(calc(-100% - 0.1rem));
  transform: translateX(calc(-100% - 0.1rem));
  color: #FF1F3A;
  font-weight: 800;
  -webkit-animation: currentPriceAnimation 0.5s forwards;
  animation: currentPriceAnimation 0.5s forwards;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

@-webkit-keyframes currentPriceAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(calc(-100% + 0.05rem)) scale(3);
    transform: translateX(calc(-100% + 0.05rem)) scale(3);
  }

  90% {
    opacity: 1;
    -webkit-transform: translateX(calc(-100% + 0.05rem)) scale(0.9);
    transform: translateX(calc(-100% + 0.05rem)) scale(0.9);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(calc(-100% + 0.05rem)) scale(1);
    transform: translateX(calc(-100% + 0.05rem)) scale(1);
  }
}

@keyframes currentPriceAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(calc(-100% + 0.05rem)) scale(3);
    transform: translateX(calc(-100% + 0.05rem)) scale(3);
  }

  90% {
    opacity: 1;
    -webkit-transform: translateX(calc(-100% + 0.05rem)) scale(0.9);
    transform: translateX(calc(-100% + 0.05rem)) scale(0.9);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(calc(-100% + 0.05rem)) scale(1);
    transform: translateX(calc(-100% + 0.05rem)) scale(1);
  }
}

.resultWrapper .resultContent.webToAppGiftContent .price .originalPrice {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0.24rem;
  color: #FFF;
  -webkit-animation: originalPriceAnimation 1s forwards;
  animation: originalPriceAnimation 1s forwards;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@-webkit-keyframes originalPriceAnimation {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0.15rem);
    transform: translateX(0.15rem);
  }
}

@keyframes originalPriceAnimation {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0.15rem);
    transform: translateX(0.15rem);
  }
}

.resultWrapper .resultContent.webToAppGiftContent .price .originalPrice::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.02rem;
  background-color: #FFF;
  top: 50%;
  left: 0;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-animation: strikeThroughAnimation 0.3s forwards;
  animation: strikeThroughAnimation 0.3s forwards;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@-webkit-keyframes strikeThroughAnimation {
  0% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: translateX(-50%) scaleX(0);
    transform: translateX(-50%) scaleX(0);
  }

  100% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1);
  }
}

@keyframes strikeThroughAnimation {
  0% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: translateX(-50%) scaleX(0);
    transform: translateX(-50%) scaleX(0);
  }

  100% {
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1);
  }
}

.resultWrapper .resultContent.webToAppGiftContent .btn {
  position: static;
  margin-top: 0.6rem;
  width: 3rem;
  height: 0.58rem;
  line-height: 0.58rem;
  opacity: 0;
  z-index: 1;
}

.resultWrapper .resultContent.webToAppGiftContent .fadeOutAnimation {
  -webkit-animation: fadeOutAnimation 0.5s forwards;
  animation: fadeOutAnimation 0.5s forwards;
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
}

@-webkit-keyframes fadeOutAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}