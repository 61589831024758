.contentHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0.3rem 0;
}

.contentHeader .step {
  display: flex;
  align-items: center;
}

.contentHeader .step .title {
  width: 0.28rem;
  height: 0.28rem;
  text-align: center;
  line-height: 0.28rem;
  background: var(--neutral900);
  color: white;
  font-size: 0.16rem;
  font-weight: bold;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.2;
}

.contentHeader .step .content {
  margin-left: 0.1rem;
  opacity: 0.2;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.contentHeader .step:not(:last-child)::after {
  content: " ";
  width: 0.28rem;
  height: 0.01rem;
  background: var(--neutral900);
  opacity: 0.2;
  display: inline-block;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.contentHeader .step.highlighted .title {
  background: var(--neutral900);
  opacity: 1;
}

.contentHeader .step.highlighted .content {
  opacity: 1;
}