.wrapper {
  width: 100%;
  height: 0.26rem;
  background: white;
  border-radius: 0.2rem;
  color: var(--color-common);
  font-size: 0.13rem;
  line-height: 0.26rem;
}

.wrapper .searchFrom {
  height: 100%;
  display: flex;
  padding: 0 0.06rem;
  font-size: 0.12rem;
}

.wrapper input {
  flex: 1 1;
  margin: 0.02rem 0.08rem;
}

.wrapper input::-webkit-input-placeholder {
  color: var(--gray3);
}

.wrapper input:-ms-input-placeholder {
  color: var(--gray3);
}

.wrapper input::placeholder {
  color: var(--gray3);
}

.wrapper .searchFrom .searchIcon {
  width: 0.14rem;
  height: 0.14rem;
  color: #cecece;
  font-size: 0.12rem;
  line-height: inherit;
  font-weight: bold;
  margin-left: 0.03rem;
}

.wrapper .searchFrom .clearIcon {
  width: 0.14rem;
  height: 0.14rem;
  margin: 0.06rem 0;
  background: url('./resources/remove.png') no-repeat center center;
  background-size: 100%;
}