.wrapper {
  position: relative;
}

.wrapper.highlight {
  z-index: var(--z-index-aside);
}

.handWrapper {
  position: relative;
}

.hand {
  position: absolute;
  background: url('./resources/select.svg') center center no-repeat;
  background-size: contain;
  -webkit-animation: moving 1s ease infinite normal;
  animation: moving 1s ease infinite normal;
  z-index: var(--z-index-flow-clickable);
}

.handWrapper .tip {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 0.15rem;
}

@-webkit-keyframes moving {
  0% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  20% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  40% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes moving {
  0% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  20% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  40% {
    -webkit-transform: translate(0.02rem, 0.03rem);
    transform: translate(0.02rem, 0.03rem);
  }

  60% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.shade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: calc(var(--z-index-aside) - 1);
}