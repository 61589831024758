.wrapper {
  position: relative;
  --navItemHeight: 0.54rem;
  box-shadow: 0 0.04rem 0.06rem rgba(230, 230, 230, 0.5);
  display: flex;
  align-items: center;
  z-index: calc(var(--z-index-flow-clickable) + 3);
}

.sliderBox {
  flex-grow: 1;
  position: relative;
  height: var(--navItemHeight);
  overflow: hidden;
  padding-left: 0.3rem;
}

.navigation {
  -webkit-transform: translateY(-0.6rem);
  transform: translateY(-0.6rem);
  width: calc(100% - 0.6rem);
}

.navigation > div {
  height: var(--navItemHeight);
  display: inline-block;
  margin-bottom: 0.1rem;
}

.navigation > div .navItem {
  display: flex;
  margin: 0 -0.03rem;
  line-height: var(--navItemHeight);
  padding: 0 0.15rem;
  position: relative;
  height: var(--navItemHeight);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 0.14rem;
  text-transform: uppercase;
  color: var(--neutral900);
}

.navigation > div .navItem.highlight {
  color: #CC0000;
}

.navigation > div .navItem:hover::before {
  content: '';
  position: absolute;
  left: 0.12rem;
  right: 0.12rem;
  bottom: 0;
  height: 0.02rem;
  background: var(--neutral900);
}

.arrow {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  height: var(--navItemHeight);
  line-height: var(--navItemHeight);
  font-size: 0.12rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.arrow.left {
  right: 0.45rem;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.arrow.disabled {
  opacity: 0.3;
}

.modalPanel {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: var(--z-index-modal);
  height: 100vh;
  background-color: rgba(4, 4, 15, 0.85);
}

.modalPanel.openPanel {
  display: block;
}

.navItemDetailPanel {
  background-color: #fff;
  padding: 0.12rem 0;
  padding-bottom: 0.3rem;
}

.navItemDetailPanel .detail {
  display: flex;
  justify-content: center;
  padding-top: 0.15rem;
}

.navItemDetailPanel .categories {
  display: flex;
  /* border-right: 1px solid var(--gray);
  border-left: 1px solid var(--gray); */
  margin-left: 0.3rem;
  padding-left: 0.3rem;
  margin-right: 0.12rem;
}

.navItemDetailPanel .grids {
  padding: 0;
}

.searchBox {
  margin-left: 0.38rem;
  margin-right: 0.45rem;
  height: 0.36rem;
}