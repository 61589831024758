.wrapper {
  display: flex;
  align-items: center;
  background: var(--neutral50);
  padding: 0.16rem 0.12rem;
  box-sizing: border-box;
}

.wrapper.inDesktop {
  margin-top: 0.25rem;
  padding: 0.32rem 0.2rem;
}

.wrapper .starNum {
  color: var(--neutral900);
  font-size: 0.28rem;
  line-height: 0.22rem;
  font-weight: 500;
}

.wrapper .reviewRating {
  margin: 0 0.05rem;
}

.wrapper .reviewsText {
  color: var(--neutral700);
  font-size: 0.12rem;
  font-weight: 400;
  line-height: normal;
}