.wrapper {
  background-color: #fff;
  margin-bottom: 0 !important;
}

.wrapper.isDesktop {
  padding-bottom: 0.36rem;
}

.header {
  position: relative;
  padding: 0.12rem 0.12rem 0.08rem;
  background-color: #fff;
}

.header .title {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: bold;
  line-height: 0.18rem;
}

.sliderBox {
  width: 100%;
  min-height: 0.4rem;
  background-color: #fff;
  padding: 0 0 0.05rem 0.12rem;
  position: relative;
}

.sliderBox.isDesktop {
  padding: 0;
  /* height: 650px; */
  overflow: hidden;
}

.list {
  margin-right: -0.11rem;
}

.rightIcon {
  --contentSize: 0.3rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: -0.05rem;
  height: var(--contentSize);
  font-size: 0.12rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  color: #686868;
  font-weight: bold;
  line-height: var(--contentSize);
}

.initCard {
  /* width: inherit !important; */
  /* width: 25%; */
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 0.0825rem);
  box-sizing: border-box;
  margin-right: 0;
}

.card {
  padding-right: 0.11rem;
}

.quickAddCard {
  position: relative;
}

.addToCartBtn {
  position: absolute;
  bottom: 0.08rem;
  right: 0.08rem;
  z-index: var(--z-index-layer-1);
}

.pcAddIcon {
  font-size: 0.24rem;
}

.sliderBox .pcSwiperButton {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  background: url(https://img.flamingo.shop/p/files/Right_3x_239079a9-8e50-42ed-87a3-fed6392eed9e.png?v=1599214611) no-repeat 50%;
  background-size: 100%;
  border-radius: 100%;
  z-index: var(--z-index-layer-1);
  cursor: pointer;
}

.sliderBox .pcLeftButton {
  left: 0.2rem;
  top: 34.7%;
  padding-left: 0.05rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sliderBox .pcRightButton {
  right: 0.2rem;
  top: 34.7%;
  padding-right: 0.05rem;
}

.sliderBox .pcLeftButtonArrow {
  width: 0.14rem;
  height: 0.14rem;
  border-top: var(--border-width) solid var(--neutral900);
  border-left: var(--border-width) solid var(--neutral900);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sliderBox .pcRightButtonArrow {
  width: 0.14rem;
  height: 0.14rem;
  border-top: var(--border-width) solid var(--neutral900);
  border-left: var(--border-width) solid var(--neutral900);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.sliderBox .pcLeftButton:hover,
.sliderBox .pcRightButton:hover {
  background-color: #fff;
  opacity: 1;
  color: var(--neutral900);
}

.sliderBox .pcLeftButton:hover .pcLeftButtonArrow,
.sliderBox .pcRightButton:hover .pcRightButtonArrow {
  border-top: var(--border-width) solid var(--neutral900) !important;
  border-left: var(--border-width) solid var(--neutral900) !important;
}

.smallCard {
  width: 0.98rem !important;
}

.mobileCard {
  margin-right: 0.08rem;
  width: 1.1rem !important;
}

.mobileList {
  width: 100%;
  overflow: hidden;
}

.mobileList .box {
  width: 100%;
  height: 1.78rem;
  overflow-x: scroll;
  overflow-y: hidden;
  list-style: none;
  padding-bottom: 0.1rem;
  display: flex;
}

.mobileList .box::-webkit-scrollbar {
  display: none;
}

.mobileList.isDesktop {
  height: 6rem;
}

.mobileList.isDesktop .box {
  height: 6.5rem;
}

.productCard {
  display: block;
}

.discountPrice {
  font-size: 0.14rem !important;
  line-height: 0.26rem;
}

.price {
  padding-top: 0.02rem;
  display: flex;
  justify-content: space-between;
}

.price.isDesktop {
  margin: 0.08rem 0 0.12rem 0;
}