.wrapper {
  position: relative;
  margin-bottom: 0.07rem;
  background-color: white;
  border-radius: 0.06rem;
}

.wrapper.inDesktop {
  margin-bottom: 0.12rem;
}

.wrapper.inDesktop .imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper.withVideoIcon::after {
  content: '';
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  width: 0.36rem;
  height: 0.36rem;
  background: url('https://img.flamingo.shop/p/show/5acf64ca8c0745fbbb945de41b9f3715.png') no-repeat center center;
  background-size: 100%;
}

.wrapper .image {
  border-radius: 0.06rem 0.06rem 0 0;
}

.wrapper .content {
  background: white;
  padding: 0.04rem 0.04rem 0.1rem 0.04rem;
  border-radius: 0 0 0.06rem 0.06rem;
}

.wrapper.inDesktop .title {
  font-size: 0.14rem;
  line-height: 0.18rem;
}

.wrapper .title {
  color: var(--neutral900);
  font-size: 0.14rem;
  line-height: 0.18rem;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.wrapper .bottomBar {
  margin-top: 0.04rem;
  display: flex;
  justify-content: space-between;
}

.wrapper .bottomBar .userInfo {
  display: flex;
  align-items: center;
}

.wrapper .bottomBar .userIcon {
  display: flex;
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  margin-right: 0.05rem;
}

.wrapper .bottomBar h4 {
  font-size: 0.12rem;
  line-height: 0.2rem;
  font-weight: normal;
  color: var(--neutral700);
  max-width: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.wrapper .bottomBar .likeButton {
  padding: 0.015rem 0;
}