.banner {
  display: block;
  color: var(--color-strong);
  background-color: var(--bg-strong);
  padding: 0.05rem;
  line-height: 0.3rem;
  font-size: 0.16rem;
  font-weight: bold;
  text-align: center;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 0.17rem;
  line-height: 0.44rem;
  text-align: center;
  color: #17181A;
}

.logoBar {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: var(--z-index-aside);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  width: 1.33rem;
  background-image: url('./resources/Logo.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.header {
  height: 0.44rem;
  width: 100%;
  color: var(--neutral900);
  background: #fff;
  border-bottom: 0.01rem solid var(--gary);
  position: -webkit-sticky;
  position: sticky;
  z-index: calc(var(--z-index-flow-clickable) + 3);
  top: 0;
  display: flex;
  justify-content: space-between;
}

.search {
  padding-left: 0.11rem;
}

.search .searchIcon {
  font-size: 0.18rem;
}

.naviBtn {
  height: 0.4rem;
  margin-left: 0.15rem;
}

.naviIcon {
  font-size: 0.14rem;
}

.backIcon {
  background: url('./resources/arrow-bold.svg') no-repeat center;
  display: block;
  background-size: contain;
  width: 0.16rem;
  height: 0.16rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  font-weight: bold;
  line-height: 0.3rem;
  margin-left: 0.12rem;
}

.header > aside {
  display: flex;
  align-items: center;
}

.header img {
  max-width: 1.2rem;
}

.header .cart {
  position: relative;
  z-index: var( --z-index-layer-1);
  height: 0.4rem;
  display: inline-flex;
  justify-content: flex-end;
  font-size: 0.16rem;
  line-height: 0.4rem;
  font-weight: 400;
  position: relative;
  z-index: 999;
  position: relative;
  padding-right: 0.12rem;
}

.cartIcon {
  line-height: 0.39rem;
  font-size: 0.32rem;
}

.header .share {
  padding: 0.19rem 0.2rem 0.2rem 0.21rem;
  width: 0.19rem;
  height: 0.17rem;
  background-image: url('./resources/share.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0.19rem 0.17rem;
}

.header .cart .cartCount {
  position: absolute;
  top: 0.02rem;
  left: 0.17rem;
  min-width: 0.16rem;
  height: 0.16rem;
  padding: 0.02rem 0.03rem 0 0.03rem;
  font-size: 0.1rem;
  line-height: 0.14rem;
  text-align: center;
  color: #FFFFFF;
  background: #EE1A1A;
  border-radius: 0.2rem;
  font-weight: 500;
}

.header .wish {
  position: relative;
  z-index: var( --z-index-layer-1);
  height: 0.4rem;
  display: inline-flex;
  justify-content: flex-end;
  font-size: 0.16rem;
  line-height: 0.4rem;
  font-weight: 400;
  position: relative;
  z-index: 999;
  position: relative;
}

.header .wish .wishCount {
  position: absolute;
  top: 0.02rem;
  left: 0.19rem;
  height: 0.16rem;
  padding: 0 0.04rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.1rem;
  line-height: 0.16rem;
  text-align: center;
  color: #FFFFFF;
  background-color: #000;
  border-radius: 0.3rem;
}

.header .wish .wishCount > span {
  display: inline-block;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
}

.wishIcon {
  line-height: 0.39rem;
  font-size: 0.32rem;
}