.wrapper {
  padding: 0.05rem 0.12rem 0.1rem 0.12rem;
  cursor: pointer;
}

.wrapper.isDesktop {
  padding: 0;
}

.wrapper .titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.wrapper .title {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: 700;
  line-height: 0.18rem;
  display: inline-block;
  margin-bottom: 0.08rem;
}

.wrapper.isDesktop .title {
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.wrapper .viewMore {
  position: relative;
  width: auto;
  font-size: 0.12rem;
  line-height: 0.22rem;
  color: #333;
  font-weight: 300;
  background-color: transparent;
  height: 0.22rem;
  padding: 0;
}

.wrapper .viewMore::after {
  content: "\e668";
  display: inline-block;
  right: 0.12rem;
  width: 0.18rem;
  height: 0.18rem;
  line-height: 0.18rem;
  text-align: center;
  font-weight: 400;
  color: var(--gray5);
  margin-right: -0.06rem;
}

.wrapper.isDesktop .viewMore {
  font-weight: 400;
  text-decoration: underline;
}

.wrapper.isDesktop .viewMore::after {
  display: none;
}

.wrapper .couponWrapper {
  position: relative;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.28rem;
}

.wrapper .couponWrapper::before {
  content: ' ';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0.04rem;
  width: 0.18rem;
  height: 0.18rem;
  background: url('https://img.flamingo.shop/p/show/60144856fa4b4a2eaa770b6b78b28503.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .coupon {
  display: inline-block;
  font-size: 0.12rem;
  color: #FF1818;
  line-height: 0.26rem;
  padding: 0 0.06rem;
  background: #FFF4F2;
  margin-right: 0.1rem;
  border-radius: 0.02rem;
  font-weight: 400;
}

.wrapper.isDesktop .coupon {
  font-size: 0.14rem;
}