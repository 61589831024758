.button {
  display: block;
  border: none;
  outline: none;
  text-align: center;
  width: 100%;
  cursor: pointer;
  z-index: calc(var(--z-index-flow-clickable) - 1);
}

/* reset a style to a button */

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary {
  background-color: var(--neutral900);
  color: var(--white);
  font-size: 0.21rem;
  height: 0.5rem;
  padding: 0 0.25rem;
  font-weight: 700;
  width: 100%;
  border-radius: 0.26rem;
  box-shadow: 0 0.02rem var(--neutral900-shadow);
}

.primary.invert {
  background-color: transparent;
  border: 0.01rem solid var(--neutral900);
  color: var(--neutral900);
  box-shadow: none;
  font-weight: normal;
  font-size: 0.16rem;
  height: 0.43rem;
}

.primary.small {
  padding: 0 0.11rem;
  font-size: 0.14rem;
  height: 0.32rem;
  border-radius: 0.3rem;
  box-shadow: none;
}

.normal {
  background-color: var(--neutral900);
  color: var(--white);
  font-size: 0.18rem;
  height: 0.45rem;
  border-radius: 0.08rem;
}

.normal.small {
  padding: 0 0.08rem;
  font-size: 0.14rem;
  height: 0.32rem;
  border-radius: 0.04rem;
}

.normal.invert {
  background-color: transparent;
  border: 0.01rem solid var(--neutral900);
  color: var(--neutral900);
}

.button:disabled {
  opacity: 0.6;
}

.isSubmitting {
  width: 100%;
  height: 100%;
  background-image: url('./resources/loading.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 0.75rem;
}

.small .isSubmitting {
  background-size: 0.5rem;
}

.inline {
  display: inline-block;
  width: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@-webkit-keyframes beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.97);
    transform: scale(0.97);
  }
}

@keyframes beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.97);
    transform: scale(0.97);
  }
}

.beat {
  -webkit-animation: beat 1.5s ease infinite normal;
  animation: beat 1.5s ease infinite normal;
}

.beatFast {
  -webkit-animation: beat 1s ease infinite normal;
  animation: beat 1s ease infinite normal;
}