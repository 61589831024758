.price {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 0.16rem;
}

.price.inDesktop {
  text-align: left;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  line-height: 0.16rem;
}

.salePrice {
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  font-weight: 700;
}

.price.inDesktop .salePrice {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: var(--neutral900);
}

.price .salePrice.highlighted {
  color: #D20000;
}

.originalPrice {
  font-size: 0.12rem;
  line-height: 0.14rem;
  color: var(--color-original-price);
  text-decoration: line-through;
  font-weight: normal;
  margin: 0.02rem 0 0 0.04rem;
}

.price.inDesktop .originalPrice {
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.22rem;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  text-transform: capitalize;
  color: #808080;
}

.discount {
  position: relative;
  margin-left: 0.04rem;
  margin-bottom: -0.01rem;
  line-height: 0.14rem;
  font-size: 0.1rem;
  color: #D20000;
  padding: 0 0.02rem;
  font-weight: 400;
}

.discount::after {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  border: 0.01rem solid #D20000;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  border-radius: 0.04rem;
}

.price.inDesktop .discount::after {
  display: none;
}

.price.inDesktop .discount {
  font-weight: 500;
  margin-left: 0.1rem;
  margin-bottom: 0.03rem;
  border: 0.01rem solid #D20000;
  border-radius: 0.02rem;
}