.wrapper.isDesktop {
  padding: 0.4rem 0.8rem;
}

.wrapper.isDesktop .tagSkeleton {
  width: 100%;
  height: 1.2rem;
  background: #F7F7F7;
}

.wrapper.isDesktop .content {
  width: 100%;
  display: flex;
}

.wrapper.isDesktop .content .filterSkeleton {
  width: 2.2rem;
  height: 9rem;
  background: #F7F7F7;
  margin-top: 0.4rem;
  margin-right: 0.4rem;
}

.wrapper.isDesktop .content .list {
  flex: 1 1;
  padding: 0;
  margin: 0.4rem 0;
  padding: 0;
}

.list {
  padding: 0.12rem 0.06rem;
}

.list .listColumn:not(:last-child) {
  margin-right: 0.07rem;
}

.wrapper:not(.isDesktop) {
  background-color: #fff;
}

.wrapper:not(.isDesktop) .filterSkeleton {
  height: 0.4rem;
  background-color: #fff;
}

.wrapper:not(.isDesktop) .header {
  height: 0.8rem;
  padding: 0.12rem;
  background-color: #fff;
}

.wrapper:not(.isDesktop) .header .tags {
  display: flex;
  margin-bottom: 0.24rem;
}

.wrapper:not(.isDesktop) .header .tags .skeleton {
  margin-right: 0.12rem;
}

.viewMore {
  margin: 0.12rem auto;
}