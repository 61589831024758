.wrapper {
  touch-action: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: white;
  top: 0;
  left: 0;
  z-index: 200;
}

.search {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 0.44rem;
  background: var(--primary);
  color: white;
  padding: 0.1rem 0.12rem;
}

.search .back {
  width: 0.1rem;
  height: 100%;
  background: url('https://img.flamingo.shop/p/files/navBack-1.png?v=1585652075') no-repeat center center;
  background-size: contain;
  margin-right: 0.1rem;
}

.search .searchBar {
  flex: 1 1;
}

.search .cancel {
  font-size: 0.13rem;
  line-height: 0.24rem;
  margin-left: 0.1rem;
}

.searchRecommendSections .section {
  padding: 0.12rem;
  background: white;
  margin: 0.06rem 0;
  font-size: 0.13rem;
  line-height: 0.15rem;
}

.searchRecommendSections .section .sectionTitle {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.searchRecommendSections .section .sectionTitle .clearIcon {
  width: 0.12rem;
  height: 0.15rem;
  background: url('./resources/trash-can.png') no-repeat right center;
  background-size: 100%;
}

.searchRecommendSections .section .content {
  display: flex;
  flex-wrap: wrap;
}

.searchItem {
  font-size: 0.11rem;
  display: inline-block;
  padding: 0.04rem 0.08rem;
  margin: 0 0.08rem 0.08rem 0;
  border: 0.01rem solid var(--gray);
  border-radius: 0.18rem;
  color: #666;
}

.searchItem.hot::before {
  display: block;
  content: '';
  width: 0.09rem;
  height: 100%;
  background: url('./resources/hot.png') no-repeat center center;
  background-size: 100%;
  float: left;
  margin-right: 0.03rem;
}