.topHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 0.6rem;
  background-color: white !important;
  z-index: var(--z-index-aside);
  width: 100%;
  box-shadow: 0 0.04rem 0.1rem rgba(0, 0, 0, 0.08);
}

.topHeader .content {
  width: 11.98rem;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.topHeader .headerImage {
  background: url('../../../components/Shell/Header/resources/Logo.svg') no-repeat;
  background-size: contain;
  width: 1.33rem;
  height: 0.35rem;
  margin-bottom: 0.01rem;
  cursor: pointer;
}

.topHeader .headerLockImage {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.16rem;
}

.topHeader .headerTitle {
  font-size: 0.14rem;
  color: #a7d163;
  font-weight: 600;
  margin-left: 0.4rem;
  padding-left: 0.4rem;
  border-left: 0.01rem solid #e6e6e6;
  flex: 1 1;
  display: flex;
  align-items: center;
  height: 0.4rem;
}

.topHeader .headerTitle span {
  display: block;
  font-size: 0.38rem;
  margin-right: 0.16rem;
}

.topHeader .continue {
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #666666;
  cursor: pointer;
}

.topHeader .continue span {
  font-size: 0.12rem;
  font-weight: bold;
  margin-left: 0.15rem;
}