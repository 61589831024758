.mobileCard {
  position: relative;
  width: 1.13rem;
}

.mobileCard .image {
  width: 1.13rem;
  display: block;
}

.imageWrapper {
  position: relative;
}

.addIcon {
  position: absolute;
  bottom: 0.05rem;
  right: 0.05rem;
}

.addIcon .icon {
  background: url('../../common/AddToCartIcon/resources/atc_icon.png') no-repeat center center;
  background-size: 100%;
  width: 0.26rem;
  height: 0.26rem;
  border-radius: 50%;
}

.salePrice {
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.salePrice.highlighted {
  color: #CC0000;
}

.originalPrice {
  font-size: 0.12rem;
  line-height: 0.14rem;
  color: var(--color-original-price);
  text-decoration: line-through;
  font-weight: normal;
  margin: 0.02rem 0 0 0.04rem;
}

.floatDiscount {
  position: absolute;
  left: 0.05rem;
  top: 0.05rem;
  width: 0.34rem;
  font-size: 0.1rem;
  line-height: 0.16rem;
  border-radius: 0;
  background-color: rgb(235, 25, 25, 0.8);
  color: var(--white);
  font-weight: 600;
  font-style: italic;
  text-align: center;
}

.discount {
  position: relative;
  margin-left: 0.04rem;
  line-height: 0.14rem;
  font-size: 0.1rem;
  color: #D20000;
  padding: 0.01rem 0.02rem;
  font-weight: 400;
}

.discount::after {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border: 0.01rem solid #D20000;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  border-radius: 0.04rem;
}

.priceBox {
  height: 0.2rem;
  display: flex;
  align-items: center;
  margin-top: 0.02rem;
  margin-bottom: 0.1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}