.wrapper {
  width: 3.75rem;
  padding: 0.08rem;
  padding-bottom: calc(var(--fixed-footer-bottom) + 0.08rem);
  background: #fff;
  border-top: 0.01rem solid var(--bg-gray2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: var(--z-index-aside);
  box-shadow: 0 -0.02rem 0.08rem 0 rgba(153, 153, 153, 0.2);
}

@supports (padding: max(0px)) {
  .wrapper {
    padding-bottom: max(var(--fixed-footer-bottom), 8px);
  }
}

.wrapper .addToCartBtn {
  height: 0.44rem;
  border-radius: 0;
  background: var(--neutral900);
  font-size: 0.14rem;
  line-height: 0.2rem;
  font-weight: 700;
}

.wrapper .addToCartBtn.checkout {
  background-color: var(--green);
}

.wrapper .addToCartBtn.update {
  text-transform: uppercase;
}

.wrapper .reminderStyle {
  font-size: 0.16rem;
}

.cartWrapper {
  position: relative;
  width: 0.42rem;
  height: 0.42rem;
  margin: 0.04rem 0.15rem 0 0;
}

.cartWrapper::before {
  content: "\e61c";
  position: relative;
  font-size: 0.44rem;
  width: 0.44rem;
  height: 0.44rem;
  color: #ff8b8c;
}

.cartWrapper .count {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 50%;
  font-size: 0.1rem;
  line-height: 0.15rem;
  color: white;
  text-align: center;
  background: var(--primary);
}

.cartIcon {
  height: 0.35rem;
  line-height: 0.35rem;
  position: relative;
  color: #000;
  margin-right: 0.16rem;
}

.cartIcon.shake::before {
  content: '+1';
  color: #FF8000;
  font-weight: 700;
  font-size: 0.12rem;
  line-height: 0.14rem;
  position: absolute;
  top: -0.1rem;
  right: 0.04rem;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: showAddCart;
  animation-name: showAddCart;
}

@-webkit-keyframes showAddCart {
  from {
    top: -0.1rem;
  }

  to {
    top: -0.23rem;
  }
}

@keyframes showAddCart {
  from {
    top: -0.1rem;
  }

  to {
    top: -0.23rem;
  }
}

.cartIcon > span {
  font-size: 0.38rem;
}

.cartIcon .cartCounts {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 0.15rem;
  height: 0.15rem;
  padding: 0 0.0375rem;
  box-sizing: border-box;
  font-size: 0.1rem;
  display: block;
  border-radius: 0.15rem;
  text-align: center;
  line-height: 0.15rem;
  background: #FF8000;
  color: var(--white);
  font-weight: bold;
}