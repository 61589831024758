.wrapper {
  /* width: 83px; */
  height: 0.15rem;
  display: flex;
}

.wrapper.isDesktop {
  width: 1.06rem;
  height: 0.18rem;
}

.wrapper .starContainer {
  position: relative;
  margin-right: 0.02rem;
}

.wrapper .starContainer:last-child {
  margin-right: 0;
}

.wrapper .starIcon {
  line-height: 0.15rem;
  font-size: 0.15rem;
  position: absolute;
  color: #ffaa00;
}

.wrapper .starDarkIcon {
  color: #e3e3e3;
}

.wrapper .solidStarBox {
  position: relative;
  overflow: hidden;
}

.wrapper.isDesktop {
  /* width: 106px; */
  height: 0.18rem;
}

.wrapper.isDesktop .starContainer {
  margin-right: 0.04rem;
}

.wrapper.isDesktop .starIcon {
  line-height: 0.18rem;
  font-size: 0.18rem;
}

.wrapper.isEdit {
  /* width: 142px; */
  height: 0.22rem;
}

.wrapper.isEdit .starContainer {
  margin-right: 0.08rem;
}

.wrapper.isEdit .starIcon {
  line-height: 0.22rem;
  font-size: 0.22rem;
}