.wrapper {
  padding-bottom: 1rem;
  max-width: 4.8rem;
}

.wrapper.isDesktop {
  padding-bottom: 2rem;
}

.wrapper .bordered {
  padding: 0.12rem 0.12rem 0 0.44rem;
  margin-left: 0.12rem;
  border-bottom: 0.01rem solid var(--neutral50);
}

div.cardGroup.isDesktop {
  grid-template-columns: repeat(5, 0.73rem);
  grid-row-gap: 0.07rem;
  row-gap: 0.07rem;
  grid-column-gap: 0.07rem;
  -webkit-column-gap: 0.07rem;
  column-gap: 0.07rem;
  padding: 0.09rem 0;
}