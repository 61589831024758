.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.12rem;
  width: 100%;
  height: 0.4rem;
  background: rgba(255, 225, 77);
  color: var(--neutral900);
  font-size: 0.12rem;
  line-height: 0.12rem;
  font-style: normal;
  font-weight: 500;
}

.wrapper .leftWrapper {
  display: flex;
  align-items: center;
}

.wrapper .leftWrapper .flashIcon {
  color: var(--neutral900);
  font-size: 0.16rem;
  margin-right: 0.06rem;
  line-height: 0.16rem;
}

.wrapper .leftWrapper .text {
  margin-top: 0.02rem;
}

.wrapper.inDesktop .leftWrapper .text {
  line-height: 0.21rem;
}

.wrapper .rightWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
}

.wrapper .countDown {
  display: flex;
  align-items: center;
}

.wrapper .countDown .text {
  margin-right: 0.1rem;
  text-transform: capitalize;
  margin-top: 0.02rem;
}

.wrapper .countDown .rightArrow {
  color: #666666;
  margin-left: 0.1rem;
  margin-top: 0.02rem;
}

.wrapper .countDown .msTime {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 0.04rem;
  background-color: var(--neutral900);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.04rem;
  margin-top: -0.01rem !important;
}

.wrapper.inDesktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.14rem 0 0.18rem;
  height: 0.5rem;
  background: rgba(255, 225, 77);
  font-size: 0.14rem;
  line-height: 0.14rem;
  font-style: normal;
  font-weight: 500;
}

.wrapper.inDesktop .countDown .text {
  font-size: 0.14rem;
  line-height: 0.21rem;
}

.wrapper.inDesktop .leftWrapper {
  display: flex;
  align-items: center;
  line-height: 0.2rem;
  line-height: 0.14rem;
}

.wrapper.inDesktop .leftWrapper .flashIcon {
  font-size: 0.22rem;
  margin-right: 0.1rem;
  line-height: 0.22rem;
  margin-top: 0.01rem;
}

.wrapper .countDown .counts {
  display: flex;
  align-items: center;
  margin-top: 0.01rem;
}

.wrapper .countDown .timeSpan {
  line-height: 0.12rem;
  padding: 0;
}

.wrapper.inDesktop .countDown .timeSpan {
  line-height: 0.21rem;
}

.wrapper.inDesktop .countDown .msTime {
  margin-left: 0.1rem;
  padding: 0;
  margin-top: 0.01rem;
}

.wrapper .countDown .sTime {
  width: 0.23rem;
}

.wrapper.inDesktop .countDown .sTime {
  width: 0.25rem;
}

.wrapper.inDesktop .countDown .rightArrow {
  color: var(--neutral900);
  font-size: 0.2rem;
  line-height: 0.2rem;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  margin-left: 0;
}