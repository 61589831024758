.selectWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 0.4rem;
}

.selectWrapper .selectLabel {
  margin: 0 0.1rem 0 0.4rem;
  color: var(--neutral900);
  font-size: 0.14rem;
  font-weight: 400;
  line-height: normal;
}

.selectWrapper .selectItem {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--neutral50);
  padding: 0.1rem;
  border-bottom: 0.04rem solid white;
  box-sizing: border-box;
}

.selectWrapper .selectItem .selectValue {
  display: inline-block;
  flex: 1 1;
  height: 0.2rem;
  line-height: 0.2rem;
  font-size: 0.14rem;
  font-weight: 500;
  color: var(--neutral900);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectWrapper .selectItem .arrowIcon {
  font-size: 0.16rem;
  margin-left: 0.1rem;
}

.selectItem::after {
  margin-left: 0.1rem;
  content: '';
  width: 0.1rem;
  height: 0.1rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow.svg') no-repeat;
  background-size: 100%;
  float: right;
}

.selectItem.active::after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

/* Option */

.itemWrapper {
  cursor: pointer;
  position: relative;
  border-bottom: 0.01rem solid var(--neutral50);
  padding: 0.15rem 0.08rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemWrapper.isDesktop:hover {
  background-color: var(--neutral50);
}

.itemWrapper.isDesktop {
  border-bottom: none;
  padding: 0.15rem 0.1rem;
}

.itemWrapper:last-child {
  border-bottom: none;
}

.itemWrapper .itemName {
  color: var(--neutral900);
  font-size: 0.14rem;
  font-weight: 500;
  line-height: normal;
}

.itemWrapper .selectedItemName {
  font-weight: 600;
}

.itemWrapper .selectedIcon {
  position: absolute;
  right: 0.08rem;
  font-size: 0.16rem;
  font-weight: 600;
  color: var(--neutral900);
}

.itemWrapper .selectedIcon.isDesktop {
  right: 0.1rem;
}