.header {
  width: 100%;
  position: relative;
}

.header .panelImage {
  width: 100%;
}

.header .countDown {
  position: absolute;
  bottom: 0.14rem;
  right: 0;
  margin-right: 0.1rem;
  display: flex;
  align-items: center;
}

.wrapper.isDesktop .header .countDown {
  bottom: 0.32rem;
}

.header .countDown .rightArrow {
  font-size: 0.2rem;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  width: 0.13rem;
  margin-left: -0.06rem;
  margin-top: 0.02rem;
}

.wrapper.isDesktop .header .countDown .rightArrow {
  font-size: 0.16rem;
  -webkit-transform: scale(1);
  transform: scale(1);
  margin-left: 0.1rem;
}

.header .countDown > span {
  margin-right: -0.1rem;
  font-size: 0.2rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  display: block;
}

.wrapper.isDesktop .header .countDown > span {
  -webkit-transform: scale(1);
  transform: scale(1);
  margin-right: 0.18rem;
  font-weight: 500;
  font-size: 0.2rem;
}

.header .countDown .counts span {
  font-size: 0.12rem;
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.06rem;
  border-radius: 0.04rem;
}

.wrapper.isDesktop .header .countDown {
  font-size: 0.36rem;
  margin-right: 0.35rem;
}

.wrapper.isDesktop .header .countDown .counts span {
  font-size: 0.16rem;
  width: 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius: 0.05rem;
}

.wrapper.isDesktop .header .countDown .counts label {
  margin-right: 0.16rem;
  font-weight: 500;
  font-size: 0.36rem;
  line-height: 0.4rem;
}

.body {
  background-size: 100%;
}

.wrapper.isDesktop .body {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 calc(0.76rem -0.15rem) 0.4rem calc(0.76rem -0.15rem);
}

.wrapper.isDesktop .body .silder > button::before {
  content: '';
}

.body .productSection {
  margin: 0;
  padding: 0 0.12rem 0.12rem;
  background: inherit;
  margin-top: -0.01rem;
}

.body .productSection::-webkit-scrollbar {
  display: none;
}

.arrow {
  position: absolute;
  top: calc(50% - 0.2rem);
  width: 0.5rem;
  height: 0.5rem;
  --border-width: 0.02rem;
  border: var(--border-width) solid #fff;
  box-sizing: border-box;
  z-index: var(--z-index-layer-1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  opacity: 0.7;
}

.arrow.left {
  left: 0.99rem;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.arrow.right {
  right: 0.99rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.countDown .endsInDays {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 0.1rem;
  line-height: 0.12rem;
  color: #3D3D3D;
}

.wrapper.isDesktop .countDown .endsInDays {
  margin-right: 0.44rem;
  font-size: 0.12rem;
  line-height: 0.24rem;
  margin-bottom: 0.03rem;
}

.listWrapper {
  background-color: #fff;
  position: relative;
}

.flamingCardBox {
  padding: 0 0.15rem;
}

.wrapper.isDesktop .body .flamingCardBox {
  padding: 0;
  padding-right: 0.12rem;
}

@media (max-width: 768px) {
  .wrapper.isDesktop .body .flamingCardBox {
    padding-right: 0.08rem;
  }
}

@media (min-width: 768px) and  (max-width: 1200px) {
  .wrapper.isDesktop .body .flamingCardBox {
    padding-right: 0.1rem;
  }
}

@media (min-width: 1200px) {
  .wrapper.isDesktop .body .flamingCardBox {
    padding-right: 0.12rem;
  }
}

.endsInDaysSpan {
  margin-top: 0.02rem;
}

.endsInDaysSpan span {
  text-transform: capitalize;
  margin-top: 0.02rem;
}