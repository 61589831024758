.wrapper {
  width: 3.75rem;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
}

.wrapper.auto {
  height: auto;
}

.whiteWrapper {
  width: 3.75rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  flex-direction: column;
}

.message {
  font-size: 0.12rem;
  font-weight: bold;
  margin-top: 0.2rem;
}

.wrapper.inDesktop {
  width: 100%;
}

.loadingRoller>div {
  background-color: #fff;
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 100%;
  margin: 0.02rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0.02rem solid var(--neutral900);
  border-bottom-color: rgba(42, 42, 42, .1);
  height: 0.26rem;
  width: 0.26rem;
  background: transparent !important;
  display: inline-block;
  -webkit-animation: rotate 0.75s 0s linear infinite;
  animation: rotate 0.75s 0s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

div.maskClassName {
  background-color: rgba(255, 255, 255);
}