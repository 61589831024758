.wrapper {
  margin-top: 0.28rem;
}

.wrapper .breadCrumbsBox,
.wrapper .body {
  width: 11.98rem;
  margin: 0 auto;
}

.wrapper .body {
  display: flex;
  margin: 0.18rem auto;
}

.navigate {
  flex-basis: 3rem;
  margin-right: 1.2rem;
}

.content {
  flex: 1 1;
}