.priceSection {
  padding-top: 0.36rem;
  padding-bottom: 0.9rem;
  /* position: absolute; */
  transition: all 1s ease;
  /* bottom: -100%; */
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.1rem;
}

.show {
  bottom: 0;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  font-size: 0.15rem;
  line-height: 0.18rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
}

.priceRow.couponHint {
  font-size: 0.11rem;
  line-height: 0.18rem;
  color: #67605f;
  margin: -0.08rem 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.priceRow .title {
  font-weight: normal;
}

.priceRow.text .price::before {
  content: "";
}

.priceRow .price.negative::before {
  content: "- ";
}

.priceRow.orderTotal {
  min-height: 0.22rem;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.priceRow.orderTotal .selectAll {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.priceRow.orderTotal .selectAll .selectIcon {
  width: 0.16rem;
  height: 0.16rem;
}

.priceRow.orderTotal .selectAll > span {
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  margin-left: 0.06rem;
}

.priceRow .saveTotal {
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #D20000;
  text-align: right;
}

.priceRow.orderTotal .title {
  font-weight: bold;
  font-style: normal;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: var(--neutral900);
}

.priceRow.orderTotal .price {
  font-weight: bold;
  font-style: normal;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: var(--neutral900);
  margin-left: 0.04rem;
}

.priceRow.freeShipping .price::before {
  content: none;
}

.cartItems {
  padding: 0 0.12rem 0.04rem;
  overflow: hidden;
}

.cartItems.offSale {
  border-top: 0.08rem solid var(--bg-common);
}

.btnBox {
  display: flex;
  padding-bottom: 0.12rem;
}

.checkoutBtn {
  height: 0.45rem;
  font-weight: bold;
  border-radius: 0.04rem;
  background: #190808;
}

.checkoutBtn.fitButton {
  height: inherit;
}

.wrapper .klarna {
  font-size: 0.11rem;
  text-align: center;
}

.buttonGroup {
  padding: 0.09rem 0.12rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  border-top: 0.01rem solid var(--gray);
  box-shadow: 0 0.89rem 0.65rem 0.45rem grey;
  z-index: calc(var(--z-index-flow-clickable) + 1);
}

.buttonGroup .floatBar {
  position: absolute;
  left: 0;
  top: -0.18rem;
}

.payBtn {
  width: 100%;
  height: 45PX;
  margin-right: 0.12rem;
}

.wrapper {
  position: relative;
  background: #FFF;
}

.wrapper .loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .6);
  z-index: var(--z-index-modal);
}

.totalIcon.active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.others {
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: #666666;
  margin-right: 0.07rem;
}

.cartItemTitle {
  padding: 0.09rem 0.12rem 0.07rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #1A1919;
}

.priceBox {
  padding: 0.12rem;
  border-top: 0.08rem solid var(--neutral50);
}

.cartItem {
  position: relative;
  margin-bottom: 0.15rem;
  padding-left: 0.26rem;
}

.cartItem .selectIcon {
  position: absolute;
  top: 0.3rem;
  left: 0;
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.1rem;
}

.subTotalBox {
  text-align: right;
}

.couponInfo {
  width: 3.75rem;
  height: 0.53rem;
  background: linear-gradient(90deg, #F7E6BA 0%, #E1B483 100%);
  box-shadow: 0 0 0.04rem rgba(0, 0, 0, 0.07);
  padding: 0.07rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.couponInfo .title {
  font-style: normal;
  font-weight: 700;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  text-transform: uppercase;
  color: #411001;
}

.couponInfo .cardClassName {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 0.04rem;
  background-color: var(--neutral900);
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.11rem;
  text-align: center;
  color: #FFFFFF;
  padding-top: 0.04rem;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.couponInfo .separatorClassName {
  color: var(--neutral900);
}

div.securityCertification,
div.purchaseProtection {
  border-top: 0.08rem solid var(--neutral50);
}

div.purchaseProtection {
  padding-bottom: 0;
  overflow: hidden;
}

div.securityCertification .cardGroup {
  display: grid;
  grid-template-columns: repeat(7, 0.4rem);
  grid-row-gap: 0.04rem;
  row-gap: 0.04rem;
  grid-column-gap: 0.04rem;
  -webkit-column-gap: 0.04rem;
  column-gap: 0.04rem;
  padding-top: 0.09rem;
}

div.securityCertification .safeDesc,
div.purchaseProtection .safeDesc {
  color: var(--neutral600);
}

div.securityCertification .cardTitle,
div.purchaseProtection .cardTitle {
  color: var(--neutral900);
}

.syncTip {
  background-color: #1a1a1a;
  background-image: url('https://img.flamingo.shop/p/show/039a66852d904552928064ff955a8164.png');
  background-size: cover;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #FFFFFF;
  padding: 0.09rem 0.9rem 0.09rem 0.12rem;
  position: relative;
}

.syncTip .signBtn {
  position: absolute;
  top: 50%;
  right: 0.12rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 1rem;
  height: 0.23rem;
  line-height: 0.21rem;
  text-align: center;
  border: 0.01rem solid #FFFFFF;
  border-radius: 0.2rem;
  white-space: nowrap;
  padding: 0 0.06rem;
}

.syncItemTip {
  border-top: 0.01rem solid #F5F5F5;
  padding: 0.1rem 0.14rem;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.17rem;
  color: #646464;
  display: flex;
  flex-wrap: wrap;
}

.syncItemTip > div {
  white-space: nowrap;
  margin-right: 0.05rem;
}

.syncItemTip span {
  white-space: nowrap;
  color: #F36F24;
  position: relative;
}

.syncItemTip span::after {
  content: "\e668";
  display: inline-block;
  width: 0.11rem;
  height: 0.12rem;
  line-height: 0.12rem;
  -webkit-transform: translateY(0.01rem);
  transform: translateY(0.01rem);
  margin-left: 0.02rem;
}