.wrapper {
  --radioSize: 0.1rem;
  --radioSmallSize: 0.06rem;
  position: relative;
}

.wrapper .topContent,
.wrapper .bottomContent {
  position: relative;
  display: flex;
}

.wrapper .topContent > main,
.wrapper .bottomContent > main {
  flex: 1 1;
  background-color: #fff;
  padding: 0 0.2rem;
}

.wrapper .bar {
  width: 100%;
  flex: 1 1;
  background-color: #fff;
}

.wrapper .separatorWrapper {
  position: relative;
  width: 100%;
  height: 0.18rem;
  background: url('./resources/coupon-separator.webp') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .separatorWrapper .separator {
  position: absolute;
  left: 0.12rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: calc(100% - 0.24rem);
  height: 0.02rem;
  border-top: 0.01rem dashed rgba(255, 155, 117, .5);
}