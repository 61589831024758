.wrapper {
  width: 100%;
  height: 0.2rem;
  background: linear-gradient(89.92deg, #FFEE99 0.05%, #FFFBE4 99.93%);
  padding: 0 0 0 0.08rem;
  font-weight: 500;
  font-size: 0.1rem;
  line-height: 0.1rem;
  display: flex;
  align-items: center;
  color: #B8802C;
  position: relative;
  white-space: nowrap;
}

.wrapper.isLink {
  padding: 0 0.16rem 0 0.08rem;
}

.wrapper.isLink::after {
  content: "\e668";
  position: absolute;
  top: 50%;
  right: 0.08rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wrapper .title {
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.12rem;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.countdownBox {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0.02rem;
  padding-left: 0.02rem;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.12rem;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.countdownBox::before {
  content: "";
  position: absolute;
  left: -0.045rem;
  display: inline-block;
  width: 0.01rem;
  height: 0.125rem;
  background-color: #B8802C;
}