.wrapper {
  margin-top: -0.08rem;
}

.titleSection {
  padding: 0.1rem 0.12rem 0.05rem 0.12rem;
  background: white;
  font-size: 0.12rem;
  line-height: 0.14rem;
  color: var(--neutral900);
}

.titleSection .klarna {
  margin: 0.04rem 0 0 0;
}

.titleSection .firstRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  line-height: 0.16rem;
  vertical-align: middle;
}

.titleSection .firstRow .reviewRating {
  display: flex;
}

.titleSection .firstRow .reviewRating .starRating {
  height: 0.15rem;
}

.titleSection .firstRow .reviewRating .reviewCount {
  margin: 0.01rem 0.04rem 0 0.02rem;
  color: var(--color-gray);
  font-size: 0.12rem;
}

.titleSection .firstRow a.reviewRating::after {
  content: url("./resources/arrow.svg");
  display: inline-block;
  margin-top: 0.02rem;
}

.titleSection .priceSection {
  display: flex;
}

.titleSection .salePrice {
  font-size: 0.16rem;
  line-height: 0.16rem;
  font-weight: 700;
  color: var(--neutral900);
  margin-right: 0.08rem;
}

.titleSection .salePrice.highlighted {
  color: #D20000;
}

.titleSection .originalPriceWrapper {
  display: flex;
  align-items: flex-end;
}

.titleSection .originalPrice {
  font-size: 0.12rem;
  line-height: inherit;
  color: var(--color-original-price);
  text-decoration: line-through;
  margin: 0 0.08rem 0 0;
}

.titleSection .discount {
  position: relative;
  font-size: 0.1rem;
  line-height: 0.14rem;
  color: #D20000;
  padding: 0.01rem 0.02rem;
}

.discount::after {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border: 0.01rem solid #D20000;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  border-radius: 0.04rem;
}

.titleSection .title {
  font-size: 0.14rem;
  font-weight: 400;
  color: var(--neutral900);
  line-height: 0.18rem;
  margin-right: 0.18rem;
}

.titleSection .iconBox {
  display: flex;
  align-items: center;
  -webkit-transform: translateX(0.04rem) translateY(-0.05rem);
  transform: translateX(0.04rem) translateY(-0.05rem);
}

.titleSection .reviewRating .starRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.15rem;
}

.titleSection .reviewRating  .starRatingDesc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.15rem;
  padding-top: 0.02rem;
}

.titleSection .reviewRating .starRatingDesc span:nth-child(1) {
  margin: 0 0.02rem 0 0.04rem;
  font-size: 0.12rem;
  font-weight: 500;
  color: var(--neutral900);
}

.titleSection .reviewRating .starRatingDesc span:nth-child(2) {
  font-size: 0.12rem;
  font-weight: 400;
  color: var(--neutral500);
}

.titleSection .titleWrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-top: 0.08rem;
}

.titleSection .titleWrapper .newTag {
  display: inline-block;
  padding: 0 0.06rem;
  border-radius: 0 0.08rem;
  background: rgba(33, 167, 0, 0.75);
  color: #FFF;
  font-size: 0.12rem;
  line-height: 0.16rem;
  height: 0.16rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 0.04rem;
  vertical-align: 0.01rem;
}

.titleSection .titleWrapper .iconBox {
  position: absolute;
  right: 0;
  top: 0;
}

.titleSection .titleWrapper .share::after {
  width: 0.24rem;
  height: 0.24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\e60c";
  font-size: 0.3rem;
  color: #000;
  -webkit-transform: translateY(0.02rem);
  transform: translateY(0.02rem);
}

.priceChart {
  position: relative;
  width: 100%;
  height: 0.81rem;
  background: url("https://img.flamingo.shop/p/files/Lark20191030-033033.jpeg?100769") no-repeat
    center center;
  background-size: 100%;
}

.priceChart .startPoint {
  font-size: 0.15rem;
  line-height: 0.18rem;
  color: white;
  position: absolute;
  left: 0.34rem;
  bottom: 0.31rem;
}

.priceChart .endPoint {
  font-size: 0.15rem;
  line-height: 0.18rem;
  color: white;
  position: absolute;
  right: 0.24rem;
  bottom: 0.31rem;
}

.priceChart .middlePoint {
  font-size: 0.18rem;
  line-height: 0.24rem;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 0.22rem;
  left: 1.55rem;
  width: 0.7rem;
  text-align: center;
}

.reminderBox {
  width: 100%;
  height: 0.59rem;
  background: url("https://img.flamingo.shop/p/files/Lark20191030-025309.jpeg?100767") no-repeat
    center center;
  background-size: 100%;
}

.promotionTag {
  display: inline-block;
  min-height: 0.19rem;
  background: #FEF7E1;
  border-radius: 0.02rem;
  padding: 0 0.04rem;
  margin-top: 0.06rem;
  font-size: 0.12rem;
  line-height: 0.19rem;
  color: #B8802C;
  font-weight: 400;
}

.purchaseLimit {
  padding: 0.03rem 0.12rem 0.08rem;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}