.wrapper {
  background-color: #fff;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.header {
  position: relative;
  padding: 0.12rem 0.12rem 0.08rem;
  background-color: #fff;
}

.header .title {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: bold;
  line-height: 0.18rem;
}

.list {
  padding: 0;
}

.wrapper .listBox .column {
  margin-right: 0.06rem;
}

.wrapper .listBox {
  position: relative;
  padding: 0 0.12rem 0.12rem 0.12rem;
}

.wrapper .viewMore {
  text-align: center;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  background-color: transparent;
  height: 0.2rem;
}

.wrapper .viewMore .arrow {
  font-size: 0.2rem;
  padding-left: 0.03rem;
  vertical-align: -0.02rem;
  color: var(--neutral900);
}

.loading {
  height: auto;
}