.wrapper {
  width: 3.35rem;
  background: url('https://img.flamingo.shop/p/show/0b2737418569477595d1642475f7644d.png') no-repeat center center;
  background-size: auto;
  padding: 0.2rem 0.2rem 0.31rem 0.2rem;
  display: flex;
  flex-direction: column;
}

.wrapper .title {
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.34rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFEACD;
  margin-bottom: 0.05rem;
}

.wrapper .subtitle {
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #FFEACD;
  margin-bottom: 0.15rem;
  text-align: center;
}

.wrapper .couponWrapper {
  margin-bottom: 0.1rem;
}

.wrapper .couponWrapper .coupon {
  display: flex;
  justify-content: space-between;
  background: url('https://img.flamingo.shop/p/show/6066b1134675416e8736b52fc8180f7f.png') no-repeat center center;
  background-size: 100% 100%;
  margin-bottom: 0.05rem;
  color: #EE1A1A;
  font-size: 0.1rem;
  line-height: 0.14rem;
  padding: 0.08rem 0.24rem;
}

.wrapper .couponWrapper .leftWrapper {
  width: 1.68rem;
}

.wrapper .couponWrapper .coupon .name {
  font-size: 0.2rem;
  line-height: 0.28rem;
  font-weight: 600;
}

.wrapper .couponWrapper .coupon .countdownClassName {
  margin: auto 0;
}

.wrapper .couponWrapper .coupon .cardClassName {
  background-color: var(--neutral900);
  color: #FFEACD;
  border-radius: 0.04rem;
  height: 0.18rem;
  width: 0.18rem;
  font-size: 0.1rem;
  padding: 0.01rem;
  margin-right: 0.02rem;
}

.wrapper .couponWrapper .coupon .separatorClassName {
  color: var(--neutral900);
  font-size: 0.14rem;
}

.continue {
  width: 100%;
  height: 0.42rem;
  background-color: black;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.42rem;
  /* identical to box height, or 143% */
  text-align: center;
  text-transform: uppercase;
  color: #FFEACD;
}

.closeBtnClassName {
  width: 0.32rem;
  height: 0.32rem;
  top: 0;
  right: 0;
  padding: 0.1rem;
  background-size: 0.12rem;
  background-position: center;
}

.cartItems {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0.15rem 0;
}

.cartItem {
  width: 0.7rem;
  position: relative;
}

.cartItem:not(:last-child) {
  margin-right: 0.05rem;
}

.cartItem .cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-layer-1);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.36rem;
}