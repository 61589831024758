.wrapper {
  height: calc(var(--sticky-header-top) + 0.44rem);
  padding: 0.05rem 0.12rem;
  padding-top: calc(var(--sticky-header-top) + 0.05rem);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: var(--z-index-flow-clickable);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.wrapper.floating {
  position: fixed;
  top: var(--safe-area-inset-top);
  background: transparent;
  z-index: var(--z-index-flow-clickable);
  padding: 0.08rem 0.12rem;
}

.wrapper.transparent {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.0);
  z-index: var(--z-index-flow-clickable);
  padding: 0.08rem 0.12rem;
  padding-top: calc(var(--safe-area-inset-top) + 0.05rem);
}

.wrapper .left,
.wrapper .right {
  display: flex;
}

.wrapper .left .back {
  position: relative;
  width: 0.32rem;
  height: 0.32rem;
}

.wrapper .left .back::after {
  content: "\e610";
  font-size: 0.16rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 50%;
  color: #000;
}

.wrapper.floating .left .back::after {
  content: "\e610";
  font-size: 0.16rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background-color: rgba(82, 82, 82, 0.7);
  border-radius: 50%;
  color: #fff;
}

.wrapper .title {
  position: absolute;
  display: flex;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 500;
  font-size: 0.17rem;
  line-height: 0.24rem;
  color: #17181A;
  text-align: center;
  max-width: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrapper .right .home {
  position: relative;
  width: 0.32rem;
  height: 0.32rem;
}

.wrapper .right .home::after {
  position: relative;
  content: "\e618";
  font-size: 0.3rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 50%;
  color: #000;
}

.wrapper.floating .right .home::after {
  content: "\e618";
  font-size: 0.3rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background-color: rgba(82, 82, 82, 0.7);
  border-radius: 50%;
  color: #fff;
}

.wrapper .right .cart {
  position: relative;
  width: 0.32rem;
  height: 0.32rem;
}

.wrapper .right .cart::before {
  position: relative;
  content: "\e61c";
  font-size: 0.3rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 50%;
  color: #000;
}

.wrapper.floating .right .cart::before {
  content: "\e61c";
  font-size: 0.3rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background-color: rgba(82, 82, 82, 0.7);
  border-radius: 50%;
  color: #fff;
}

.wrapper .right .share {
  position: relative;
  width: 0.32rem;
  height: 0.32rem;
}

.wrapper .right .share::after {
  position: relative;
  content: "\e61b";
  font-size: 0.3rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border-radius: 50%;
  color: #000;
}

.wrapper.floating .right .share::after {
  content: "\e61b";
  font-size: 0.3rem;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background-color: rgba(82, 82, 82, 0.7);
  border-radius: 50%;
  color: #fff;
  margin-left: 0.08rem;
}

.wrapper .right .cart .count {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 50%;
  font-size: 0.1rem;
  line-height: 0.15rem;
  color: white;
  text-align: center;
  background: var(--primary);
}