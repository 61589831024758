.wrapper {
  position: -webkit-sticky;
  position: sticky;
  z-index: var(--z-index-flow-clickable);
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(var(--safe-area-inset-top) + 0.44rem);
  background: var(--white);
  color: white;
  padding: 0.09rem 0.12rem;
  padding-top: calc(var(--safe-area-inset-top) + 0.09rem);
}

.wrapper.inWeb {
  background: #fff;
}

.wrapper .back::after {
  content: "\e612";
  font-size: 0.1rem;
  position: absolute;
  top: 50%;
  left: 0.12rem;
  -webkit-transform: translate(0, -50%) rotate(180deg);
  transform: translate(0, -50%) rotate(180deg);
  color: var(--neutral900);
  font-weight: 400;
}

.wrapper .webBack {
  width: 0.1rem;
  height: 0.25rem;
  background: url('https://img.flamingo.shop/p/files/naviBack.png?v=1585807994') no-repeat center center;
  background-size: contain;
  margin-right: 0.1rem;
}

.wrapper .cancel {
  font-size: 0.12rem;
  margin-left: 0.03rem;
  margin-top: 0.02rem;
  color: #4a4a4a;
}

.wrapper .searchBar {
  background: var(--gray);
  flex: 1 1;
}