.title,
.content {
  width: 92%;
  margin: 0 auto;
}

.title {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 0.2rem;
}

.content {
  margin-bottom: 0.3rem;
  word-break: break-word;
}