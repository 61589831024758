.SEOCollection:not(.show) h1,
.SEOCollection:not(.show) h2,
.SEOCollection:not(.show) h3,
.SEOCollection:not(.show) h4 {
  opacity: 0;
  height: 0 !important;
  margin: 0 !important;
}

.SEOCollection > div > *:first-child,
.SEOCollection:not(.show) p {
  margin: 0 !important;
}

.SEOCollection:not(.isDesktop) {
  width: 100%;
  overflow: hidden;
  margin-top: -0.0225rem;
  position: relative;
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
  height: 0.16rem;
}

.SEOCollection:not(.isDesktop):not(.show) {
  padding-bottom: 0.2rem;
  margin-bottom: 0.1rem;
}

.SEOCollection:not(.isDesktop).show {
  height: unset;
}

.SEOCollection:not(.isDesktop) .icon {
  position: absolute;
  top: -0.02rem;
  right: 0.12rem;
  width: 0.1rem;
  padding: 0;
}

.SEOCollection:not(.isDesktop) .iconDown {
  position: absolute;
  left: 50%;
  bottom: -0.1rem;
  z-index: var(--z-index-layer-1);
}

.SEOCollection:not(.isDesktop) .iconDown::after {
  content: "";
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  padding: 0;
  background: url('./resources/up_arrow.svg');
  background-size: 100%;
}

.SEOCollection:not(.isDesktop) .icon::after {
  content: '';
  display: inline-block;
  width: 0.12rem;
  height: 0.12rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow.svg');
  background-size: 100%;
}

.SEOCollection:not(.isDesktop).show .icon::after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.SEOCollection:not(.isDesktop) > div {
  display: inline-block;
  width: 3.3rem;
  padding-left: 0.144rem;
  box-sizing: content-box;
}

.SEOCollection:not(.isDesktop) h2 {
  margin-top: 0.1rem;
  color: var(--neutral900) !important;
  font-size: 0.18rem;
  font-weight: 600;
  line-height: 1.5;
}

.SEOCollection:not(.isDesktop) h3 {
  margin-top: 0.1rem;
  color: var(--neutral900) !important;
  font-size: 0.16rem;
  font-weight: 600;
  line-height: 1.5;
}

.SEOCollection:not(.isDesktop) h4 {
  margin-top: 0.1rem;
  color: var(--neutral900) !important;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 1.5;
}

.SEOCollection:not(.isDesktop) p {
  margin-top: 0.05rem;
  color: var(--neutral600) !important;
  font-size: 0.12rem;
  line-height: 1.5;
}

.SEOCollection:not(.isDesktop) p > *:not(strong):not(a) {
  margin-top: 0.05rem;
  color: var(--neutral600) !important;
  font-size: 0.12rem;
  line-height: 1.5;
}

.SEOCollection:not(.isDesktop) ul li {
  list-style: disc;
  margin-left: 0.2rem;
}

.SEOCollection:not(.isDesktop) ol li {
  list-style: auto;
  margin-left: 0.16rem;
  padding-left: 0.04rem;
}

.SEOCollection:not(.isDesktop) li {
  margin-top: 0.05rem;
  color: var(--neutral600) !important;
  font-size: 0.12rem;
  line-height: 1.5;
}

.SEOCollection:not(.isDesktop) a {
  color: var(--neutral600) !important;
  text-decoration: underline;
}

.SEOCollection.isDesktop {
  margin: 0.3rem auto;
  width: 7.5rem;
  overflow: hidden;
  position: relative;
  height: 0.16rem;
}

.SEOCollection.isDesktop.show {
  height: unset;
}

.SEOCollection.isDesktop h2 {
  margin-top: 0.1rem;
  color: var(--neutral900) !important;
  font-size: 0.16rem;
  font-weight: 600;
  line-height: normal;
}

.SEOCollection.isDesktop h3 {
  margin-top: 0.1rem;
  color: var(--neutral900) !important;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: normal;
}

.SEOCollection.isDesktop h4 {
  margin-top: 0.1rem;
  color: var(--neutral900) !important;
  font-size: 0.12rem;
  font-weight: 600;
  line-height: normal;
}

.SEOCollection.isDesktop p {
  margin-top: 0.05rem;
  color: var(--neutral600) !important;
  font-size: 0.12rem;
  line-height: 1.5;
}

.SEOCollection.isDesktop p > * {
  margin-top: 0.05rem;
  color: var(--neutral600) !important;
  font-size: 0.12rem;
  line-height: 1.5;
}

.SEOCollection.isDesktop ul li {
  list-style: disc;
  margin-left: 0.2rem;
}

.SEOCollection.isDesktop ol li {
  list-style: auto;
  margin-left: 0.16rem;
  padding-left: 0.04rem;
}

.SEOCollection.isDesktop li {
  margin-top: 0.05rem;
  color: var(--neutral600) !important;
  font-size: 0.12rem;
  line-height: 1.5;
}

.SEOCollection.isDesktop a {
  color: var(--neutral600) !important;
  text-decoration: underline;
}

.pcReadMore {
  cursor: pointer;
  color: var(--neutral600) !important;
  text-align: center;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: normal;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

strong {
  font-weight: bold;
  color: var(--neutral900) !important;
}