.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-animation: display 1s ease;
  animation: display 1s ease;
}

@-webkit-keyframes display {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  30% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes display {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  30% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.wrapper.withCustomizedImage {
  width: 3.51rem;
}

.wrapper.inDesktop.withCustomizedImage {
  width: 7rem;
}

.imageWrapper {
  position: relative;
}

.guideImage {
  width: 3rem;
  height: 3.4rem;
  position: relative;
  color: white;
  text-align: center;
}

.guideImage.welcome {
  background: url('./resources/welcome.png') no-repeat;
  background-size: 100% 100%;
}

.guideImage.congrats {
  background: url('./resources/congrats.svg') no-repeat;
  background-size: 100% 100%;
}

/* .guideImage.congrats .target {
  display: none;
} */

.guideImage .content {
  position: absolute;
  top: 1.7rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.guideImage .discount {
  font-size: 0.4rem;
  font-weight: bold;
  margin-bottom: 0.15rem;
}

.guideImage .discount .highlighted {
  font-size: 0.55rem;
  padding-right: 0.05rem;
}

.guideImage .discount .off {
  vertical-align: top;
}

.guideImage .target {
  font-size: 0.18rem;
  line-height: 0.25rem;
}

.rect {
  margin-top: 0.24rem;
  display: flex;
  justify-content: center;
  background: transparent;
}

.rect .btn {
  width: 0.28rem;
  height: 0.28rem;
  border: 0.01rem solid white;
  border-radius: 50%;
  background: url('./resources/cancel.svg') no-repeat center center;
  background-size: 0.14rem 0.14rem;
}

.guideImage .content p {
  width: 2rem;
}

.mask {
  background: #000;
  opacity: 0.3;
}