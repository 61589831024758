.wrapper {
  margin-bottom: 0.8rem;
  padding-bottom: 1.28rem;
}

.wrapper .freeShippingBanner {
  height: 0.3rem;
  background: #f6f6f6;
  color: #0e0e10;
  font-size: 0.14rem;
  line-height: 0.3rem;
}

.wrapper.inDesktop {
  background: white;
  margin: 0;
  box-sizing: content-box;
  padding: 0 0.25rem 0.4rem;
  margin: 0 auto;
  width: 11.98rem;
}

@media (max-width: 1366px) {
  .wrapper.inDesktop {
    width: calc(100% - 0.5rem);
  }
}

@media (min-width: 1074px) and  (max-width: 1366px) {
  .wrapper.inDesktop {
    width: 9.92rem;
  }
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .wrapper.inDesktop {
    width: 10.91rem;
  }
}

.wrapper .fixedBottomBar {
  position: fixed;
  bottom: 0;
  z-index: var(--z-index-aside);
}

.header .title {
  background-color: white;
  font-size: 0.14rem;
  color: var(--neutral900);
  line-height: 0.2rem;
  padding: 0.09rem 0 0 0.12rem;
  margin-top: 0.08rem;
}

.content {
  background-color: white;
  padding: 0.07rem 0 0.07rem 0.12rem;
  position: relative;
  margin-bottom: 0.08rem;
}

.sizeChartPadding {
  padding: 0.12rem;
}

.sizeChartPadding.isDesktop {
  padding: 0.2rem 0.3rem;
}