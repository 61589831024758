.pcTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 0.2rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: #333333;
  padding-left: 0.12rem;
  margin-bottom: 0.08rem;
}

.wrapper {
  width: 3.75rem;
  height: 0.44rem;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: var(--z-index-aside);
  padding: 0 0.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.01rem solid #F6F6F6;
}

.wrapper .title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 500;
  font-size: 0.17rem;
  line-height: 0.24rem;
  color: #17181A;
  text-align: center;
  max-width: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrapper .iconLeft {
  background: url('./arrow-bold.svg') no-repeat center;
  display: block;
  background-size: contain;
  width: 0.16rem;
  height: 0.16rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  font-weight: bold;
  line-height: 0.3rem;
}