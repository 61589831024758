.drawer {
  --header-height: 0.42rem;
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: var(--z-index-modal);
}

.drawer .cover {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-modal);
  -webkit-animation: coverIn 0.3s ease;
  animation: coverIn 0.3s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 0;
  z-index: var(--z-index-modal);
}

.drawer .content {
  position: fixed;
  background-color: #fff;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  z-index: var(--z-index-modal);
}

.drawer.overflow .content {
  background-color: transparent;
}

.drawer.right .content {
  top: 0;
  right: -100%;
  height: 100%;
  -webkit-animation-name: drawerOpen;
  animation-name: drawerOpen;
}

.drawer.bottom .content {
  bottom: -100%;
  left: 0;
  width: 100%;
  -webkit-animation-name: drawerOpenDown;
  animation-name: drawerOpenDown;
}

.drawer.needScroll.right .content .body {
  height: 100vh;
  overflow-x: scroll;
}

.drawer.bottom:not(.fullScreen, .overflow) .content {
  overflow: hidden;
}

.drawer.bottom:not(.fullScreen) .content .body {
  max-height: 75vh;
  overflow-y: scroll;
}

.drawer.bottom.fullScreen .content {
  height: 100%;
}

.drawer.bottom.fullScreen .content.isApp {
  height: calc(100vh - var(--safe-area-inset-top));
}

.drawer.bottom.fullScreen .content .body {
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 1.2rem;
}

.drawer.bottom.headerTitle .content .body {
  padding-top: var(--header-height);
}

/* mobile 样式 */

.drawer:not(.isDesktop) .content .header {
  width: 3.75rem;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.01rem solid #F5F5F5;
  font-style: normal;
  font-size: 0.14rem;
  line-height: 0.17rem;
  text-align: center;
  letter-spacing: -0.004rem;
  color: var(--neutral900);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: calc(var(--z-index-modal) + 1);
}

.drawer:not(.isDesktop) .content .header .closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.1436rem;
  line-height: 0.42rem;
  padding: 0 0.12rem;
  color: #4A4A4A;
}

/* pc 的样式 */

.drawer.isDesktop .content .header {
  height: 1.04rem;
  padding: 0.15rem 0.3rem;
  display: flex;
  align-items: flex-end;
  border-bottom: 0.01rem solid #E6E6E6;
  position: relative;
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.34rem;
  color: var(--neutral900);
}

.drawer.isDesktop .content .header .closeIcon {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  padding: 0.02rem;
  font-size: 0.2rem;
  color: #333333;
  cursor: pointer;
}

.closeAnimation {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.closeAnimation.bottom .content {
  -webkit-animation-name: closeDrawerBottom;
  animation-name: closeDrawerBottom;
}

.closeAnimation.right .content {
  -webkit-animation-name: closeDrawerRight;
  animation-name: closeDrawerRight;
}

.closeAnimation .cover {
  -webkit-animation-name: closeDrawerCover;
  animation-name: closeDrawerCover;
}

/* animation */

@-webkit-keyframes closeDrawerBottom {
  from {
    opacity: 0.7;
    bottom: 0;
  }

  to {
    opacity: 0;
    bottom: -100%;
  }
}

@keyframes closeDrawerBottom {
  from {
    opacity: 0.7;
    bottom: 0;
  }

  to {
    opacity: 0;
    bottom: -100%;
  }
}

@-webkit-keyframes closeDrawerRight {
  from {
    opacity: 0.7;
    right: 0;
  }

  to {
    opacity: 0;
    right: -100%;
  }
}

@keyframes closeDrawerRight {
  from {
    opacity: 0.7;
    right: 0;
  }

  to {
    opacity: 0;
    right: -100%;
  }
}

@-webkit-keyframes closeDrawerCover {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 0;
  }
}

@keyframes closeDrawerCover {
  from {
    opacity: 0.7;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes coverIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@keyframes coverIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@-webkit-keyframes drawerOpen {
  from {
    opacity: 0;
    right: -100%;
  }

  to {
    opacity: 1;
    right: 0;
  }
}

@keyframes drawerOpen {
  from {
    opacity: 0;
    right: -100%;
  }

  to {
    opacity: 1;
    right: 0;
  }
}

@-webkit-keyframes drawerOpenDown {
  from {
    opacity: 0;
    bottom: -100%;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes drawerOpenDown {
  from {
    opacity: 0;
    bottom: -100%;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}