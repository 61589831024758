.card {
  width: 100%;
  position: relative;
  background-color: #FFF;
  border-radius: 0.06rem;
  margin-bottom: 0.07rem;
  overflow: hidden;
}

.card.inDesktop {
  margin-bottom: 0;
  width: 100%;
  border-radius: 0;
  background-color: white;
}

.card .image {
  width: 1.78rem;
}

.card.inDesktop .image {
  border-radius: 0;
  width: auto;
  height: auto;
}

.info {
  padding: 0.04rem 0.04rem 0.06rem 0.04rem;
  display: block;
  width: 1.7rem;
}

.card.inDesktop .info {
  width: auto;
  padding: 0.08rem;
  background-color: var(--white);
}

.card .floatDiscount {
  position: absolute;
  left: 0.05rem;
  top: 0.05rem;
  width: 0.34rem;
  font-size: 0.1rem;
  line-height: 0.16rem;
  border-radius: 0;
  background-color: rgb(235, 25, 25, 0.8);
  color: var(--white);
  font-weight: 600;
  font-style: italic;
  text-align: center;
}

.card.inDesktop .floatDiscount {
  width: 0.42rem;
  height: 0.2rem;
  background-color: #EB1919;
  font-style: italic;
  font-weight: 700;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #FFFFFF;
}

.simpleDiscount {
  position: absolute;
  left: 0.08rem;
  top: 0.08rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  background-color: #e9070799;
  color: var(--white);
  font-style: italic;
  text-align: center;
  font-weight: bold;
  padding: 0 0.07rem;
}

.title {
  font-size: 0.12rem;
  line-height: 0.14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #767676;
}

.card.inDesktop .title.nowrap,
.card .title.nowrap {
  height: unset;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  grid-gap: 0.04rem;
  gap: 0.04rem;
  margin-bottom: 0.02rem;
}

.titleWrapper .newTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.03rem;
  border-radius: 0 0.05rem;
  background: rgba(33, 167, 0, 0.75);
  color: #FFF;
  font-size: 0.1rem;
  line-height: 0.12rem;
  height: 0.12rem;
  font-weight: 600;
  text-transform: uppercase;
}

.card.inDesktop .titleWrapper .newTag {
  padding: 0 0.04rem;
  font-size: 0.1rem;
  line-height: 0.14rem;
  height: 0.14rem;
}

.titleWrapper .title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.14rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.card.inDesktop .titleWrapper .title {
  height: 0.16rem;
  font-size: 0.14rem;
  line-height: 0.16rem;
  color: var(--neutral900);
}

.card.inDesktop .title {
  height: 0.28rem;
  /* width: 240px;
  margin: 0 auto; */
}

.price {
  height: 0.3rem;
  padding-bottom: 0.04rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card.inDesktop .price {
  padding: 0.08rem 0 0.12rem 0;
}

.tags {
  display: flex;
  margin-top: 0.08rem;
}

.tags .tag {
  color: var(--primary);
  font-size: 0.09rem;
  line-height: 0.11rem;
  padding: 0.02rem;
  border: 0.01rem solid var(--primary);
  border-radius: 0.02rem;
  margin-right: 0.02rem;
}

.ColorGalleryPlaceholder {
  height: 0.46rem;
}

.addIcon {
  font-size: 0.12rem;
  position: absolute;
  top: 0;
  right: 0.0487rem;
  font-size: 0.2rem;
  cursor: pointer;
  z-index: var(--z-index-flow-clickable);
}

.quickadd {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.card .colorSwatchBox {
  position: relative;
  height: 0.22rem;
}

.card.inDesktop .colorSwatchBox {
  position: relative;
}

.card .contentWrapper {
  margin-top: 0.04rem;
  padding: 0 0.04rem 0.1rem 0.04rem;
  line-height: 0;
}

.card.inDesktop .contentWrapper {
  padding: 0;
  margin-top: 0.05rem;
  margin-bottom: 0.28rem;
}

.card .specialNote {
  height: 0.24rem;
  font-weight: 500;
  font-size: 0.1rem;
  line-height: 0.12rem;
  color: #D10000;
  margin-top: 0.04rem;
}

.card.inDesktop .specialNote {
  height: 0.4rem;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #D10000;
  margin-top: 0.05rem;
}

.card.inDesktop .price .productCardFlashIcon {
  font-size: 0.18rem;
  line-height: 0.18rem;
  margin-left: 0.01rem;
}