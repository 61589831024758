.wrapper {
  padding: 0 0.12rem 1rem;
  background-color: var(--white);
  overflow: hidden;
}

.wrapper.isDesktop {
  max-width: 8rem;
  margin: 0 auto;
}

.wrapper .mobileTitle {
  margin-top: 0.2rem;
  font-weight: 600;
  font-size: 0.17rem;
  line-height: 0.24rem;
  color: var(--neutral900);
}

.wrapper .title {
  margin-top: 0.2rem;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.wrapper .desc {
  margin-top: 0.12rem;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.payMethodCardGroup {
  margin-top: 0.12rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 0.64rem);
  grid-row-gap: 0.08rem;
  row-gap: 0.08rem;
  grid-column-gap: 0.04rem;
  -webkit-column-gap: 0.04rem;
  column-gap: 0.04rem;
}

.cardImage {
  margin-top: 0.12rem;
  width: 0.64rem;
  height: 0.4rem;
}