.header {
  font-size: 0.2rem;
  line-height: 0.2rem;
  font-weight: 600;
}

.topHeader {
  padding-bottom: 0.2rem;
  background-color: var(--white);
  border-bottom: 0.01rem solid #d9d9d9;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: var(--neutral900);
}

.topHeader .selectIcon {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.16rem;
}

.priceSection {
  margin-top: 0.06rem;
  padding-top: 0.08rem;
  border-top: 0.01rem solid var(--gray);
}

.priceRow {
  display: flex;
  justify-content: space-between;
  font-size: 0.15rem;
  line-height: 0.18rem;
  font-weight: 600;
  margin-bottom: 0.1rem;
}

.priceRow.couponHint {
  font-size: 0.11rem;
  line-height: 0.18rem;
  color: #67605f;
  margin: -0.08rem 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.priceRow .title {
  font-weight: normal;
}

.priceRow.text .price::before {
  content: "";
}

.priceRow .price.negative::before {
  content: "- ";
}

.priceRow.orderTotal {
  border-top: 0.01rem solid var(--gray);
  padding-top: 0.1rem;
}

.priceRow.orderTotal .title {
  font-weight: 600;
}

.priceRow.orderTotal .price {
  font-size: 0.16rem;
  color: var(--color-price);
}

.priceRow.freeShipping .price::before {
  content: none;
}

.cartItems {
  background-color: var(--white);
  height: 100%;
}

.cartItem {
  padding-left: 0.34rem;
  position: relative;
}

.cartItem .selectIconBox {
  position: absolute;
  left: 0;
  top: 0.5rem;
  padding-top: 0.2rem;
  display: flex;
  align-items: center;
}

.cartItem .selectIconBox .selectIcon {
  width: 0.2rem;
  height: 0.2rem;
}

.cartItem:not(:last-of-type) {
  padding-bottom: 0.2rem;
  border-bottom: 0.01rem solid var(--gray);
}

.paypalBtn {
  width: 100%;
  height: 0.45rem;
  background: rgb(255, 196, 57)
    url("https://img.flamingo.shop/p/files/Lark20191119-191609.png?101024") no-repeat center center;
  background-size: 0.75rem 0.19rem;
  border-radius: 0.04rem;
  margin-top: 0.08rem;
}

.unsaleableLineItems .header {
  display: flex;
  justify-content: space-between;
  font-size: 0.2rem;
  color: var(--neutral900);
}

.wrapper {
  background-color: var(--white);
  position: relative;
  padding: 0.3rem;
}

.wrapper .loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-index-modal);
  background-color: rgba(255, 255, 255, .6);
}

.wrapper > span:nth-child(1) {
  font-weight: bold;
}

.buttonGroup {
  padding-bottom: 0.2rem;
}

.wrapper .buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wrapper .buttonGroup .checkoutBtn,
.wrapper .buttonGroup .paypalBtn {
  width: 3.75rem;
}

.clearBtn {
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.18rem;
}

.unsaleableCartItems {
  padding: 0 0.3rem 0.3rem 0.3rem;
  background-color: var(--white);
  margin-top: 0.3rem;
  padding-top: 0.3rem;
}

.cartItems .cartItem {
  padding-top: 0.2rem !important;
}

.cartDataRow {
  height: 0.6rem;
  box-sizing: border-box;
  border-bottom: 0.01rem solid var(--gray);
  display: flex;
  flex-direction: row-reverse;
  background-color: var(--white);
  position: relative;
}

.cartDataRow .selectAll {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.66rem;
  display: flex;
  align-items: center;
}

.cartDataRow .selectAll span {
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.cartDataRowItem {
  height: 100%;
  width: 1.23rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.unsaleableLineItems .header .unsaleIcon {
  margin-left: 0.06rem;
  font-size: 0.18rem;
  display: inline-block;
  cursor: pointer;
}

.header .unsaleIcon.active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.syncTip {
  background-color: #1a1a1a;
  background-image: url('https://img.flamingo.shop/p/show/039a66852d904552928064ff955a8164.png');
  background-size: cover;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #FFFFFF;
  padding: 0.17rem 0.92rem 0.17rem 0.3rem;
  position: relative;
  margin-bottom: 0.24rem;
}

.syncTip .signBtn {
  position: absolute;
  top: 50%;
  right: 0.3rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.7rem;
  height: 0.23rem;
  line-height: 0.21rem;
  text-align: center;
  border: 0.01rem solid #FFFFFF;
  border-radius: 0.2rem;
  cursor: pointer;
}

.syncItemTip {
  border-top: 0.01rem solid #F5F5F5;
  padding: 0.24rem 0.3rem;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.17rem;
  color: #646464;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
}

.syncItemTip > div {
  white-space: nowrap;
  margin-right: 0.05rem;
}

.syncItemTip .syncItemTipLink {
  display: inline-block;
  white-space: nowrap;
  color: #F36F24;
  position: relative;
  cursor: pointer;
}

.syncItemTip .syncItemTipLink::after {
  content: "\e668";
  display: inline-block;
  width: 0.11rem;
  height: 0.12rem;
  line-height: 0.12rem;
  -webkit-transform: translateY(0.01rem);
  transform: translateY(0.01rem);
  margin-left: 0.02rem;
}