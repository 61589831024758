.wrapper {
  position: relative;
}

.wrapper .handCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: calc(var(--z-index-aside) - 1);
  background-color: rgba(0, 0, 0, 0.3);
}

.body {
  background-color: #fff;
  width: 3.75rem;
  border-bottom: 0.01rem solid #F5F5F5;
  --card-width: 1.69rem;
}

.body.coverTopOne {
  position: relative;
  z-index: var(--z-index-aside);
}

.body .header {
  width: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem;
}

.body .header.isDesktop {
  padding: 0.1rem 0.2rem;
}

.body .header .title {
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral900);
}

.body  .header .btn {
  width: auto;
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.22rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--neutral900);
  background-color: #fff;
  min-width: 0.89rem;
  height: 0.24rem;
  text-align: center;
  border: 0.01rem solid var(--neutral500);
  border-radius: 0.2rem;
  white-space: nowrap;
  flex-shrink: 0;
  padding: 0 0.12rem;
  cursor: pointer;
}

.couponCard {
  width: 1.66rem;
}