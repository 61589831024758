.wrapper {
  position: relative;
  width: 3.75rem;
}

.wrapper .imagePlayer {
  background: black;
  display: block;
  height: 5rem;
}

.wrapper.inDesktop .imagePlayer {
  height: 6rem;
}

.wrapper.inDesktop .imagePlayer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper .imagePlayer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pagination {
  position: absolute;
  right: 0.15rem;
  top: 0.15rem;
  padding: 0 0.05rem;
  height: 0.19rem;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 0.2rem;
  color: white;
  text-align: center;
  line-height: 0.19rem;
  font-size: 0.12rem;
}

.wrapper .pcSwiperButton {
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.36rem;
  height: 0.36rem;
  --border-width: 0.02rem;
  border: var(--border-width) solid #fff;
  box-sizing: border-box;
  z-index: var(--z-index-layer-1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  opacity: 0.7;
}

.wrapper .pcLeftButton {
  left: 0.2rem;
  top: 50%;
  padding-left: 1%;
}

.desktopWrapper .wrapper .pcLeftButton {
  left: 1.16rem;
}

.wrapper .pcRightButton {
  right: 0.2rem;
  top: 50%;
  padding-right: 1%;
}

.wrapper .pcLeftButtonArrow {
  width: 0.12rem;
  height: 0.12rem;
  border-top: var(--border-width) solid var(--neutral900);
  border-left: var(--border-width) solid var(--neutral900);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.wrapper .pcRightButtonArrow {
  width: 0.12rem;
  height: 0.12rem;
  border-top: var(--border-width) solid var(--neutral900);
  border-left: var(--border-width) solid var(--neutral900);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.wrapper .pcLeftButton:hover,
.wrapper .pcRightButton:hover {
  background-color: #fff;
  opacity: 1;
  color: var(--neutral900);
}

.wrapper .pcLeftButton:hover .pcLeftButtonArrow,
.wrapper .pcRightButton:hover .pcRightButtonArrow {
  border-top: var(--border-width) solid var(--neutral900) !important;
  border-left: var(--border-width) solid var(--neutral900) !important;
}