.wrapper {
  width: 3.75rem;
  height: 1.26rem;
  margin-bottom: 0.08rem;
  background-color: #fff;
  padding: 0.12rem;
  display: flex;
  position: relative;
  align-items: center;
}

.content {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.18rem;
  color: var(--neutral900);
}

.close {
  position: absolute;
  float: right;
  top: 0.05rem;
  right: 0.05rem;
  width: 0.26rem;
  height: 0.26rem;
  background: url('https://img.flamingo.shop/p/files/close.png?v=1579332768') no-repeat center center;
  background-size: 100%;
}

.button {
  flex: 1 1;
  height: 0.36rem;
  border-radius: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 0.14rem;
  line-height: 0.14rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  background: var(--neutral900);
  white-space: nowrap;
  padding: 0 0.12rem;
}