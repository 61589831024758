.photoSwipe {
  display: block !important;
}

.wrapper {
  position: relative;
  overflow-y: hidden;
  height: 3.72rem;
  --img-width-pc: 7.02rem;
  --small-img-width-pc: 0.66rem;
  --small-img-height-pc: 0.99rem;
}

.wrapper.inDesktop {
  position: relative;
  display: flex;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: 0;
  height: auto;
}

.wrapper.smallSize.inDesktop {
  height: 100%;
  margin-right: 0.12rem;
}

.wrapper .thumbnails {
  overflow: auto;
}

.wrapper.smallSize .thumbnails {
  height: 2.12rem;
  overflow-y: scroll;
  flex-shrink: 0;
}

.thumbnails::-webkit-scrollbar {
  width: 0;
}

.wrapper .thumbnails .thumbnail {
  width: var(--small-img-width-pc);
  height: var(--small-img-height-pc);
  margin: 0 0.1rem 0.1rem 0;
}

.wrapper.smallSize .thumbnails .thumbnail {
  width: 0.37rem;
  height: 0.49rem;
  margin: 0 0.09rem 0.06rem 0;
}

.wrapper .thumbnails .thumbnail.highlighted::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  border: 0.01rem solid #222;
  box-shadow: 0 0 0 0.06rem #fff inset;
  background: 0 0;
}

.firstImgWrapper {
  position: relative;
}

.photoWrapper {
  position: relative;
  width: 2.48rem;
  height: 3.72rem;
  border-radius: inherit;
  display: flex;
  margin-right: 0.08rem;
}

.photoWrapper.videoWrapper {
  width: 2.09rem;
}

.firstImgWrapper .wishButton {
  position: absolute;
  top: 0.08rem;
  right: 0.16rem;
  width: 0.3rem;
  height: 0.3rem;
  z-index: var(--z-index-layer-1);
  background-color: #fff;
  border-radius: 50%;
}

.firstImgWrapper .wishButton > div > div::after {
  font-size: 0.3rem !important;
  width: 0.3rem !important;
  height: 0.3rem !important;
  line-height: 0.3rem !important;
}

.wrapper.inDesktop .photoWrapper {
  display: block;
  width: var(--img-width-pc);
  height: auto;
}

.wrapper.inDesktop .photoWrapper.flexible {
  width: 100%;
}

.photoWrapper img {
  margin: 0 auto;
}

.sliderWrapper {
  position: relative;
  width: var(--img-width-pc);
}

.wrapper.smallSize .sliderWrapper {
  width: 2.21rem;
  height: 2.78rem;
}

.sliderWrapper .leftButton {
  position: absolute;
  left: 0.24rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.6rem;
  height: 0.6rem;
  background: url("https://img.flamingo.shop/p/files/Left_1x_5d1bad60-9d33-4c6f-b233-27654ee3b1b5.png?v=1599732255")
    no-repeat center center;
  background-size: 100%;
  z-index: var(--z-index-layer-1);
}

.sliderWrapper .rightButton {
  position: absolute;
  right: 0.24rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.6rem;
  height: 0.6rem;
  background: url("https://img.flamingo.shop/p/files/Right_1x_f86443a7-6c8f-4faa-88f6-0071f351e267.png?v=1599732255")
    no-repeat center center;
  background-size: 100%;
  z-index: var(--z-index-layer-1);
}

.sliderWrapper .pcSwiperButton {
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.4rem;
  height: 0.4rem;
  --border-width: 0.02rem;
  border: var(--border-width) solid #fff;
  box-sizing: border-box;
  z-index: var(--z-index-layer-1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  opacity: 0.7;
}

.sliderWrapper .pcLeftButton {
  left: 0.4rem;
  top: 50%;
  padding-left: 1%;
}

.desktopWrapper .sliderWrapper .pcLeftButton {
  left: 1.16rem;
}

.sliderWrapper .pcRightButton {
  right: 0.4rem;
  top: 50%;
  padding-right: 1%;
}

.sliderWrapper .pcLeftButtonArrow {
  width: 0.12rem;
  height: 0.12rem;
  border-top: var(--border-width) solid var(--neutral900);
  border-left: var(--border-width) solid var(--neutral900);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sliderWrapper .pcRightButtonArrow {
  width: 0.12rem;
  height: 0.12rem;
  border-top: var(--border-width) solid var(--neutral900);
  border-left: var(--border-width) solid var(--neutral900);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.sliderWrapper .pcLeftButton:hover,
.sliderWrapper .pcRightButton:hover {
  background-color: #fff;
  opacity: 1;
  color: var(--neutral900);
}

.sliderWrapper .pcLeftButton:hover .pcLeftButtonArrow,
.sliderWrapper .pcRightButton:hover .pcRightButtonArrow {
  border-top: var(--border-width) solid var(--neutral900) !important;
  border-left: var(--border-width) solid var(--neutral900) !important;
}

.wrapper.smallSize .sliderWrapper .leftButton,
.wrapper.smallSize .sliderWrapper .rightButton {
  width: 0.3rem;
  height: 0.3rem;
}

.wrapper.smallSize .sliderWrapper .pcSwiperButton {
  width: 0.3rem;
  height: 0.3rem;
}

.wrapper.smallSize .sliderWrapper .pcLeftButtonArrow,
.wrapper.smallSize .sliderWrapper .pcRightButtonArrow {
  width: 0.085rem;
  height: 0.085rem;
}

.pagination {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.1rem;
  width: 0.36rem;
  height: 0.15rem;
  background: rgba(74, 74, 74, 0.6);
  border-radius: 0.11rem;
  color: white;
  text-align: center;
  line-height: 0.15rem;
  font-size: 0.11rem;
}

.badge {
  position: absolute;
  bottom: 0.16rem;
  left: 0.16rem;
  width: 1.08rem;
  height: 1.08rem;
  z-index: var(--z-index-layer-1);
}

.wrapper.inDesktop .badge {
  top: 0.16rem;
  left: 0.16rem;
  width: 1.2rem;
  height: 1.2rem;
}

.wrapper.smallSize:not(.inDesktop) {
  height: 2.05rem;
}

.wrapper.smallSize:not(.inDesktop) .singleSlider {
  height: 2.05rem;
}

.wrapper.smallSize:not(.inDesktop) .photoWrapper {
  width: 1.36rem;
  height: auto;
}

.wrapper.inDesktop.smallSize .photoWrapper {
  width: 2.21rem;
  height: 2.78rem;
}

.wrapper.smallSize:not(.inDesktop) .pagination {
  left: 0.9rem;
  -webkit-transform: none;
  transform: none;
  bottom: 0.1rem;
}

.desktopWrapper {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.desktopWrapper.flexible {
  display: flex;
  min-width: 1.74rem;
}

.desktopWrapper::-webkit-scrollbar {
  display: none;
}

.desktopWrapper .leftSide {
  height: 0.01rem;
  display: inline-block;
  overflow: visible;
  position: relative;
  z-index: var(--z-index-flow-clickable);
}

.desktopWrapper .rightSide {
  width: 7.02rem;
  float: right;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
}

.desktopWrapper.flexible .rightSide {
  min-width: 7.53rem;
  box-sizing: border-box;
  padding-left: calc(var(--small-img-width-pc) + 0.1rem);
}

@media (max-width: 1074px) {
  .desktopWrapper.flexible .rightSide {
    min-width: unset;
    width: calc(100vw - 4.45rem - 0.5rem - 0.76rem);
    padding-left: 0;
    overflow: hidden;
  }
}

@media (min-width: 1074px) and  (max-width: 1366px) {
  .desktopWrapper.flexible .rightSide {
    min-width: 5.47rem;
  }
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .desktopWrapper.flexible .rightSide {
    min-width: 6.46rem;
  }
}