.guideWrapper {
  --bar-width: 0.31rem;
  position: fixed;
  right: 0;
  bottom: 3rem;
  z-index: calc(var(--z-index-flow-clickable) + 3);
  width: auto;
  transition: all ease 0.3s;
  display: flex;
}

.guideWrapper.guideHide {
  right: var(--bar-width);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.guideWrapper .guideBtn {
  position: relative;
  width: var(--bar-width);
  height: 2.72rem;
  background: url('https://img.flamingo.shop/p/files/1_c86784e2-7037-41a4-accf-d32588243a05.png?v=1603518892') no-repeat;
  background-size: 100%;
  cursor: pointer;
}