.wrapper {
  position: relative;
}

.wrapper .fixBottomWrapper {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wrapper .fixBottomWrapper .countdownClassName {
  position: absolute;
  right: 0.12rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.1rem;
  color: white;
  font-weight: 600;
  line-height: 0.12rem;
}

.wrapper .fixBottomWrapper .imageWrapper {
  position: relative;
}

.wrapper .fixBottomWrapper .imageWrapper.isDesktop .countdownClassName {
  font-size: 0.12rem;
  line-height: 0.16rem;
  right: 0.03rem;
}

.wrapper .fixBottomWrapper .imageWrapper .image {
  display: block;
  width: 100%;
}

.wrapper .fixBottomWrapper .colorSwatchWrapper {
  width: 0.15rem;
  padding: 0.02rem 0;
  border-radius: 0.17rem;
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 0.05rem;
  margin-right: 0.05rem;
}

.wrapper .fixBottomWrapper .colorSwatchWrapper .colorSwatch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.02rem;
  gap: 0.02rem;
}

.wrapper .fixBottomWrapper .colorSwatchWrapper .colorSwatchBox {
  position: relative;
  width: 0.11rem;
  height: 0.11rem;
  border-radius: 50%;
}

.wrapper .fixBottomWrapper .colorSwatchWrapper .colorSwatchBoxBorder {
  position: relative;
  width: 0.22rem;
  height: 0.22rem;
  border-radius: 50%;
  border: 0.01rem solid #FFF;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  overflow: hidden;
}

.wrapper .fixBottomWrapper .colorSwatchWrapper .colorSwatchCount {
  color: #F7F7F7;
  font-size: 0.12rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.13rem;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  margin-bottom: -0.03rem;
}