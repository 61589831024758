.page {
  position: relative;
  background: #fff;
}

.stickyHeader {
  width: 100%;
  /* position: sticky;
  top: 0;
  z-index: var(--z-index-aside);
  transition: all ease .3s; */
}

.cartOpen {
  overflow: hidden;
}

.cartOpen .content {
  overflow: hidden;
  height: 100vh;
}

.cartOpen .content > * {
  pointer-events: none;
}

.content {
  max-width: 3.75rem;
  margin: 0 auto;
  padding-bottom: 0.2rem;
}

.content.desktopScreen {
  max-width: 19.2rem;
}

.page .coverCart {
  width: 3rem;
  /* height: 100vh; */
  bottom: 0;
  height: auto;
  position: fixed;
  top: 0;
  right: -3rem;
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  opacity: 0;
  z-index: var(--z-index-aside);
  border-left: 0.01rem solid var(--gray);
  overflow: auto;
}

.cartOpen .coverCart {
  -webkit-transform: translate(-3rem);
  transform: translate(-3rem);
  opacity: 1;
}