.wrapper {
  max-height: 8rem;
  overflow: hidden;
  position: relative;
}

.wrapper.expanded {
  max-height: unset;
}

.wrapper .cover {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: calc(var(--z-index-flow-clickable) - 1);
  width: 100%;
  height: 0.9rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 64.06%, #FFFFFF 100%);
  padding-top: 0.62rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .cover .icon {
  display: inline-block;
  width: 0.2rem;
  line-height: 0.16rem;
  font-size: 0.16rem;
  margin-left: 0.06rem;
}