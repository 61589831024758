.quickAddCard {
  position: relative;
}

.quickAddCard .quickAddBtn {
  position: absolute;
  z-index: var(--z-index-flow-clickable);
  width: 80%;
  height: 0.4rem;
  left: 0;
  margin-left: 10%;
  bottom: 0.5rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.16rem;
  text-transform: uppercase;
  color: var(--neutral900);
}