.wrapper {
  position: fixed;
  left: 0;
  bottom: calc(-0.4rem - max(var(--fixed-footer-bottom), 0.08rem));
  width: 3.75rem;
  z-index: calc(var(--z-index-modal) - 1);
  transition: all 0.6s ease;
}

.wrapper .card {
  position: relative;
  width: 3.75rem;
  height: 0.4rem;
  margin: 0 auto;
  background: rgba(0,0,0,0.7);
  padding-left: 0.7rem;
  display: flex;
  align-items: center;
}

.wrapper .card .close {
  position: absolute;
  top: 0.14rem;
  right: 0.1rem;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  width: 0.3rem;
  height: 0.3rem;
  font-size: 0.16rem;
  display: flex;
  border-bottom-left-radius: 100%;
  border-top-right-radius: 0.04rem;
  padding-left: 0.08rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  color: #fff;
}

.wrapper .card .freeGiftImage {
  position: absolute;
  bottom: 0.05rem;
  left: 0.12rem;
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 0.04rem;
}

.wrapper .card .title {
  width: calc(100% - 1.06rem);
  color: white;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 0.2rem;
}

.wrapper .card .btn {
  position: absolute;
  top: 0.08rem;
  right: 0.3rem;
  height: 0.26rem;
  width: 0.7rem;
  background: #F36F24;
  color: #FFFFFF;
  border-radius: 0.2rem;
  padding: 0 0.07rem;
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.2rem;
  text-transform: uppercase;
}

.wrapper .card .btn .cart {
  font-size: 0.13rem;
  font-weight: normal;
  margin-right: 0.03rem;
}

.wrapper .card .loadingRoller {
  height: 0.18rem;
  width: 0.18rem;
}

.wrapper .card .loadingRoller>div {
  background-color: #fff;
  border-radius: 100%;
  margin: 0.02rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0.02rem solid #fff;
  border-bottom-color: rgba(42, 42, 42, .1);
  height: 0.16rem;
  width: 0.16rem;
  background: transparent !important;
  display: inline-block;
  -webkit-animation: rotate 0.75s 0s linear infinite;
  animation: rotate 0.75s 0s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes show {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes show {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.emailWrapper {
  background: black;
  padding: 0.25rem 0.08rem;
  display: flex;
}

@supports (padding: max(0px)) {
  .emailWrapper {
    padding-bottom: max(var(--fixed-footer-bottom), 40px);
  }
}

.emailWrapper .close {
  position: absolute;
  top: 0;
  right: 0;
  background: #3A3A3A;
  width: 0.44rem;
  height: 0.44rem;
  font-size: 0.2rem;
  display: flex;
  border-bottom-left-radius: 100%;
  border-top-right-radius: 0.04rem;
  padding-left: 0.16rem;
  padding-top: 0.08rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  color: #fff;
}

.emailWrapper .freeGiftImage {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.06rem;
  margin-right: 0.1rem;
}

.emailWrapper .rightWrapper {
  flex: 1 1;
}

.emailWrapper .rightWrapper .title {
  font-size: 0.14rem;
  line-height: 0.18rem;
  margin-bottom: 0.05rem;
  font-weight: 600;
  color: white;
}

.emailWrapper .rightWrapper .inputRow {
  display: flex;
}

.emailWrapper .rightWrapper .inputRow .mainInput {
  flex: 1 1;
  color: #111;
  height: 0.34rem;
  line-height: 0.16rem;
  font-size: 0.12rem;
  width: 100%;
  background-color: var(--white);
  padding: 0 0.12rem;
  text-align: left;
  margin-bottom: 0.07rem;
}

.mainInput::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.mainInput:-ms-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.mainInput::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.emailWrapper .rightWrapper .inputRow .btn {
  width: 0.85rem;
  height: 0.34rem;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.34rem;
  text-align: center;
  text-transform: uppercase;
  color: white;
  background: #F36F24;
  border-radius: 0;
}

.privacyWrapper {
  width: 120%;
  font-style: normal;
  font-weight: 400;
  height: 0.16rem;
  font-size: 0.12rem;
  line-height: 0.16rem;
  letter-spacing: -0.005rem;
  color: white;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: left;
  transform-origin: left;
  margin-bottom: 0.16rem;
}