.navTabBoxDrawerPosition {
  top: 1.84rem;
  right: -100%;
  height: 100%;
}

.colorOrSizeTabDrawerPosition {
  top: 1.42rem;
  right: -100%;
  height: 100%;
}

.coverClass {
  height: calc(100%-1.84rem) !important;
  top: 1.84rem !important;
}

.drawerBodyClass {
  width: 3.75rem;
}

.sortDrawerBodyClass {
  width: 3.75rem;
  padding: 0 0.12rem;
}

.drawerContentClass {
  border-radius: 0 0 0.06rem 0.06rem;
  overflow: hidden;
}