.wrapper {
  position: relative;
}

.body.isDesktop {
  color: black;
  height: 100vh;
  width: 46vw;
  background: #fff;
  border-radius: 0.08rem;
  overflow-y: scroll;
  padding-bottom: 2rem;
}

.body.isDesktop::-webkit-scrollbar {
  display: none;
}

.body:not(.isDesktop) {
  color: black;
  height: 90vh;
  width: 100vw;
  background: #fff;
  border-radius: 0.08rem;
  overflow: scroll;
  padding-bottom: 2rem;
}

.body:not(.isDesktop) .closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.16rem;
}

.body.isDesktop .closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.2rem 0.3rem;
}