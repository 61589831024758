.wrapper {
  background-color: #F5F5F5;
}

.tabs {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  font-size: 0.12rem;
  color: var(--neutral900);
  line-height: 0.18rem;
  padding: 0.12rem 0 0.12rem 0.12rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0.4rem;
  z-index: calc(var(--z-index-flow-clickable) + 3);
  background-color: #fff;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tabs .tabItem {
  margin-right: 0.2rem;
  text-wrap: nowrap;
}

.tabs .tabItem.active {
  position: relative;
  color: var(--neutral900);
  font-weight: 600;
}

.tabs .tabItem.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.02rem;
  width: 100%;
  height: 0.02rem;
  background: var(--neutral900);
}

.title {
  background: #FFF;
  font-size: 0.14rem;
  font-weight: 700;
  line-height: 0.18rem;
  color: var(--neutral900);
  padding: 0.1rem 0.12rem;
}

.quickAddCard {
  position: relative;
}

.addToCartBtn {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  z-index: var(--z-index-layer-1);
}

.pcAddIcon {
  font-size: 0.24rem;
}

.wrapper .list {
  background: linear-gradient(180deg, #FFF 0%, #F5F5F5 2.87%);
  padding: 0 0.06rem;
}

.wrapper .list .listColumn {
  overflow: hidden;
}

.wrapper .list .listColumn:not(:last-child) {
  margin-right: 0.07rem;
}

.wrapper .loading {
  height: auto;
}

.bottomMargin {
  margin-bottom: 0.08rem !important;
}