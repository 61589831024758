.listComponent {
  background: linear-gradient(180deg, #FFF 0%, #F5F5F5 2.87%);
  padding: 0 0.06rem;
}

.listComponent.pcRightAreaBox {
  background: #FFF;
  padding: 0;
}

.listComponent .listColumn {
  overflow: hidden;
}

.listComponent .listColumn:not(:last-child) {
  margin-right: 0.07rem;
}

.listComponent.pcRightAreaBox .listColumn:not(:last-child) {
  margin-right: 0;
}

.pcRightAreaBox {
  padding: 0;
}

.errorContainer {
  background: white;
  height: 75vh;
}

.reselectBtn {
  width: auto;
  height: auto;
  margin: 0 auto;
  font-size: 0.14rem;
  line-height: 0.2rem;
  font-weight: 600;
  padding: 0.08rem 0.2rem;
  border: 0.01rem solid var(--neutral900);
  color: var(--neutral900);
  background: transparent;
  border-radius: 0;
}

.noFilterRes {
  background: url('https://img.flamingo.shop/p/show/e36dd981d0d747a186d4b47eb73bd5d2.png') no-repeat center top 0.1rem;
  background-size: 0.45rem;
}

.isDesktop.noFilterRes {
  background: url('https://img.flamingo.shop/p/show/e36dd981d0d747a186d4b47eb73bd5d2.png') no-repeat center top 0.6rem;
  background-size: 0.6rem;
}

.isDesktop.noFilterRes {
  padding: 1.4rem 0.2rem 0.6rem 0.2rem;
}