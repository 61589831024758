.viewMore {
  color: var(--blue);
  cursor: pointer;
}

.wrapper {
  background-color: #fff;
  border-radius: 0.05rem;
  height: calc(100vh - 1.04rem);
}

.close {
  font-size: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 0.2rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: var(--z-index-flow-clickable);
  cursor: pointer;
}

.imageGallery {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 2.5rem;
  height: 100%;
  margin-right: -0.1rem;
  overflow-y: scroll;
}

.imageGallery::-webkit-scrollbar {
  display: none;
}

.imageGallery .productImage {
  width: 2.5rem;
  margin-bottom: 0.07rem;
}

.basicInfo {
  height: calc(100vh - 1.04rem);
  padding: 0 0.2rem;
  overflow-x: scroll;
}

.basicInfo::-webkit-scrollbar {
  display: none;
}

.toDetail {
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: right;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #666666;
}

.sizeChartPadding {
  padding: 0.12rem;
}

.sizeChartPadding.isDesktop {
  padding: 0.2rem 0.4rem;
}