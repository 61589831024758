.wrapper {
  background: white;
}

.wrapper.inDesktop {
  color: var(--neutral900);
  background: #F5F5F5;
  margin-bottom: 0.2rem;
}

.wrapper.inDesktop .desc {
  font-size: 0.14rem;
  line-height: 0.17rem;
  color: rgba(26, 26, 26, 0.6);
  font-weight: 500;
}

.guideInfo:not(.isDesktop) {
  display: flex;
  align-items: center;
  padding: 0.09rem 0.12rem 0.09rem 0.44rem;
  position: relative;
}

.guideInfo.isDesktop {
  display: flex;
  align-items: center;
}

.guideInfo.isDesktop:nth-child(1) {
  padding: 0.2rem 0.2rem 0.1rem 0.52rem;
}

.guideInfo.isDesktop:nth-child(2) {
  padding: 0.1rem 0.2rem 0.2rem 0.52rem;
}

.guideInfo:not(.isDesktop) .icon {
  font-size: 0.1729rem;
  margin-right: 0.12rem;
  font-weight: bold;
  padding-top: 0.02rem;
  color: var(--neutral900);
}

.guideInfo.isDesktop .icon {
  font-size: 0.24rem;
  margin-right: 0.1rem;
}

.guideInfo:not(.isDesktop) .guideContent {
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.16rem;
  color: var(--neutral900);
  font-weight: bold;
  position: relative;
}

.guideInfo:not(.returnPolicy):not(.isDesktop) .guideContent::before {
  content: "\e666";
  position: absolute;
  top: 50%;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.22rem;
  -webkit-transform: translateX(-100%) translateY(-50%);
  transform: translateX(-100%) translateY(-50%);
}

.guideInfo.returnPolicy:not(.isDesktop) .guideContent::before {
  content: "\e664";
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.22rem;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.guideInfo .guideTitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.guideInfo:not(.isDesktop) .guideDesc {
  font-weight: 400;
  margin-top: 0.03rem;
}

.guideInfo.isDesktop .guideContent {
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-transform: capitalize;
  color: var(--neutral900);
  position: relative;
}

.guideInfo:not(.returnPolicy).isDesktop .guideContent::before {
  content: "\e666";
  position: absolute;
  top: 50%;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.22rem;
  -webkit-transform: translateX(-100%) translateY(-50%);
  transform: translateX(-100%) translateY(-50%);
}

.guideInfo.returnPolicy.isDesktop .guideContent::before {
  content: "\e664";
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.22rem;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.guideInfo.isDesktop .guideDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: var(--neutral900);
  margin-top: 0.02rem;
}

.guideInfo.isDesktop .guideContent .questionMark {
  font-size: 0.12rem;
  margin-left: 0.08rem;
  cursor: pointer;
}

.guideInfo:not(.isDesktop)::after {
  content: "\e668";
  position: absolute;
  top: 0.09rem;
  right: 0.12rem;
  width: 0.18rem;
  height: 0.18rem;
  line-height: 0.18rem;
  text-align: center;
  color: var(--gray5);
}

.guideInfo:not(.returnPolicy):not(.isDesktop)::after {
  content: "\e668";
  position: absolute;
  top: 50%;
  right: 0.12rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.18rem;
  height: 0.18rem;
  line-height: 0.18rem;
  text-align: center;
  color: var(--gray5);
}

.guideInfo .estimated {
  font-weight: normal;
}

.shippingStrategy {
  position: relative;
  display: flex;
  padding: 0.17rem 0.12rem;
  font-size: 0.12rem;
  line-height: 0.2rem;
  border-bottom: 0.01rem solid var(--bg-gray2);
}

.wrapper.inDesktop .shippingStrategy {
  padding: 0;
  height: auto;
  margin-bottom: 0.12rem;
  line-height: 0.28rem;
  font-size: 0.14rem;
  border-bottom: 0;
  font-weight: 600;
}

.shippingStrategy::before {
  content: '';
  width: 0.18rem;
  background: url('./resources/shipping.svg') no-repeat center center;
  background-size: 100%;
  margin-right: 0.06rem;
  vertical-align: -0.02rem;
}

.wrapper.inDesktop .shippingStrategy::before {
  content: '';
  width: 0.24rem;
  height: 0.24rem;
  background: url('https://img.flamingo.shop/p/files/Delivery_1x_1e780595-0368-49d1-8421-d535f09de506.png?v=1600325182') no-repeat center center;
  background-size: 100%;
  margin-right: 0.143rem;
  vertical-align: -0.02rem;
}

.shippingStrategy .highlighted {
  color: var(--primary);
}

.wrapper.inDesktop .shippingStrategy .highlighted {
  font-weight: bold;
  color: var(--neutral900);
}

.wrapper.inDesktop .shippingStrategy.normal .highlighted {
  font-weight: bold;
  color: var(--neutral900);
  font-style: oblique;
  padding: 0 0.06rem;
}

.shippingStrategy span {
  padding: 0 0.02rem;
}

.content {
  position: relative;
  padding: 0.16rem 0.12rem;
}

.content .title {
  font-size: 0.12rem;
  line-height: 0.16rem;
}

.content .title:not(:last-of-type) {
  margin-bottom: 0.08rem;
}

.content .title::before {
  content: '';
  display: inline-block;
  margin: 0 0.08rem 0.03rem 0;
  width: 0.04rem;
  height: 0.04rem;
  border-radius: 50%;
  background: var(--primary);
}

.content .title .underline {
  text-decoration: underline;
}

.content .title .subTitle {
  color: #666;
}

.content .payments {
  margin: 0.08rem 0 0 0.12rem;
}

.content .payments .payment {
  width: auto;
  height: 0.26rem;
  margin-right: 0.04rem;
}

.popupContent {
  background: white;
  padding: 0.165rem 0.16rem 0.2rem 0.16rem;
  width: 3.75rem;
  margin: 0 auto;
}

.popupContent.isDesktop {
  padding: 0;
  width: 4.2rem;
  margin: 0.3rem 0.31rem;
}

.popupContent .sections:not(:last-of-type) {
  margin-bottom: 0.2rem;
}

.popupContent.isDesktop .sections:not(:last-of-type) {
  margin-bottom: 0.29rem;
}

.popupContent .sections .title {
  font-size: 0.16rem;
  line-height: 0.16rem;
  font-weight: 600;
  margin-bottom: 0.12rem;
}

.popupContent.isDesktop .sections .title {
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.popupContent .sections .title::before {
  content: '';
  display: inline-block;
  margin: 0 0.04rem 0.04rem 0;
  width: 0.06rem;
  height: 0.06rem;
  border-radius: 50%;
  background: var(--primary);
}

.popupContent .sections .desc {
  font-size: 0.12rem;
  line-height: 0.16rem;
}

.popupContent.isDesktop .sections .desc {
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral900);
  margin-left: 0.08rem;
}

.popupContent .sections .subDesc {
  font-size: 0.12rem;
  line-height: 0.16rem;
  margin-top: 0.12rem;
}

.popupContent.isDesktop .sections .subDesc {
  margin-left: 0.08rem;
}

.shoppingSecurity {
  border-top: 0.08rem solid #F6F6F6;
}

.shoppingSecurity.isDesktop {
  border-top: 0.15rem solid #fff;
  cursor: pointer;
}

.shoppingSecurity.isDesktop .shoppingSecurityTitle {
  font-size: 0.14rem;
  line-height: 0.2rem;
  font-weight: 500;
}