.wrapper {
  --radio-height: 0.32rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-flow-clickable);
  width: auto;
  height: auto;
  background-color: #fff;
  border: 0.01rem solid #dfdfdf;
  display: flex;
  -webkit-transform: translateY(0.12rem) translateX(-50%);
  transform: translateY(0.12rem) translateX(-50%);
  box-shadow: 0 0.06rem 0.1rem 0 rgba(0, 0, 0, 0.1);
}

.wrapper::after {
  content: " ";
  position: absolute;
  top: -0.06rem;
  left: 50%;
  z-index: calc(var(--z-index-flow-clickable) - 8);
  width: 0.12rem;
  height: 0.12rem;
  background-color: #fff;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  border-top: 0.01rem solid #dfdfdf;
  border-left: 0.01rem solid #dfdfdf;
  border-top-left-radius: 0.04rem;
}

.wrapper>div {
  width: 0.29rem;
  height: 0.29rem;
  margin: 0.1rem;
  cursor: pointer;
  border-radius: 50%;
}