.translateCardWrapper {
  margin-top: 0.1rem;
  position: relative;
}

.translateCardWrapper .translateCardContentBox {
  background: var(--neutral50);
  padding: 0.05rem;
  line-height: normal;
}

.translateCardWrapper .translateCardContentBox .translateCardContent {
  display: flex;
  margin-bottom: 0.05rem;
  color: var(--neutral900);
  font-size: 0.12rem;
  font-weight: 400;
  line-height: 0.16rem;
}

.translateCardWrapper .translateCardContentBox .translateCardContent.isDesktop {
  font-size: 0.14rem;
  line-height: 0.18rem;
}

.translateCardContent .translateCardContentLeft {
  flex: 1 1;
}

.translateCardContent .translateCardContentRight {
  flex: 0 0 0.17rem;
}

.translateCardContent .translateCardContentRight .deleteIcon {
  cursor: pointer;
  float: right;
  font-size: 0.1rem;
  color: var(--neutral400);
}

.translateLangBtn {
  position: relative;
  cursor: pointer;
  color: var(--blue3);
  font-size: 0.12rem;
  font-weight: 400;
  line-height: normal;
}

.translateLangBtnDesc::after {
  margin-left: 0.05rem;
  content: '';
  width: 0.11rem;
  height: 0.11rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow_blue.svg') no-repeat;
  background-size: 100%;
  float: right;
  position: absolute;
  top: 0.02rem;
}

.translateLangBtnDesc.isDesktop {
  font-size: 0.14rem;
}

.translateBtnBox {
  height: 0.2rem;
  line-height: 0.16rem;
}

.translating {
  padding: 0.1rem 0.1rem 0 0;
  box-sizing: border-box;
  font-size: 0.12rem;
  font-weight: 400;
  line-height: 0.16rem;
}

.translateBtn {
  cursor: pointer;
  padding: 0 0.1rem 0 0;
  box-sizing: border-box;
  color: var(--blue3);
  font-size: 0.12rem;
  font-weight: 400;
}

.translateBtn.isDesktop::after {
  margin-left: 0.05rem;
  content: '';
  width: 0.11rem;
  height: 0.11rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow_blue.svg') no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0.05rem;
}

.translateBtn.isDesktop,
.translating.isDesktop {
  font-size: 0.14rem;
}