.wrapper {
  width: 100%;
  text-align: center;
}

.wrapper .btn {
  min-width: 1.6rem;
  padding: 0 0.12rem;
  height: 0.42rem;
  line-height: 0.42rem;
  background: #222;
  cursor: pointer;
  margin-top: 0.27rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}