.wrapper {
  display: flex;
}

.wrapper .quantity {
  min-width: 0.22rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.2rem;
  padding: 0 0.08rem;
  font-size: 0.12rem;
  font-weight: 500;
  line-height: 0.2rem;
  border-top: 0.01rem solid var(--neutral300);
  border-bottom: 0.01rem solid var(--neutral300);
}

.wrapper .leftBtn,
.wrapper .rightBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.22rem;
  height: 0.2rem;
  background-color: var(--neutral50);
  font-size: 0.14rem;
  font-weight: 500;
  border: 0.01rem solid var(--neutral300);
}

.wrapper.isDesktop .leftBtn:hover,
.wrapper.isDesktop .rightBtn:hover {
  border-color: var(--neutral900);
}

.wrapper .leftBtn {
  border-top-left-radius: 0.04rem;
  border-bottom-left-radius: 0.04rem;
}

.wrapper .rightBtn {
  border-top-right-radius: 0.04rem;
  border-bottom-right-radius: 0.04rem;
}

.wrapper .leftBtn span,
.wrapper .rightBtn span {
  -webkit-transform: translateY(-0.01rem);
  transform: translateY(-0.01rem);
}