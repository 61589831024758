.list {
  width: calc(100% - 1rem);
  display: flex;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list::-webkit-scrollbar {
  display: none;
}

.item {
  flex-shrink: 0;
  width: 0.8rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.item .tagsImageBox {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  border: 0.04rem solid #fff;
  box-shadow: 0 0 0 0.01rem #ccc;
  margin: 0.01rem;
}

.item .tagsImageBox:hover {
  box-shadow: 0 0 0 0.01rem var(--neutral900);
}

.item .tagsName {
  width: 0.7rem;
  height: 0.32rem;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-transform: capitalize;
  color: var(--neutral900);
  margin-top: 0.06rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sliderBox {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-right: 0;
}

.sliderBox .arrowBox {
  position: absolute;
  top: 0.25rem;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 0.6rem;
  height: auto;
  margin: 0 0.2rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sliderBox .nextBtn,
.sliderBox .prevBtn {
  z-index: var(--z-index-flow-clickable);
  width: 0.2rem;
  height: 0.2rem;
  background: #FFFFFF;
  box-shadow: 0 0 0 0.01rem var(--neutral900);
  color: var(--neutral900);
  border-radius: 50%;
  font-size: 0.12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sliderBox .nextBtn > span {
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
}

.sliderBox .prevBtn > span {
  -webkit-transform: scale(0.67) rotate(180deg);
  transform: scale(0.67) rotate(180deg);
}

.sliderBox .nextBtn.disabled,
.sliderBox .prevBtn.disabled {
  box-shadow: 0 0 0 0.01rem #ccc;
  color: #ccc;
}