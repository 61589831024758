.wrapper {
  background: white;
}

.wrapper .comment .header {
  padding: 0.16rem 0.12rem 0.12rem 0.12rem;
  display: flex;
  justify-content: space-between;
}

.wrapper .comment .header.inDesktop {
  display: block;
  padding-right: 0;
}

.wrapper.inDesktop .comment .header {
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.34rem;
  color: var(--neutral900);
}

.wrapper.inDesktop .comment .header .title {
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.34rem;
  color: var(--neutral900);
  padding: 0;
  display: block;
  padding: 0;
}

.wrapper .comment .header.withoutComment {
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
}

.wrapper .comment .header .title {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: bold;
  line-height: 0.18rem;
}

.wrapper .comment .header .noComment {
  font-size: 0.12rem;
  line-height: 0.16rem;
  font-weight: 500;
}

.wrapper .comment .header {
  position: relative;
}

.wrapper .btn {
  width: 100%;
  height: 0.46rem;
  font-size: 0.12rem;
  color: var(--primary);
  border-top: 0.01rem solid var(--bg-gray2);
}

.wrapper .btn .innerBtn {
  display: flex;
  justify-content: center;
  margin: 0.11rem 0;
}

.wrapper .btn .innerBtn::before {
  content: '';
  background-image: url('./resources/edit.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 0.16rem;
  margin-right: 0.06rem;
}

.wrapper .commentsWrapper {
  margin: 0 0 0.3rem 0;
}

.wrapper .commentsWrapper .content {
  margin: 0;
  padding-top: 0;
}

.wrapper.inDesktop .blank {
  min-height: 2.5rem;
}

.wrapper.inDesktop .commentsWrapper .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emptyCard {
  width: 0.01rem;
  height: 0.01rem;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.paginationItem {
  margin: 0 0.1rem;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.24rem;
  color: #999999;
  width: 0.24rem;
  height: 0.24rem;
  border-radius: 50%;
  text-align: center;
}

.active {
  position: relative;
  color: white;
  width: 0.24rem;
  height: 0.24rem;
  background: var(--neutral900);
  border-radius: 50%;
  text-align: center;
}

.prevItem {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.1rem;
  background: url('https://img.flamingo.shop/p/files/Left_1x.png?v=1599467902') no-repeat center center;
  background-size: 100%;
}

.nextItem {
  width: 0.24rem;
  height: 0.24rem;
  margin-left: 0.1rem;
  background: url('https://img.flamingo.shop/p/files/Right_1x.png?v=1599467902') no-repeat center center;
  background-size: 100%;
}

.header .reviewRating {
  display: flex;
  margin-right: 0.14rem;
}

.noSearchRes {
  background: #fff url('https://img.flamingo.shop/p/show/7be04ce13bfc4e7b84c10a00e8b41816.png') no-repeat center top 0.6rem;
  background-size: 0.6rem;
  padding-top: 1.4rem;
  padding-bottom: 1.63rem;
}

.noSearchRes h1 {
  color: var(--neutral900);
  font-size: 0.16rem;
  font-weight: 500;
}

.productSectionBox.inDesktop {
  margin-top: 0;
}