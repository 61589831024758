.list {
  padding: 0.12rem 0.08rem;
  display: flex;
}

.column {
  width: 50%;
}

.column > * {
  width: 100%;
  display: block;
}

.column:first-child {
  margin-right: 0.08rem;
}