.couponBuoy {
  position: fixed;
  left: 0;
  bottom: 1rem;
  z-index: var(--z-index-aside);
}

.couponBuoy .content {
  position: relative;
  width: 0.62rem;
  height: 0.49rem;
  background: url('https://img.flamingo.shop/p/show/672160ac50b446ad91ecbd7cc9904707.png') no-repeat center center;
  background-size: 100%;
}

.couponBuoy .content .chances {
  display: block;
  position: absolute;
  left: 0.12rem;
  bottom: -0.07rem;
  font-size: 0.12rem;
  font-weight: 600;
  color: white;
  line-height: 0.14rem;
  width: 0.4rem;
  height: 0.14rem;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 0.18rem;
  text-align: center;
}

.couponBuoy .close {
  position: absolute;
  top: -0.11rem;
  right: -0.16rem;
  width: 0.3rem;
  height: 0.3rem;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 0.12rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.couponBuoy .timeStamp > div {
  width: 0.15rem;
  display: inline-block;
}

.couponBuoy .timeStamp > span {
  margin: 0 0.03rem;
}