.wrapper:not(.isDesktop) {
  display: flex;
  position: relative;
}

.wrapper:not(.isDesktop) .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255 ,255, 255, .6);
  z-index: var(--z-index-flow-clickable);
}

.wrapper:not(.isDesktop) .imageContainer {
  flex-basis: 0.76rem;
  flex-shrink: 0;
  height: 0.76rem;
  position: relative;
  margin-right: 0.1rem;
  overflow: hidden;
}

.wrapper:not(.isDesktop) .image {
  width: 0.76rem;
  height: 0.76rem;
}

.wrapper:not(.isDesktop) .discountTag {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255,139,140,0.9);
  width: 0.3rem;
  height: 0.18rem;
  color: white;
  font-size: 0.09rem;
  line-height: 0.18rem;
  text-align: center;
  font-weight: bold;
}

.wrapper:not(.isDesktop) .rightWrapper {
  width: 2.39rem;
}

.wrapper:not(.isDesktop) .rightWrapper > div:not(:last-child) {
  margin-bottom: 0.06rem;
}

.wrapper:not(.isDesktop).disabled .rightWrapper .name {
  color: var(--color-gray);
}

.wrapper:not(.isDesktop) .rightWrapper .name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.12rem;
  font-weight: 400;
  color: var(--neutral900);
}

.wrapper:not(.isDesktop) .rightWrapper .variant {
  position: absolute;
  top: 0.88rem;
  left: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #999999;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper:not(.isDesktop).disabled .rightWrapper .priceRow {
  display: none;
}

.wrapper:not(.isDesktop) .rightWrapper .priceRow {
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: var(--neutral900);
  display: flex;
  align-items: center;
}

.wrapper:not(.isDesktop) .flashSaleTagBox .returnTips {
  margin-top: 0.08rem;
  width: 120%;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: var(--neutral900);
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.wrapper:not(.isDesktop) .flashSaleTagBox .returnTips::before {
  content: '\e66b';
  width: 0.076rem;
  height: 0.076rem;
  font-size: 0.076rem;
  line-height: 0.076rem;
  margin-right: 0.042rem;
}

.wrapper:not(.isDesktop) .flashSaleTagBox.isDesktop {
  position: absolute;
  top: 1.23rem;
  left: 0;
  width: 1.41rem;
}

.wrapper:not(.isDesktop) .price {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: normal;
  color: var(--neutral900);
}

.wrapper:not(.isDesktop) .price.highlighted {
  color: #D20000 !important;
}

.wrapper:not(.isDesktop) .originalPrice {
  font-weight: 500;
  font-size: 0.14rem;
  line-height: normal;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  color: #999999;
  margin-left: 0.06rem;
}

.wrapper:not(.isDesktop) .priceAndQuality {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* pc 的样式 */

.wrapper.isDesktop {
  display: flex;
  position: relative;
}

.wrapper.isDesktop .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255 ,255, 255, .6);
  z-index: var(--z-index-flow-clickable);
}

.wrapper.isDesktop .imageContainer {
  flex-basis: 1.2rem;
  flex-shrink: 0;
  height: 1.2rem;
  position: relative;
  margin-right: 0.1rem;
  overflow: hidden;
}

.wrapper.isDesktop .image {
  width: 1.2rem;
  height: 1.2rem;
}

.wrapper.isDesktop .discountTag {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255,139,140,0.9);
  width: 0.3rem;
  height: 0.18rem;
  color: white;
  font-size: 0.09rem;
  line-height: 0.18rem;
  text-align: center;
  font-weight: bold;
}

.wrapper.isDesktop .rightWrapper {
  position: relative;
  width: 100%;
  max-width: 5.14rem;
}

.wrapper.isDesktop .rightWrapper > div {
  margin-bottom: 0.04rem;
}

.wrapper.isDesktop.disabled .rightWrapper .name {
  color: var(--color-gray);
}

.wrapper.isDesktop .rightWrapper .name {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.14rem;
  font-weight: 400;
  color: var(--neutral900);
}

.wrapper.isDesktop .priceAndQuality {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 0;
}

.wrapper.isDesktop.disabled .rightWrapper .priceRow {
  display: none;
}

.wrapper.isDesktop .rightWrapper .priceRow {
  font-size: 0.14rem;
  font-weight: 600;
  line-height: normal;
  color: var(--neutral900);
  display: flex;
  align-items: center;
}

.wrapper.isDesktop .flashSaleTagBox .returnTips {
  margin-top: 0.08rem;
  width: 120%;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: var(--neutral900);
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.wrapper.isDesktop .flashSaleTagBox .tagBox {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.wrapper.isDesktop .flashSaleTagBox .returnTips::before {
  content: '\e66b';
  width: 0.076rem;
  height: 0.076rem;
  font-size: 0.076rem;
  line-height: 0.076rem;
  margin-right: 0.042rem;
}

.wrapper.isDesktop .flashSaleTagBox.isDesktop {
  position: absolute;
  top: 1.23rem;
  left: 0;
  width: 1.41rem;
}

.wrapper.isDesktop .price {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: normal;
  color: var(--neutral900);
}

.wrapper.isDesktop .price.highlighted {
  color: #D20000 !important;
}

.wrapper.isDesktop .originalPrice {
  font-weight: 500;
  font-size: 0.14rem;
  line-height: normal;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  color: #999999;
  margin-left: 0.06rem;
}