.wrapper {
  background: #ECEEF0;
  margin-bottom: 0.08rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 0.63rem;
}

.text {
  margin-left: 0.15rem;
  display: inline-block;
  font-weight: 600;
  color: var(--neutral900);
  font-size: 0.14rem;
  line-height: 0.18rem;
  white-space: pre-line;
}

.image {
  display: inline-block;
  width: 33%;
}

.imageSmall {
  width: 45%;
}