.priceCard .priceLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0.02rem;
}

.priceCard .priceLine .title {
  max-width: 2.6rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 0.07rem;
}

.priceCard .priceLine:last-child {
  padding: 0;
}

.priceCard .priceLine .subTitle {
  font-size: 0.12rem;
}

.priceCard .priceLine .tag {
  display: inline-block;
  font-size: 0.1rem;
  line-height: 0.14rem;
  padding: 0.03rem 0.04rem;
  background: #FFEDEF;
  vertical-align: 0.05rem;
  border-radius: 0.02rem;
}

.priceCard .priceLine .tag .arrow {
  margin-left: 0.02rem;
  font-size: 0.12rem;
}

.priceCard .priceLabel {
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.priceCard .priceLine.total .priceLabel {
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.14rem;
  color: var(--neutral900);
}

.priceCard .priceValue {
  display: flex;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.priceCard .priceLine.total .priceValue {
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.16rem;
}

.priceCard .priceLine.discount .priceLabel,
.priceCard .priceLine.discount .priceValue {
  color: #d20000;
}

.priceCard .priceLine .priceValue.negative::before {
  content: "- ";
}

.priceLine.orderTotal {
  margin-top: 0.07rem;
  padding-top: 0.09rem !important;
  border-top: 0.01rem solid #F5F5F5;
}

.priceLine.orderTotal .priceLabel,
.priceLine.orderTotal .priceValue {
  font-weight: 700;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: var(--neutral900);
}