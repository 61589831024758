.wrapper {
  width: 5.65rem;
  height: 2.72rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--neutral900);
  position: relative;
}

.wrapper .closeBtnWrapper {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.2rem;
  cursor: pointer;
}

.wrapper .closeBtn {
  width: 0.12rem;
  height: 0.12rem;
  background: url('../../common/Modal/resources/cancel@2x.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .header {
  position: absolute;
  top: 0.21rem;
  left: 0;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  color: #FFFFFF;
  padding: 0 0.32rem;
}

.wrapper .couponTitle {
  position: absolute;
  top: 0.76rem;
  left: 1.65rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 500;
  font-size: 0.13rem;
  line-height: 0.13rem;
  text-align: center;
  color: #FFFFFF;
}

.wrapper .couponName {
  position: absolute;
  top: 1.28rem;
  left: 1.65rem;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  font-style: normal;
  font-weight: 600;
  font-size: 0.36rem;
  line-height: 1;
  text-align: center;
  letter-spacing: -0.04rem;
  color: #FFFFFF;
  max-width: 2.56rem;
}

.wrapper .line {
  position: absolute;
  top: 0.88rem;
  left: 3.17rem;
  width: 0.02rem;
  height: 0.64rem;
  background-color: #fff;
}

.wrapper .freeShipping {
  position: absolute;
  top: 0.728rem;
  left: 4.3rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 600;
  font-size: 0.32rem;
  line-height: 0.34rem;
  text-align: center;
  letter-spacing: -0.02rem;
  color: #FFFFFF;
  text-transform: uppercase;
}

.wrapper .freeShippingRule {
  position: absolute;
  top: 1.46rem;
  left: 4.3rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 500;
  font-size: 0.11rem;
  line-height: 0.18rem;
  text-align: center;
  color: #FFFFFF;
  width: 1.6rem;
}

.wrapper .mainInput {
  position: absolute;
  top: 1.86rem;
  left: 0.69rem;
  width: 2.97rem;
  height: 0.42rem;
  background-color: #fff;
  padding: 0 0.16rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.13rem;
  line-height: 0.18rem;
}

.wrapper .mainInput::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 0.13rem;
  line-height: 0.18rem;
  color: #999999;
}

.wrapper .mainInput:-ms-input-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 0.13rem;
  line-height: 0.18rem;
  color: #999999;
}

.wrapper .mainInput::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 0.13rem;
  line-height: 0.18rem;
  color: #999999;
}

.wrapper .submitBtn {
  position: absolute;
  top: 1.86rem;
  left: 3.66rem;
  width: 1.34rem;
  height: 0.42rem;
  background: #FF8000;
  border-radius: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 0.13rem;
  line-height: 0.18rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.wrapper .privacyWrapper {
  position: absolute;
  top: 2.43rem;
  left: 2.82rem;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  letter-spacing: -0.002rem;
  color: #999999;
  padding: 0 0.32rem;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}