/* 卡片样式 */

.defaultCardItem {
  position: relative;
  width: 0.76rem;
  height: 0.76rem;
  margin-right: 0.1rem;
}

.defaultCardItem::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .6);
}

.defaultCardItem .image {
  width: 0.76rem;
  height: 0.76rem;
}

.defaultCardItem.isDesktop {
  width: 1.2rem;
  height: 1.2rem;
}

.defaultCardItem.isDesktop .image {
  width: 1.2rem;
  height: 1.2rem;
}

/* scroll 的样式 */

.scrollList {
  margin-top: 0.08rem;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  position: relative;
}

.scrollList .defaultCardItem {
  margin-right: 0.06rem;
}

.scrollList::-webkit-scrollbar {
  display: none;
}

/* slider 的样式 */

.sliderBox {
  position: relative;
  margin-top: 0.2rem;
  width: 100%;
}

.sliderBox .prevBtn,
.sliderBox .nextBtn {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  position: absolute;
  top: 0.5rem;
  z-index: var(--z-index-flow-clickable);
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sliderBox .prevBtn {
  left: 0.12rem;
}

.sliderBox .prevBtn::after {
  content: "\e668";
  display: block;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.sliderBox .nextBtn {
  right: 0.12rem;
}

.sliderBox .nextBtn::after {
  content: "\e668";
}