.CollectionTags {
  width: 3.75rem;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0.12rem;
  box-sizing: border-box;
  display: flex;
  border-bottom: 0.08rem solid #f5f5f5;
}

.tagsImageItem {
  --tag-size: 0.65rem;
}

.CollectionTags:not(.isDesktop) {
  background-color: #fff;
}

.CollectionTags.isDesktop {
  width: 100%;
  padding: 0.04rem 0.4rem 0.08rem;
  flex-wrap: wrap;
}

.CollectionTags::-webkit-scrollbar {
  display: none;
}

.CollectionTags .tabItem {
  height: 0.22rem;
  padding: 0 0.1rem;
  text-align: center;
  line-height: 0.22rem;
  background: #f6f6f6;
  margin-right: 0.12rem;
  font-size: 0.12rem;
  font-weight: 400;
  color: #999999;
  box-sizing: border-box;
  white-space: nowrap;
}

.CollectionTags.isDesktop .tabItem {
  height: 0.24rem;
  background-color: #fff;
  line-height: 0.24rem;
  text-transform: capitalize;
  border: 0.005rem solid #999999;
  cursor: pointer;
  margin-bottom: 0.12rem;
  padding: 0 0.12rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  color: #666666;
}

.CollectionTags.isDesktop .tabItem:hover {
  border-color: var(--neutral900);
  color: var(--neutral900);
}

.CollectionImageTags {
  background-color: #fff;
}

.CollectionImageTags .normal {
  display: flex;
  width: 100%;
  height: 1.2rem;
  overflow-x: scroll;
  padding: 0.105rem 0.12rem;
  border-bottom: 0.08rem solid #F5F5F5;
}

.CollectionImageTags .normal::-webkit-scrollbar {
  display: none;
}

.CollectionImageTags .normal .tagsImageItem {
  margin-right: 0.16rem;
}

.normal .tagsImageItem .tagsImageBox {
  width: 0.62rem;
  height: 0.62rem;
  border: 0.01rem solid #CCCCCC;
  border-radius: 50%;
  padding: 0.06rem;
  margin-bottom: 0.04rem;
}

.normal .tagsImageItem .tagsImageBox .image img {
  width: 0.48rem;
  height: 0.48rem;
}

.normal .tagsImageItem .tagsName {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  text-transform: capitalize;
  color: var(--neutral900);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.CollectionImageTags .tiny {
  width: 100%;
  height: 1.2rem;
  background-color: #fff;
  z-index: var(--z-index-flow-clickable);
  display: flex;
  align-items: flex-end;
  width: 100%;
  overflow-x: scroll;
  padding: 0.105rem 0.12rem;
  border-top: 0.01rem solid rgba(209, 209, 209, 1);
  border-bottom: 0.08rem solid #F5F5F5;
}

.CollectionImageTags .tiny::-webkit-scrollbar {
  display: none;
}

.CollectionImageTags .tiny .tagsImageItem {
  height: 0.4rem;
  flex-shrink: 0;
  margin-right: 0.1rem;
  display: flex;
  align-items: center;
  background: #F5F5F5;
  padding: 0 0.1rem;
}

.tiny .tagsImageItem .tagsImageBox {
  width: 0.3rem;
  height: 0.3rem;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 0.1rem;
}

.tiny .tagsImageItem .tagsName {
  max-width: 0.8rem;
  width: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-transform: capitalize;
  color: var(--neutral900);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* word-break: break-all; */
}

.imageTagsSlider .tagsName {
  width: var(--tag-size);
  font-style: normal;
  font-weight: 400;
  font-size: 0.16rem;
  line-height: 0.21rem;
  text-transform: capitalize;
  color: var(--neutral900);
  text-align: center;
}

.tagsImageItem.isDesktop .tagsImageBox {
  cursor: pointer;
  width: var(--tag-size);
  height: var(--tag-size);
  border: 0.015rem solid #CCCCCC;
  border-radius: 50%;
  padding: 0.04rem;
  margin-bottom: 0.12rem;
}

.tagsImageItem.isDesktop .tagsImageBox:hover {
  border-color: rgba(26, 26, 26, 1);
}

.tagsImageItem.isDesktop .tagsName {
  font-size: 0.12rem;
}

.inView {
  position: absolute;
  left: 0;
  top: 0;
}