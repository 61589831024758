.wrapper {
  position: fixed;
  bottom: var(--fixed-footer-bottom);
  z-index: var(--z-index-flow-clickable);
  width: 3.75rem;
}

.wrapper.inDesktop {
  width: 100%;
}

.wrapper >*:not(:first-of-type) {
  display: none;
}