.wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0.12rem;
}

.wrapper .titleWrapper {
  display: flex;
  overflow: hidden;
}

.wrapper .titleWrapper .rightWrapper {
  flex: 1 1;
}

.wrapper .avatar {
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  margin-right: 0.12rem;
}

.wrapper .header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.03rem 0;
  font-size: 0.12rem;
  line-height: 0.14rem;
  height: 0.36rem;
  font-weight: normal;
}

.wrapper .header .desc {
  font-size: 0.12rem;
  color: var(--neutral500);
  font-weight: normal;
  margin-top: 0.03rem;
}

.wrapper .contentWrapper .content {
  margin-top: 0.1rem;
  font-size: 0.14rem;
  line-height: 0.18rem;
  color: var(--neutral900);
  font-weight: normal;
}