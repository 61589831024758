.wrapper {
  background-color: #fff;
}

.wrapper.hasMargin {
  margin-top: 0.08rem;
}

.wrapper.inDesktop {
  padding-bottom: 0.7rem;
  background: white;
}

.wrapper.inDesktop .header {
  display: block;
  font-size: 0.24rem;
  line-height: 0.48rem;
  font-weight: bold;
  text-align: center;
  background: white;
  height: auto;
  margin-bottom: 0.1rem;
}

.header {
  width: 100%;
  height: 0.48rem;
  display: flex;
  justify-content: center;
  background: var(--bg-common);
  font-size: 0.16rem;
  font-weight: 500;
  line-height: 0.48rem;
}

.header .title {
  text-align: left;
  margin: 0 0.06rem;
}

.header .title.isDesktop {
  margin: 0;
  display: block;
  text-align: left;
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.34rem;
  color: var(--neutral900);
}

.header .separator {
  width: 0.24rem;
  height: 0.01rem;
  background: var(--color-gray3);
  margin-top: 0.24rem;
}

.heading {
  display: inline;
}