.imageBox {
  --picker-size-mobile: 0.22rem;
  --picker-size-pc: 0.22rem;
  --white-circle-weight-mobile: 0.01rem;
  --white-circle-weight-pc: 0.02rem;
}

.imageBox:not(.isDesktop) {
  width: var(--picker-size-mobile);
  height: var(--picker-size-mobile);
  position: relative;
}

.imageBox:not(.isDesktop) .image,
.imageBox:not(.isDesktop) .bg {
  position: relative;
  display: flex;
  border-radius: 50%;
  box-sizing: border-box;
  width: inherit;
  height: inherit;
  border: 0.01rem solid #DBDBDB;
}

.imageBox:not(.isDesktop).active .image,
.imageBox:not(.isDesktop).active .bg {
  border-color: var(--neutral900);
}

.imageBox:not(.isDesktop).active .image::after,
.imageBox:not(.isDesktop).active .bg::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: var(--white-circle-weight-mobile) solid #fff;
  border-radius: 50%;
}

/* pc的设计 */

.imageBox.isDesktop {
  position: relative;
  border-radius: 50%;
}

.imageBox.isDesktop .image,
.imageBox.isDesktop .bg {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  border-radius: 50%;
  box-shadow: 0 0 0.01rem 0.01rem #DBDBDB;
  margin: 0.02rem;
}

.imageBox.isDesktop.active .image {
  box-shadow: 0 0 0.01rem 0.01rem var(--neutral900);
  border: var(--white-circle-weight-pc) solid #fff;
}

.imageBox.isDesktop.active .bg {
  box-shadow: 0 0 0.01rem 0.01rem var(--neutral900), 0 0 0 var(--white-circle-weight-pc) #fff inset;
}