:root {
  --skeleton-bg-color: #f7f7f7;
  --skeleton-bg-color1: rgba(66, 65, 65, .2);
}

.mobile .longItem {
  width: 1.2rem;
  height: 0.12rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.mobile .shortItem {
  width: 0.6rem;
  height: 0.12rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.mobile .header {
  width: 100vw;
  height: 0.48rem;
  line-height: 0.48rem;
  font-size: 0.15rem;
  border-bottom: 0.01rem solid var(--gray);
  background-color: #fff;
}

.mobile .header .title {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 100vw;
}

.mobile .imageGroup {
  padding: 0.13rem 0.12rem;
  background-color: #fff;
  height: 3.96rem;
  margin-bottom: 0.08rem;
  position: relative;
}

.mobile .imageGroup .items {
  width: 0.54rem;
  height: 0.12rem;
  margin-bottom: 0.11rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.mobile .imageGroup .cartItem {
  margin-bottom: 0.08rem;
  width: 3.75rem;
  height: 1.7rem;
  position: relative;
}

.mobile .imageGroup .cartItem .itemCheck {
  position: absolute;
  left: 0;
  top: 0.35rem;
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  background: #f7f7f7;
  background: var(--skeleton-bg-color);
}

.mobile .imageGroup .cartItem .imageBox {
  position: absolute;
  top: 0;
  left: 0.3rem;
  width: 0.76rem;
  height: 0.76rem;
}

.mobile .imageGroup .cartItem .productTitle {
  width: 1.96rem;
  height: 0.16rem;
  background: #f7f7f7;
  background: var(--skeleton-bg-color);
  position: absolute;
  top: 0.1rem;
  left: 1.2rem;
}

.mobile .imageGroup .cartItem .productTitle2 {
  width: 1.35rem;
  height: 0.16rem;
  background: #f7f7f7;
  background: var(--skeleton-bg-color);
  position: absolute;
  top: 0.34rem;
  left: 1.2rem;
}

.mobile .imageGroup .cartItem .productOption {
  width: 1.05rem;
  height: 0.22rem;
  background: #f7f7f7;
  background: var(--skeleton-bg-color);
  position: absolute;
  top: 0.96rem;
  left: 1.2rem;
}

.mobile .adds {
  position: absolute;
  left: 1.2rem;
  bottom: 0.12rem;
  width: 0.76rem;
  height: 0.2rem;
  border: 0.02rem solid #f7f7f7;
  border: 0.02rem solid var(--skeleton-bg-color);
}

.mobile .priceCounts {
  width: 3.75rem;
  background-color: #fff;
  margin-bottom: 0.08rem;
  padding: 0.09rem 0.12rem;
}

.mobile .priceCounts>div {
  margin-bottom: 0.1rem;
  display: flex;
  justify-content: space-between;
}

.mobile .others {
  width: 3.75rem;
  height: 5rem;
  background-color: #fff;
  margin-bottom: 0.08rem;
}

.mobile .btnFix {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 3.75rem;
  height: 1.2rem;
  background-color: #fff;
  box-shadow: 0 0.89rem 0.65rem 0.45rem gray;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  padding: 0.09rem 0.12rem;
}

.mobile .btnFix .all {
  flex: 1 1;
}

.mobile .btnFix .total {
  width: 0.94rem;
  height: 0.22rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.mobile .btnFix .btn {
  flex-basis: 100%;
  width: 3.51rem;
  height: 0.4rem;
  background: var(--neutral900);
}

/* pc的样式 */

.pc .header {
  width: 19.2rem;
  height: 0.6rem;
  background-color: #fff;
}

.pc .steps {
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pc .steps .circle {
  width: 0.28rem;
  height: 0.28rem;
  border-radius: 50%;
  background-color: rgba(66, 65, 65, .2);
  background-color: var(--skeleton-bg-color1);
  margin-right: 0.1rem;
}

.pc .steps .text {
  width: 1.18rem;
  height: 0.12rem;
  background-color: rgba(66, 65, 65, .2);
  background-color: var(--skeleton-bg-color1);
  margin-right: 0.68rem;
}

.pc .content {
  position: relative;
  margin: 0 auto;
  width: 12.54rem;
}

.pc .content .leftContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 7.44rem;
  height: 6.63rem;
  background-color: #fff;
  padding: 0.26rem 0.3rem;
}

.pc .content .leftContent .title {
  width: 1.6rem;
  height: 0.28rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
  margin-bottom: 0.19rem;
}

.pc .leftContent .header {
  width: 100%;
  height: 0.3rem;
  box-sizing: content-box;
  padding-bottom: 0.17rem;
  border-bottom: 0.01rem solid #f7f7f7;
  border-bottom: 0.01rem solid var(--skeleton-bg-color);
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}

.pc .leftContent .header>div:nth-child(2) {
  flex-basis: 4.73rem;
}

.pc .leftContent .header>div:nth-child(3) {
  flex-basis: 1.62rem;
}

.pc .leftContent .header>div:nth-child(4) {
  flex-basis: 1.62rem;
}

.pc .leftContent .header>div:nth-child(5) {
  flex-basis: 1.62rem;
}

.pc .checkIcon {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.pc .header .checkIcon {
  margin-right: 0.08rem;
}

.pc .shortItem {
  width: 0.39rem;
  height: 0.22rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.pc .longItem {
  width: 1.2rem;
  height: 0.22rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.pc .leftContent .cartItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}

.pc .cartItem .checkIcon {
  margin-right: 0.2rem;
}

.pc .leftContent .cartItem>div:nth-child(2) {
  flex-basis: 4.73rem;
}

.pc .leftContent .cartItem>div:nth-child(3) {
  flex-basis: 1.62rem;
}

.pc .leftContent .cartItem>div:nth-child(4) {
  flex-basis: 1.62rem;
}

.pc .leftContent .cartItem>div:nth-child(5) {
  flex-basis: 1.62rem;
}

.pc .leftContent .cartItem .imageBox {
  width: 1.2rem;
  height: 1.2rem;
}

.pc .cartItem .productCard {
  position: relative;
}

.pc .cartItem .productCard>div:nth-child(2) {
  position: absolute;
  top: 0;
  left: 1.3rem;
  width: 2.5rem;
}

.pc .cartItem .productCard>div:nth-child(3) {
  position: absolute;
  top: 0.3rem;
  left: 1.3rem;
  width: 0.9rem;
}

.pc .cartItem .productCard>div:nth-child(4) {
  position: absolute;
  top: 0.3rem;
  left: 2.8rem;
  width: 0.9rem;
}

.pc .cartItem .productCard>div:nth-child(5) {
  position: absolute;
  top: 0.3rem;
  left: 3.9rem;
  width: 0.9rem;
}

.pc .cartItem .productCard>div:nth-child(6) {
  position: absolute;
  top: 0.3rem;
  left: 5rem;
  width: 0.9rem;
}

.pc .content .rightContent .top {
  position: absolute;
  top: 0;
  right: 0;
  width: 4.8rem;
  height: 4.58rem;
  background-color: #fff;
}

.pc .content .rightContent .top .btn {
  width: 4.2rem;
  height: 0.45rem;
  background-color: var(--neutral900);
  position: absolute;
  left: 0.3rem;
  bottom: 0.3rem;
}

.pc .content .rightContent .top .priceCounts {
  position: absolute;
  top: 0.81rem;
  left: 0.3rem;
  width: 4.2rem;
}

.pc .content .rightContent .top .priceCounts>div {
  display: flex;
  justify-content: space-between;
  margin: 0.12rem 0;
}

.pc .content .rightContent .top .title {
  width: 1.5rem;
  height: 0.28rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
  position: absolute;
  top: 0.26rem;
  left: 0.3rem;
}

.pc .content .rightContent .bottom {
  position: absolute;
  top: 4.9rem;
  right: 0;
  width: 4.8rem;
  height: 1.75rem;
  background-color: #fff;
  padding: 0.26rem 0.3rem;
}

.pc .content .rightContent .bottom .title {
  width: 1.04rem;
  height: 0.28rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
}

.pc .content .rightContent .bottom .desc {
  width: 4.2rem;
  height: 0.14rem;
  background-color: #f7f7f7;
  background-color: var(--skeleton-bg-color);
  margin: 0.12rem 0;
}