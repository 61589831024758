.couponImage {
  width: calc(var(--card-width) - 0.12rem);
  flex-shrink: 0;
}

.list {
  display: flex;
  width: 3.75rem;
  padding-left: 0.12rem;
  padding-bottom: 0.12rem;
  overflow-x: scroll;
}

.list::-webkit-scrollbar {
  display: none;
}

.list .couponBox {
  margin-right: 0.08rem;
}