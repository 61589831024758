.listComponent {
  background: linear-gradient(180deg, #FFF 0%, #F5F5F5 2.87%);
  padding: 0 0.06rem;
}

.listComponent.pcListComponent {
  background: #FFF;
  padding: 0;
}

.listComponent .listColumn {
  overflow: hidden;
}

.listComponent .listColumn:not(:last-child) {
  margin-right: 0.07rem;
}

.listComponent.pcListComponent .listColumn:not(:last-child) {
  margin-right: 0;
}