.page {
  position: relative;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.navigation {
  width: 2.7rem;
  height: 100vh;
  display: none;
  transition: left 1s ease;
  position: fixed;
  top: 0;
  left: -2.7rem;
  z-index: var( --z-index-modal);
}

.navigateCloseIcon {
  position: fixed;
  top: 0;
  left: 2.7rem;
  width: 0.46rem;
  height: 0.46rem;
  background-color: var(--neutral900);
  z-index: var( --z-index-modal);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.01rem solid #fff;
  border-left-width: 0;
  -webkit-animation-name: IconfadeIn;
  animation-name: IconfadeIn;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes IconfadeIn {
  from {
    left: 0;
  }

  to {
    left: 2.7rem;
  }
}

@keyframes IconfadeIn {
  from {
    left: 0;
  }

  to {
    left: 2.7rem;
  }
}

.navigationCover {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  z-index: calc(var( --z-index-modal) - 1);
  -webkit-animation-name: light;
  animation-name: light;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes light {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes light {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.naviOpen .navigation {
  display: block;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
  from {
    left: -2.7rem;
  }

  to {
    left: 0;
  }
}

@keyframes fadeIn {
  from {
    left: -2.7rem;
  }

  to {
    left: 0;
  }
}

.page .coverCart {
  width: 3rem;
  /* height: 100vh; */
  bottom: 0;
  height: auto;
  position: fixed;
  top: 0;
  right: -3rem;
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  opacity: 0;
  z-index: var( --z-index-modal);
  border-left: 0.01rem solid var(--gray);
  overflow: auto;
}