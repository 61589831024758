.wrapper {
  background-color: #fff;
}

.wrapper.isDesktop {
  padding-bottom: 0.7rem;
}

.selectableTabs {
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.46rem;
  padding: 0 0.12rem;
}

.selectableTabs:not(.isDesktop) {
  padding: 0.12rem 0.12rem 0;
  height: auto;
}

.selectableTabs.isDesktop {
  justify-content: flex-start;
  height: 0.34rem;
  margin-bottom: 0.24rem;
  padding: 0;
}

.selectableTabs > div {
  color: #999999;
  font-style: normal;
  font-weight: normal;
  font-size: 0.14rem;
  line-height: 0.14rem;
  position: relative;
  color: var(--neutral900);
  font-weight: 600;
}

.selectableTabs:not(.isDesktop) > div {
  color: var(--neutral900);
  font-weight: 700;
}

.selectableTabs.isDesktop > div {
  margin-right: 0.64rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.34rem;
  color: var(--neutral900);
}

.quickAddCard {
  position: relative;
}

.addToCartBtn {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  z-index: var(--z-index-layer-1);
}

.pcAddIcon {
  font-size: 0.24rem;
}

.wrapper .list {
  padding: 0.12rem;
}

.list.empty {
  min-height: 1.2rem;
}

.list.isDesktop {
  padding: 0;
}

.viewMore {
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.12rem;
  text-align: center;
  text-transform: capitalize;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.2rem;
}

.viewMore > span {
  margin-left: 0.05rem;
  font-size: 0.14rem;
}