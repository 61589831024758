.wrapper {
  width: 2.95rem;
  padding: 0.3rem 0.12rem 0.23rem 0.12rem;
  background: #E7584A;
  background: url('./resources/wheel-winback.webp') no-repeat center center;
  background-size: contain;
}

.wrapper.us {
  background: url('./resources/wheel-winback-us.webp') no-repeat center center;
  border-radius: 0.12rem;
  background-size: contain;
}

.wrapper .header {
  padding: 0 0.19rem;
  color: #FEE3BD;
  font-size: 0.22rem;
  font-weight: 800;
  line-height: 0.26rem;
  margin-bottom: 0.1rem;
  text-align: center;
}

.wrapper .desc {
  padding: 0 0.19rem;
  color: #FEE3BD;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 0.2rem;
  margin-bottom: 0.25rem;
  text-align: center;
}

.wrapper .buttonGroup {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 0.4rem;
  font-weight: 800;
}

.wrapper .buttonGroup .okBtn {
  font-size: 0.14rem;
  border-radius: 0.2rem;
  color: #FEE3BD;
  margin-bottom: 0.1rem;
  background: black;
}

.wrapper .buttonGroup .cancelBtn {
  font-size: 0.14rem;
  background: rgba(255, 255, 255, 0.4);
  color: var(--neutral900);
  border-radius: 0.2rem;
}