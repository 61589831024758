.header {
  position: relative;
  width: 3.75rem;
  height: 2.81rem;
  background: white url('https://img.flamingo.shop/p/show/1bae9407a2b04b6b85c0a94c8555f23d.png') no-repeat center top;
  background-size: 100% 100%;
}

.wrapper.inDesktop .header {
  margin: 0 1rem;
  width: calc(100% - 2rem);
  height: 4rem;
  background: white url('https://img.flamingo.shop/p/show/55b2521f5bf94c1f82732fc582052d17.png') no-repeat center top;
  background-size: 100% 100%;
}

.postList {
  padding: 0.12rem 0.06rem;
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 2.87%);
}

.contentWrapper .listColumn:not(:last-child) {
  margin-right: 0.07rem;
}

.wrapper.inDesktop .contentWrapper {
  padding: 0 1rem;
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 2.87%);
  min-height: 6rem;
}

.wrapper.inDesktop .contentWrapper .title {
  font-size: 0.24rem;
  line-height: 0.48rem;
}

.wrapper.inDesktop .postList {
  padding: 0.12rem;
}

.wrapper.inDesktop .contentWrapper .listColumn:not(:last-child) {
  margin-right: 0.12rem;
}

.personalCard {
  position: absolute;
  bottom: 0.25rem;
  left: 0.12rem;
  display: flex;
}

.wrapper.inDesktop .personalCard {
  left: 0.5rem;
}

.wrapper.inDesktop .personalCard .image {
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 0.2rem;
}

.wrapper.inDesktop .personalCard .name {
  font-size: 0.24rem;
  line-height: 0.6rem;
  max-width: 5rem;
}

.wrapper.inDesktop .personalCard .name::after {
  width: 0.28rem;
  height: 0.28rem;
  vertical-align: -0.06rem;
}

.personalCard .image {
  width: 0.42rem;
  height: 0.42rem;
  border-radius: 50%;
  margin-right: 0.1rem;
  border: 0.02rem solid white;
  overflow: hidden;
}

.personalCard .name {
  color: white;
  font-size: 0.2rem;
  line-height: 0.46rem;
  font-weight: 600;
  max-width: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.personalCard .name::after {
  content: '';
  display: inline-block;
  width: 0.2rem;
  height: 0.2rem;
  background: url('https://img.flamingo.shop/p/show/dd61e0954436475697cc89b65017214f.png') no-repeat center center;
  background-size: 100% 100%;
  vertical-align: -0.04rem;
}

.contentWrapper {
  position: relative;
}

.contentWrapper::before {
  content: '';
  position: absolute;
  left: 0;
  top: -0.15rem;
  width: 100%;
  height: 0.15rem;
  border-radius: 0.06rem 0.06rem 0 0;
  background: white;
}

.contentWrapper .title {
  background: white;
  font-size: 0.2rem;
  line-height: 0.28rem;
  color: var(--neutral900);
  padding: 0.05rem 0.12rem 0.03rem 0.12rem;
  font-weight: 600;
}