.shippingTable:not(.isDesktop) {
  position: relative;
}

.shippingTable.isDesktop {
  position: relative;
  max-width: 100vw;
}

.shippingTable .closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.12rem;
  font-size: 0.14rem;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shippingTable:not(.isDesktop) .title {
  padding: 0.28rem 0.12rem 0.16rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.17rem;
  letter-spacing: -0.004rem;
  color: var(--neutral900);
}

.shippingTable.isDesktop .title {
  padding: 0.6rem 0.4rem 0.22rem;
  font-weight: 800;
  font-size: 0.22rem;
  line-height: 0.22rem;
  border-bottom: 0.01rem solid #E6E6E6;
}

.shippingTable.isDesktop .contents {
  padding: 0.27rem 0.3rem;
}

.shippingTable:not(.isDesktop) .contents .shippingTab {
  display: flex;
  align-items: center;
  padding: 0 0.12rem;
  overflow-x: scroll;
  margin-top: 0.16rem;
}

.shippingTable:not(.isDesktop) .contents .shippingTab::-webkit-scrollbar {
  display: none;
}

.shippingTable.isDesktop .contents .shippingTab {
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  max-width: 4.2rem;
  overflow-x: scroll;
}

.shippingTable.isDesktop .contents .shippingTab::-webkit-scrollbar {
  display: none;
}

.shippingTable:not(.isDesktop) .contents .shippingTab .shippingTabItem {
  font-style: italic;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.14rem;
  color: #999999;
  margin-right: 0.24rem;
  padding-bottom: 0.06rem;
  white-space: nowrap;
  position: relative;
}

.shippingTable.isDesktop .contents .shippingTab .shippingTabItem {
  margin-right: 0.3rem;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  color: #999999;
  white-space: nowrap;
}

.shippingTable:not(.isDesktop) .shippingTab .shippingTabItem.active {
  color: var(--neutral900) !important;
  font-weight: 600;
}

.shippingTable.isDesktop .shippingTab .shippingTabItem.active {
  color: var(--neutral900) !important;
}

.shippingTable:not(.isDesktop) .shippingTab .shippingTabItem.active::after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.02rem;
  background-color: var(--neutral900);
}

.shippingTable:not(.isDesktop) .contents .shippingStandards {
  margin: 0.2rem 0.12rem 0;
}

.shippingTable.isDesktop .contents .shippingStandards {
  max-width: 4.2rem;
  background: #F7F7F7;
  padding: 0.1rem 0.2rem 0.02rem;
  margin: 0;
}

.shippingTable:not(.isDesktop) .shippingStandardsTitle {
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.16rem;
  color: var(--neutral900);
  margin-bottom: 0.04rem;
}

.shippingTable.isDesktop .shippingStandardsTitle {
  font-weight: bold;
  font-size: 0.2rem;
  line-height: 0.2rem;
  padding-bottom: 0.1rem;
  color: var(--neutral900);
}

.shippingTable.isDesktop .shippingTables {
  width: 100%;
  color: var(--neutral900);
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: center;
  color: var(--neutral900);
}

.shippingTable:not(.isDesktop) .shippingTables {
  width: 100%;
  font-weight: normal;
  font-size: 0.14rem;
  line-height: 0.21rem;
  color: #333333;
}

.shippingTable:not(.isDesktop) .contents .hint {
  font-style: normal;
  font-weight: 300;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #999999;
  margin: 0.94rem 0.12rem 0.24rem;
}

.shippingTable.isDesktop .contents .hint {
  width: 4.2rem;
  margin-top: 0.28rem;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #999999;
}