.root {
  position: relative;
  height: 0.36rem;
  border: 0.01rem solid var(--gray6);
  width: 3.59rem;
  margin-bottom: 0.08rem;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 2.5rem;
  height: 0.38rem;
}

.searchInput input {
  box-sizing: border-box;
  border: 0.01rem solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  padding-left: 0.12rem;
  padding-right: 0.26rem;
  font-size: 0.12rem;
  outline: 0;
}

.wrapper {
  touch-action: none;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: white;
  top: 0;
  left: 0;
  z-index: 200;
}

.search {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 0.44rem;
  background: var(--primary);
  color: white;
  padding: 0.1rem 0.12rem;
}

.search .back {
  width: 0.1rem;
  height: 100%;
  background: url("https://img.flamingo.shop/p/files/navBack-1.png?v=1585652075") no-repeat center
    center;
  background-size: contain;
  margin-right: 0.1rem;
}

.search .searchBar {
  flex: 1 1;
}

.search .cancel {
  font-size: 0.13rem;
  line-height: 0.24rem;
  margin-left: 0.1rem;
}

.SearchPanel {
  position: absolute;
  top: 0.35rem;
  left: 0;
  z-index: calc(var(--z-index-modal) + 1);
  -webkit-animation: fadeIn 0.3s forwards;
  animation: fadeIn 0.3s forwards;
  border: 0.01rem solid rgba(0, 0, 0, 0.1);
  border-top-width: 0;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    margin-top: -0.2rem;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    margin-top: -0.2rem;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}