.inventoryTag {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.22rem;
  background: rgba(0,0,0,.3);
  line-height: 0.22rem;
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 0.1rem;
  text-align: center;
  color: #FFFFFF;
}

.inventoryTag > span {
  display: block;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
}

.inventoryTag.isDesktop {
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #FFFFFF;
}