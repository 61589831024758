.colorSlider {
  --color-swatch-size-mobile: 0.22rem;
  --color-swatch-size-pc: 0.22rem;
}

.colorSlider:not(.isDesktop) {
  position: relative;
}

.colorSlider:not(.isDesktop) .sliderComponent {
  width: 100%;
  padding-right: var(--color-swatch-size-mobile);
}

.colorSlider:not(.isDesktop) .next {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.2rem;
  height: 0.2rem;
  line-height: 0.2rem;
  text-align: center;
  font-style: normal;
  font-size: 0.12rem;
  color: #666;
}

/* pc */

.colorSlider.isDesktop {
  position: relative;
  margin: 0 0.01rem;
}

.colorSlider.isDesktop .sliderComponent {
  width: 100%;
  padding-right: var(--color-swatch-size-pc);
  height: var(--color-swatch-size-mobile);
}

.colorSlider.isDesktop .next {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--color-swatch-size-pc);
  height: var(--color-swatch-size-pc);
  line-height: var(--color-swatch-size-pc);
  text-align: center;
  font-style: normal;
  font-size: 0.14rem;
  color: #666;
  cursor: pointer;
}

.colorPickerBox {
  outline: none;
}

.colors {
  width: 80%;
  display: flex;
}

.colors .colorPickerBox {
  position: relative;
  z-index: calc(var(--z-index-flow-clickable) + 2);
  margin-right: 0.12rem;
  margin-bottom: 0.02rem;
}

.colorPickerBox.smallPicker .colorPicker {
  width: 0.2rem;
  height: 0.2rem;
}

.colorPicker:not(.isDesktop) {
  width: 0.2rem;
  height: 0.2rem;
}

.colorPicker.isDesktop {
  width: 0.22rem;
  height: 0.22rem;
}

.more {
  font-size: 0.13rem;
  display: inline-block;
  height: 0.24rem;
  line-height: 0.24rem;
  cursor: pointer;
  position: relative;
}

.more .moreCount {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.more .moreCount:hover::after {
  content: ' ';
  position: absolute;
  top: -0.08rem;
  right: -0.08rem;
  bottom: -0.08rem;
  left: -0.08rem;
  background-color: rgba(128,128,128,0.1);
  border-radius: 50%;
}

.colorPopover {
  --radio-height: 0.32rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-flow-clickable);
  width: 0;
  height: 0;
  -webkit-animation-name: showColorSwatch;
  animation-name: showColorSwatch;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

@-webkit-keyframes showColorSwatch {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes showColorSwatch {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.colorPopover::after {
  content: " ";
  position: absolute;
  top: -0.11rem;
  left: 0;
  z-index: calc(var(--z-index-flow-clickable) - 8);
  width: 0.16rem;
  height: 0.16rem;
  background-color: #fff;
  -webkit-transform: translateY(0.36rem);
  transform: translateY(0.36rem);
  -webkit-clip-path: polygon(50% 0, 100% 50%, 50%50%, 0 50%);
  clip-path: polygon(50% 0, 100% 50%, 50%50%, 0 50%);
  border-radius: 0.03rem;
}

.colorPopover .content {
  -webkit-transform: translateX(-50%) translateY(var(--radio-height));
  transform: translateX(-50%) translateY(var(--radio-height));
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: content-box;
  background-color: #fff;
  box-shadow: 0 0 0.16rem rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 0.06rem 0 0.06rem 0.08rem;
}

.colorPopover.wrap .content {
  width: 2rem;
  flex-wrap: wrap;
}

.colorPopover .content .colorPickerBox {
  margin-right: 0.12rem;
  margin-bottom: 0.04rem;
}

.colorPopover.toLeft .content {
  -webkit-transform: translateX(calc(-100% + var(--radio-height))) translateY(var(--radio-height));
  transform: translateX(calc(-100% + var(--radio-height))) translateY(var(--radio-height));
}

.colorPopover.toRight .content {
  -webkit-transform: translateX(-0.32rem) translateY(var(--radio-height));
  transform: translateX(-0.32rem) translateY(var(--radio-height));
}