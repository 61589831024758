.wrapper {
  width: 3.75rem;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 0.06rem 0;
  font-size: 0.13rem;
  line-height: 0.28rem;
  border-bottom: 0.01rem solid 0.01rem solid rgb(209 209 209 / 50%);
}

.wrapper.isApp {
  top: 0;
}

.wrapper .sortBy {
  display: flex;
}

.wrapper .sortBy::after {
  content: '';
  margin-top: 0.09rem;
  margin-left: 0.08rem;
  width: 0.1rem;
  height: 0.1rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow.svg');
  background-size: 100%;
}

.wrapper .sortBy.withSortListOpen::after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.filter {
  flex-basis: 50%;
  font-weight: bold;
  text-align: center;
}

.filter.highlighted {
  font-weight: bold;
}

.wrapper .sortCategory.highlighted {
  font-weight: bold;
}

.wrapper .sortList {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0.36rem;
  z-index: var(--z-index-flow-clickable);
  background: white;
  padding: 0 0.08rem;
  font-size: 0.12rem;
  line-height: 0.3rem;
}

.sortItem {
  display: flex;
  margin-right: 0.16rem;
  margin-left: 0.1rem;
  height: 0.3rem;
  font-size: 0.13rem;
}

.sortItem:last-child {
  margin-bottom: 0.1rem;
}

.sortItem::before {
  content: '';
  width: 0.12rem;
  height: 0.12rem;
  margin-top: 0.1rem;
  margin-right: 0.1rem;
}

.wrapper .sortList .sortItem.selected {
  font-weight: bold;
  color: var(--neutral900);
  padding-left: -0.15rem;
}

.wrapper .sortList .sortItem.selected::before {
  background: url('./resources/duigou.svg') no-repeat left center;
  background-size: 0.13rem 0.13rem;
}

.mobileWrapper {
  width: 100%;
}

div.sortdown {
  flex-basis: 50%;
  justify-content: center;
}

.lineBar {
  width: 0.01rem;
  height: 0.15rem;
  background-color: rgba(209 209 209, .5);
  margin-top: 0.06rem;
}