.sortWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0 0.02rem;
  border-bottom: 0.01rem solid #e6e6e6;
  box-sizing: border-box;
}

.sortWrapper .tabSort {
  cursor: pointer;
  display: inline-block;
  margin: 0.05rem 0.3rem 0 0;
  padding-bottom: 0.19rem;
  box-sizing: border-box;
  color: var(--neutral500);
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 0.34rem;
}

.sortWrapper .tabSort.lastTabSort {
  margin-right: 0;
}

.sortWrapper .tabSort.selected {
  color: var(--neutral900);
  border-bottom: 0.02rem solid var(--neutral900);
}

.sortRight {
  display: flex;
}

.sortRight .starSelectLabelClass {
  margin-left: 0;
}

.starSelectPannelClass {
  width: 1.97rem;
  left: 0;
}

.starSelectItemClass {
  width: 0.9rem;
}

.starOptionName {
  display: inline-flex;
  align-items: center;
  font-size: 0.12rem;
}

.starOptionName.inDesktop {
  font-size: 0.14rem;
}

.starIcon {
  margin-left: 0.01rem;
  font-size: 0.12rem;
  color: #ffaa00;
}

.sortBySelectItemClass {
  width: 1.5rem;
}

.sortByPannelClass {
  width: 2.75rem;
  right: 0;
}

.colorOrSizeSelectItemClass {
  width: 1.5rem;
}

.colorOrSizeByPannelClass {
  width: 3.75rem;
  left: 0;
}

/* mobile */

.moblieFilterWrapper .colorOrSizeTab {
  height: 0.46rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.12rem;
  border-bottom: 0.01rem solid var(--neutral100);
  box-sizing: border-box;
}

.moblieFilterWrapper .colorOrSizeTab .colorOrSizeDesc {
  color: var(--neutral900);
  font-size: 0.14rem;
  font-weight: 500;
  line-height: normal;
}

.moblieFilterWrapper .colorOrSizeTab .colorOrSizeDesc .colorOrSizeDescVal {
  display: inline-block;
  width: 3.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.moblieFilterWrapper .colorOrSizeTab::after {
  margin-left: 0.1rem;
  content: '';
  width: 0.11rem;
  height: 0.11rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow.svg') no-repeat;
  background-size: 100%;
  float: right;
}

.moblieFilterWrapper .colorOrSizeTab.active::after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.moblieFilterWrapper .navTabBox {
  height: 0.42rem;
  display: flex;
  align-items: center;
  border-bottom: 0.01rem solid var(--neutral100);
  box-sizing: border-box;
}

.moblieFilterWrapper .navTab {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 0.14rem;
  font-weight: 500;
  color: var(--neutral900);
}

.moblieFilterWrapper .navTab::after {
  margin-left: 0.1rem;
  content: '';
  width: 0.11rem;
  height: 0.11rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow.svg') no-repeat;
  background-size: 100%;
  float: right;
}

.moblieFilterWrapper .navTab.active::after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.moblieFilterWrapper .navTabBox .line {
  width: 0.01rem;
  height: 0.18rem;
  background-color: var(--neutral100);
}

.moblieFilterWrapper .navTab .filterDesc {
  margin-left: 0.05rem;
}