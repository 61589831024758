.ProductSizeGuideCard {
  width: 100%;
  background: #F7F7F7;
  padding: 0.08rem 0.1rem;
  margin-top: 0.1rem;
}

.ProductSizeGuideCard .title {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #666666;
}

.ProductSizeGuideCard .info {
  margin-top: 0.06rem;
  display: flex;
  flex-wrap: wrap;
}

.ProductSizeGuideCard .info .infoItem {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
}

.ProductSizeGuideCard .info .infoItem .name {
  font-weight: 500;
  color: var(--neutral900);
}

.ProductSizeGuideCard .info .infoItem .value,
.ProductSizeGuideCard .info .infoItem .unit {
  color: #808080;
  margin: 0 0.03rem;
}

.ProductSizeGuideCard .viewMore {
  position: relative;
  width: 100%;
  height: 0.24rem;
  padding-top: 0.08rem;
  border-top: 0.01rem solid #E6E6E6;
  margin-top: 0.08rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #666666;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ProductSizeGuideCard .viewMore:after {
  content: "\e612";
  font-size: 0.12rem;
  width: 0.12rem;
  height: 0.12rem;
  color: #666666;
  font-weight: bold;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  margin-left: 0.02rem;
}

.ProductSizeGuideToolTip {
  position: absolute;
  bottom: 0.5rem;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ProductSizeGuideToolTip.right {
  left: 0;
}

.ProductSizeGuideToolTip.left {
  right: 0;
}

.ProductSizeGuideToolTip .contentClassName {
  box-shadow: 0 0 0.23rem rgba(0, 0, 0, 0.13);
}

.ProductSizeGuideToolTip.right .pointerClassName {
  margin: 0 !important;
  padding-left: 0.2rem;
}

.ProductSizeGuideToolTip.left .pointerClassName {
  margin: 0 !important;
  padding-right: 0.2rem;
  justify-content: flex-end;
}

.ProductSizeGuidePop {
  padding: 0.1rem 0.13rem;
}

.ProductSizeGuidePop .title {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  margin-bottom: 0.04rem;
}

.ProductSizeGuidePop .info {
  width: 3.21rem;
  display: flex;
  flex-wrap: wrap;
}

.ProductSizeGuidePop .info .infoItem {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #808080;
}

.ProductSizeGuidePop .info .infoItem .name {
  font-weight: 500;
  color: var(--neutral900);
}

.ProductSizeGuidePop .info .infoItem .value,
.ProductSizeGuidePop .info .infoItem .unit {
  color: #808080;
  margin: 0 0.03rem;
}

.ProductSizeGuidePop .triangle {
  fill: #fff;
  stroke: #fff;
  stroke-width: 100;
}