.card {
  padding: 0.09rem 0.12rem 0.09rem 0.44rem;
  position: relative;
}

.card.isDesktop {
  padding: 0.2rem 0.2rem 0.2rem 0.52rem;
}

.card:not(.isDesktop).arrow::after {
  content: "\e668";
  position: absolute;
  top: 0.09rem;
  right: 0.12rem;
  width: 0.18rem;
  height: 0.18rem;
  line-height: 0.18rem;
  text-align: center;
  color: var(--gray5);
}

.card .section {
  margin-top: 0.04rem;
}

.card .section:not(:last-child) {
  border-bottom: 0.01rem solid var(--neutral50);
}

.card .title {
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  position: relative;
}

.shield .title::before {
  content: "\e665";
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  text-align: center;
  font-size: 0.22rem;
  color: var(--safe-green);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  font-weight: 400;
}

.shoppingLogistics .title::before {
  content: "\e666";
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  text-align: center;
  font-size: 0.22rem;
  color: var(--safe-green);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  font-weight: 400;
}

.securePrivacy .title::before {
  content: "\e663";
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  text-align: center;
  font-size: 0.22rem;
  color: var(--safe-green);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  font-weight: 400;
}

.purchaseProtection .title::before {
  content: "";
  background-image: url('./resources/cart.svg');
  background-repeat: no-repeat;
  background-size: 0.22rem;
  position: absolute;
  top: 0;
  left: -0.1rem;
  width: 0.22rem;
  height: 0.22rem;
  line-height: 0.22rem;
  text-align: center;
  font-size: 0.22rem;
  color: var(--safe-green);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  font-weight: 400;
}

.card .subTitle {
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.17rem;
  color: var(--safe-green);
  position: relative;
}

.card .subTitle::before {
  content: ".";
  position: absolute;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.16rem;
  top: 0;
  left: -0.12rem;
  -webkit-transform: translateY(-0.03rem);
  transform: translateY(-0.03rem);
  color: var(--safe-green);
}

.card .points {
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral600);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.02rem;
}

.card.isDesktop .points {
  margin-top: 0.03rem;
  font-size: 0.14rem;
}

.card .points > li {
  margin-top: 0.08rem;
  flex-basis: 50%;
  position: relative;
  padding-left: 0.08rem;
}

.card.isDesktop .points > li {
  margin-top: 0.12rem;
}

.card .points > li::before {
  content: ".";
  position: absolute;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.16rem;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-0.03rem);
  transform: translateY(-0.03rem);
  color: var(--neutral600);
}

.card .desc {
  margin-top: 0.04rem;
  margin-bottom: 0.04rem;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral900);
}

.card .payMethodCardGroup {
  display: grid;
  grid-template-columns: repeat(5, 0.53rem);
  grid-row-gap: 0.04rem;
  row-gap: 0.04rem;
  grid-column-gap: 0.04rem;
  -webkit-column-gap: 0.04rem;
  column-gap: 0.04rem;
  padding: 0.04rem 0;
}

.card .securityCardGroup {
  padding-top: 0.04rem;
}

.card .securityCardGroup .securityCard {
  display: inline-block;
  margin-right: 0.04rem;
  margin-bottom: 0.04rem;
}

.card .learnMore {
  margin: 0.08rem 0;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral600);
}

.card .learnMore::after {
  content: "\e668";
  width: 0.12rem;
  height: 0.12rem;
  line-height: 0.12rem;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(0.01rem);
  transform: translateY(0.01rem);
  color: var(--neutral600);
}

.green {
  color: var(--safe-green);
}

.card.isDesktop .title {
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.26rem;
  position: relative;
}

.card.isDesktop .subTitle {
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.21rem;
  position: relative;
}

.card.isDesktop .desc {
  margin: 0.1rem 0;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.21rem;
}

.card.isDesktop .securityCardGroup {
  padding-top: 0.1rem;
}

.card.isDesktop .securityCardGroup .securityCard {
  display: inline-block;
  margin-right: 0.07rem;
  margin-bottom: 0.07rem;
}

.card.isDesktop .learnMore {
  cursor: pointer;
  display: inline;
}

.card .title .questionMark {
  font-size: 0.12rem;
  margin-left: 0.08rem;
  cursor: pointer;
}