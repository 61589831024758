.wrapper {
  background-color: #F5F5F5;
  margin-top: 0.08rem;
}

.wrapperWithBanner::before {
  background: url('https://img.flamingo.shop/p/show/b9b877690be649f685a4fe46f37875c9.png') no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
}

.wrapperWithBanner.isDesktop::before {
  background: url('https://img.flamingo.shop/p/show/476177982d724f4eaa91b40a0168784b.png') no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  top: 0;
  left: 0.18rem;
  width: 11.32rem;
}

.wrapperWithBanner {
  position: relative;
}

.tabs {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  grid-gap: 0.05rem;
  gap: 0.05rem;
  padding: 0.19rem 0.09rem 0.19rem 0.09rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0.39rem;
  z-index: calc(var(--z-index-flow-clickable) + 3);
}

.tabs.isDesktop {
  top: 0;
  grid-gap: 0.15rem;
  gap: 0.15rem;
  padding: 0.09rem;
  margin-left: 0.18rem;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tabs .tabItem {
  color: var(--neutral900);
  background-color: var(--white);
  font-size: 0.14rem;
  font-weight: 500;
  height: 0.36rem;
  line-height: 0.34rem;
  padding: 0 0.1rem;
  border-radius: 0.3rem;
  border: 0.01rem solid var(--neutral500);
  white-space: nowrap;
  cursor: pointer;
}

.tabs .tabItem.fancy {
  background: var(--white);
  border: 0.01rem solid #FF8025;
}

.tabs .tabItem.active {
  border: 0.01rem solid var(--neutral900);
  background: var(--neutral900);
  color: var(--white);
}

.tabs .tabItem.active.fancy {
  color: var(--white);
  border: 0.01rem solid #FF8025;
  background: url('https://img.flamingo.shop/p/show/a7f01c5f466f497884d895c3bb684510.png') no-repeat center center;
  background-size: cover;
}

.wrapper .list {
  background: linear-gradient(180deg, #FFF 0%, #F5F5F5 2.87%);
  padding: 0 0.06rem;
}

.wrapper .list.pcListComponent {
  background: #FFF;
  padding: 0 0.12rem 0.12rem 0.12rem;
}

.wrapper .list .listColumn {
  overflow: hidden;
}

.wrapper .list .listColumn:not(:last-child) {
  margin-right: 0.07rem;
}

.wrapper .list.pcListComponent .listColumn:not(:last-child) {
  margin-right: 0;
}

.wrapper .loading {
  height: auto;
}