.specWrapper {
  padding-left: 0.12rem;
  padding-right: 0.12rem;
  background: white;
  min-height: 0.7rem;
  position: relative;
}

.variantOptions {
  display: flex;
  flex-wrap: wrap;
}

.optionsWrapper .variantName {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: bold;
  line-height: 0.18rem;
  display: inline-block;
}

.optionsWrapper.inDesktop .variantName {
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.optionsWrapper:not(.inDesktop) .sizeGuide {
  position: absolute;
  top: 0.03rem;
  right: 0;
  cursor: pointer;
  font-weight: 300;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: #333333;
}

.optionsWrapper.inDesktop .sizeGuide {
  position: absolute;
  top: 0.1rem;
  right: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #333333;
}

.optionsWrapper:not(.inDesktop) .sizeGuide::after {
  content: '\e642';
  position: absolute;
  left: -0.18rem;
  top: 0;
  font-weight: 400;
  color: #333333;
  font-size: 0.12rem;
}

.optionsWrapper.inDesktop .sizeGuide::after {
  content: '\e642';
  position: absolute;
  left: -0.16rem;
  bottom: -0.01rem;
  font-weight: 400;
  color: #333333;
  font-size: 0.13rem;
}

.sizeGuide .icon {
  font-weight: 400;
  color: #333333;
  font-size: 0.12rem;
}

.optionsWrapper {
  padding-bottom: 0.09rem;
  position: relative;
}

.optionsWrapper.inDesktop {
  margin-bottom: 0;
  margin-top: 0.2rem;
  padding: 0;
}

.optionBtn {
  font-size: 0.16rem;
  line-height: 0.16rem;
  font-weight: 400;
  border: 0.01rem solid var(--gray2);
  border-radius: 2rem;
  padding: 0.06rem 0.12rem;
  min-width: 0.4rem;
}

.optionsWrapper.inDesktop .optionBtn {
  height: 0.28rem;
  border: 0.005rem solid rgba(217, 217, 217, .5);
  border-radius: 2rem;
  padding: 0 0.12rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.28rem;
  text-align: center;
  color: #333333;
}

.optionsWrapper.inDesktop .optionBtn:hover {
  border-color: rgba(217, 217, 217);
}

.optionBtn.uiEnabled {
  opacity: 1;
}

.variantOption.selected .optionBtn {
  color: white;
  background: var(--neutral900);
  border: 0.01rem solid var(--neutral900);
}

.variantOption.disabled .optionBtn {
  color: #B7B7B7;
  background: #F0F0F0;
  border: 0.005rem dashed #DBDBDB;
}

.variantOptions .variantOption {
  position: relative;
  margin: 0.16rem 0.2rem 0 0;
  position: relative;
}

.optionsWrapper.inDesktop .variantOption {
  margin: 0.18rem 0.18rem 0 0;
}

.variantColorOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, 0.36rem);
  grid-column-gap: 0.2rem;
  margin-top: 0.16rem;
  margin-bottom: -0.2rem;
}

.specWrapper.inDesktop .variantColorOptions {
  margin-top: 0.15rem;
}

.variantColorOptions .variantColorOption {
  position: relative;
  margin: 0.16rem 0.2rem 0 0;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  overflow: hidden;
}

.variantColorOption.disabled {
  -webkit-filter: opacity(0.2);
  filter: opacity(0.2);
  cursor: no-drop;
}

.toDetail {
  position: absolute;
  top: 0.05rem;
  right: 0.12rem;
  z-index: var(--z-index-flow-clickable);
  font-weight: 300;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: #333;
}

.toDetail span {
  display: inline-block;
  margin-left: 0.02rem;
  color: var(--color-gray);
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.selectedValue {
  font-size: 0.14rem;
  font-weight: 400;
  color: var(--neutral900);
  line-height: 0.14rem;
  margin-left: 0.03rem;
  display: inline-block;
}

.optionsWrapper.inDesktop .selectedValue {
  font-style: normal;
  font-weight: 400;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.radioList {
  margin-top: 0.08rem;
  margin-bottom: -0.09rem;
}

.radioList {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -0.12rem;
  padding-left: 0.12rem;
}

.radioItem {
  outline: none;
}

.radioItem .radioSize {
  line-height: 0.24rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  color: #333333;
  border-radius: 0.17rem;
  margin-right: 0.12rem;
  margin-bottom: 0.1rem;
  border: 0.01rem solid #D9D9D9;
  padding: 0 0.1rem;
}

.radioImage {
  width: 0.26rem !important;
  height: 0.26rem !important;
}

.radioImage img {
  vertical-align: baseline;
}

.radioItem.selected .radioSize {
  border-color: var(--neutral900);
  background-color: var(--neutral900);
  color: var(--white);
}

.radioItem.disabled .smallColorImg {
  -webkit-filter: opacity(0.2);
  filter: opacity(0.2);
  border-color: #fff;
  cursor: no-drop;
  transition: all 1s ease;
}

.radioItem.disabled .radioSize {
  background: #F0F0F0;
  border-color: #F0F0F0;
  color: #B7B7B7;
}

.selectCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.34rem;
  height: 0.34rem;
  border: 0.01rem solid var(--neutral900);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.colorImg {
  cursor: pointer;
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}

.smallColorImg {
  cursor: pointer;
  width: 0.28rem;
  height: 0.28rem;
  margin-right: 0.15rem;
  margin-bottom: 0.1rem;
}

.colorImg.disabled,
.smallColorImg.disabled {
  opacity: 0.2;
  cursor: no-drop;
}