.wrapper {
  width: 3.75rem;
  transition: all 0.3s;
}

.wrapper.inDesktop {
  width: 100%;
  padding: 0 0.6rem;
}

.wrapper .pageHeader {
  border: none;
  z-index: calc(var(--z-index-aside) + 1);
}

.wrapper .header {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: 0.48rem;
  line-height: 0.48rem;
  font-size: 0.15rem;
  padding: 0 0.12rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
}

.wrapper .closeButton {
  width: 0.32rem;
  height: 0.32rem;
  line-height: 0.32rem;
}

.wrapper .closeIcon {
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.32rem;
}

.wrapper .wishButton {
  width: 0.32rem;
  height: 0.32rem;
  line-height: 0.32rem;
  -webkit-transform: translateX(0.06rem);
  transform: translateX(0.06rem);
}

.wrapper .wishIcon {
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.3rem;
}

.wrapper .header .title {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 100vw;
}

.wrapper .header .rightWrapper,
.wrapper .header .leftWrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
}

.leftWrapper {
  flex: 1 1;
}

.wrapper .header .iconLeft {
  background: url('./resources/arrow-bold.svg') no-repeat center;
  display: block;
  background-size: contain;
  width: 0.16rem;
  height: 0.16rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  font-weight: bold;
  line-height: 0.3rem;
}

.wrapperContent {
  padding-bottom: 1.28rem;
  background: #F5F5F5;
}

.freeShippingHintBox {
  margin-bottom: 0.11rem;
  position: relative;
  padding: 0.12rem;
}

.freeShippingHintBox::after {
  content: ' ';
  position: absolute;
  bottom: -0.08rem;
  left: 0;
  width: 100vw;
  height: 0.08rem;
  background-color: var(--bg-common);
}