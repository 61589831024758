.wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0.12rem;
}

.wrapper .titleWrapper {
  display: flex;
}

.wrapper .titleWrapper .rightWrapper {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
}

.wrapper .titleWrapper .rightWrapper .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper .titleWrapper .rightWrapper .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.wrapper .avatar {
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  margin-right: 0.12rem;
}

.wrapper .avaterName {
  font-weight: 400;
  font-size: 0.12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral900);
  background-color: #F5F5F5;
}

.wrapper .rightWrapper .nickname {
  font-size: 0.1rem;
  line-height: 0.14rem;
  color: var(--neutral900);
  font-weight: 400;
}

.wrapper .rightWrapper .sku {
  font-size: 0.1rem;
  line-height: 0.14rem;
  color: var(--neutral500);
}

.wrapper .rightWrapper .desc {
  font-size: 0.1rem;
  line-height: 0.14rem;
  color: var(--neutral500);
  text-align: right;
}

.wrapper.thumbnail .rightWrapper .desc {
  display: none;
}

.wrapper .rightWrapper .reviewRating {
  height: 0.16rem;
  margin-bottom: 0.02rem;
}

.wrapper .contentWrapper {
  margin-top: 0.12rem;
}

.wrapper.thumbnail .contentWrapper {
  margin: 0 0 0 0.48rem;
}

.wrapper .contentWrapper .content {
  font-size: 0.12rem;
  line-height: 0.16rem;
  white-space: pre-wrap;
}

.wrapper .images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.1rem;
}

.wrapper .images .image {
  width: 1.15rem;
  height: 1.15rem;
}

.wrapper .images .image:not(:nth-child(3n+3)) {
  margin-right: 0.03rem;
}

.wrapper .images .image:nth-of-type(3) ~ .image {
  margin-top: 0.03rem;
}

.wrapper .circumference {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.12rem;
  line-height: 0.2rem;
  margin-top: 0.1rem;
  position: relative;
  height: 0.2rem;
  overflow: hidden;
}

.wrapper .circumference.expanded {
  height: auto;
  overflow: auto;
}

.wrapper .circumference .foldIcon {
  display: inline-block;
  width: 0.12rem;
  height: 0.12rem;
  margin-left: 0.04rem;
  vertical-align: -0.015rem;
  background-image: url('https://img.flamingo.shop/p/show/cdb18147b966494c8e67bd6ae40d8a9a.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.wrapper .circumference .circumferenceItem .name {
  color: var(--neutral500);
}

.wrapper .circumference .circumferenceItem .value {
  color: var(--neutral900);
}

/* PC layout */

.pcWrapper {
  width: 100%;
  display: flex;
  font-size: 0.12rem;
  line-height: 0.16rem;
  padding: 0.2rem 0;
  border-bottom: 0.01rem solid #E6E6E6;
  font-weight: normal;
}

.pcWrapper .leftWrapper {
  width: 3.6rem;
  padding-right: 0.4rem;
  display: flex;
}

.pcWrapper .leftWrapper .avatarWrapper .avatar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.12rem;
}

.pcWrapper .leftWrapper .avatarWrapper .avaterName {
  font-weight: 400;
  font-size: 0.12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral900);
  background-color: #F5F5F5;
}

.pcWrapper .leftWrapper .userInfo {
  display: flex;
  flex-direction: column;
}

.pcWrapper .leftWrapper .userInfo .nickname {
  line-height: 0.5rem;
  margin-bottom: 0.1rem;
}

.pcWrapper .leftWrapper .userInfo .circumference {
  display: flex;
  flex-wrap: wrap;
}

.pcWrapper .leftWrapper .userInfo .circumference .circumferenceItem .name {
  color: var(--neutral500);
}

.pcWrapper .leftWrapper .userInfo .circumference .circumferenceItem .value {
  color: var(--neutral900);
}

.pcWrapper .rightWrapper {
  flex: 1 1;
  display: flex;
}

.pcWrapper .rightWrapper .content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.pcWrapper .rightWrapper .content .header {
  display: flex;
}

.pcWrapper .rightWrapper .content .header .reviewRating {
  height: 0.18rem;
  margin-right: 0.2rem;
}

.pcWrapper .rightWrapper .content .header .desc {
  line-height: 0.18rem;
  color: var(--neutral500);
}

.pcWrapper .rightWrapper .content .overallFitAndSku {
  display: flex;
  margin-top: 0.08rem;
}

.pcWrapper .rightWrapper .content  .overallFitAndSku .overallFit {
  margin-right: 0.1rem;
}

.pcWrapper .rightWrapper .content .overallFitAndSku .overallFit .name {
  color: var(--neutral500);
}

.pcWrapper .rightWrapper .content .overallFitAndSku .overallFit .value {
  color: var(--neutral900);
}

.pcWrapper .rightWrapper .content .text {
  font-size: 0.14rem;
  line-height: 0.2rem;
  margin-top: 0.14rem;
  color: var(--neutral900);
  white-space: pre-wrap;
}

.pcWrapper .rightWrapper .images {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.3rem;
  width: 1.2rem;
}

.pcWrapper .rightWrapper .images .imageSection {
  position: relative;
}

.pcWrapper .rightWrapper .images .imageSection .image {
  width: 1.2rem;
  height: 1.2rem;
}

.pcWrapper .rightWrapper .images .imageSection .moreImages {
  position: absolute;
  left: 50%;
  bottom: 0.21rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: white;
  padding: 0.03rem 0.12rem;
  background: rgba(0,0,0, 0.5);
  border-radius: 0.26rem;
}