.wrapper {
  position: relative;
  width: 100%;
  height: 0.08rem;
  background: var(--gray);
  border-radius: 0.04rem;
  overflow: hidden;
  margin: 0.06rem 0;
}

.wrapper.medium {
  width: 1rem;
}

.wrapper.small {
  width: 0.5rem;
}

.wrapper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: shine 1s linear infinite;
  animation: shine 1s linear infinite;
  box-shadow: 0 0.02rem 0.5rem 0 rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes shine {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes shine {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}