.wrapper {
  position: relative;
  line-height: 0;
}

.imageWrapper {
  text-align: center;
  display: block;
  border-radius: inherit;
  width: 100%;
  min-height: 0.01rem;
  /* add a default height for swipe calculate, and actual height will be overwrite once image loaded*/
}

.imageWrapper img {
  display: inline;
}

.bannerImage {
  text-align: center;
  display: flex;
  border-radius: inherit;
  width: 100%;
  min-height: 0.01rem;
  /* add a default height for swipe calculate, and actual height will be overwrite once image loaded*/
}

.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.indicator .item {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  /* border: 1px solid var(--gray2); */
  background-color: #fff;
  margin: 0.16rem 0.05rem;
  cursor: pointer;
  opacity: 0.5;
}

.indicator .current {
  opacity: 1;
}