.wrapper {
  border-top: 0.08rem solid var(--neutral50);
}

.wrapper.isDesktop {
  margin-top: 0;
  border-top-width: 0.24rem;
  padding: 0.12rem;
}

.list {
  padding: 0;
}

.wrapper .listBox .column {
  margin-right: 0.12rem;
}

.wrapper.isDesktop .listBox .column {
  overflow: hidden;
}

@media (max-width: 768px) {
  .wrapper .listBox .column {
    margin-right: 0.08rem;
  }
}

@media (min-width: 768px) and  (max-width: 1200px) {
  .wrapper .listBox .column {
    margin-right: 0.1rem;
  }
}

@media (min-width: 1200px) {
  .wrapper .listBox .column {
    margin-right: 0.12rem;
  }
}

.wrapper.isDesktop {
  /* max-width: 750px; */
  background-color: #fff;
  padding: 0.27rem 0.8rem;
  margin-top: 0.6rem;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  margin-bottom: 0.12rem;
}

.tabslist {
  padding-bottom: 0.12rem;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0.12rem;
  overflow-x: scroll;
  position: -webkit-sticky;
  position: sticky;
  top: calc(var(--sticky-header-top) + 0.6rem);
  left: 0;
  background-color: #fff;
  z-index: var(--z-index-flow-clickable);
}

.tabslist::-webkit-scrollbar {
  display: none;
}

.wrapper.isDesktop .tabslist {
  position: static;
  position: initial;
  padding: 0;
  margin-bottom: 0.27rem;
}

.tabItem {
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  color: var(--neutral900);
  margin-right: 0.12rem;
  white-space: nowrap;
  padding: 0.07rem 0.1rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: #F6F6F6;
  line-height: 0.14rem;
}

.tabItem.active {
  color: #fff;
  background: #FF8000;
}

.wrapper.isDesktop .tabslist .tabItem {
  height: 0.3rem;
  line-height: 0.3rem;
  margin-right: 0.3rem;
  cursor: pointer;
  background: none;
  padding: 0;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #999999;
}

.wrapper.isDesktop .tabslist .tabItem.active {
  color: #1A1919;
  position: relative;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-flow-clickable);
  background-color: rgba(255,255,255,0.5);
}

.wrapper .listBox {
  position: relative;
  padding: 0 0.12rem 0.12rem 0.12rem;
}

.wrapper.isDesktop .listBox {
  position: relative;
  padding: 0;
}