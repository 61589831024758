.wrapper {
  width: 100%;
  height: 100%;
  background: #FEEDCB;
  border-radius: 0.4rem;
  position: relative;
  overflow: hidden;
}

.innerProgressBar {
  width: 0%;
  height: 100%;
  border-radius: 0.4rem;
  background: rgba(233, 125, 51, 0.3) linear-gradient(270deg, rgba(255, 128, 0, 0.3) 0%, #FFCD63 100%);
  position: absolute;
  left: 0;
  top: 0;
}

.children {
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  color: var(--neutral900);
}

/* .progressing {
  transition: width 1s;
} */