.wrapper {
  display: flex;
}

.wrapper .rightWrapper {
  width: 4.2rem;
  flex-shrink: 0;
  margin-left: 0.25rem;
}

.wrapper .rightWrapper.minSliderWrapper {
  padding: 0.2rem 0;
  width: 4.08rem;
}

.wrapper .rightWrapper.minSliderWrapper::-webkit-scrollbar {
  display: none;
}

.wrapper .leftWrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0.12rem;
  width: 8rem;
}

.wrapper.flexible .leftWrapper {
  width: calc(100% - 6rem);
  flex-grow: 1;
}

.titleSection {
  position: relative;
}

.titleSection .klarna {
  margin-bottom: 0.15rem;
}

.titleSection .reviewRating {
  display: flex;
  align-items: center;
}

.titleSection .reviewRating .starRating {
  height: 0.18rem;
}

.titleSection .reviewRating .starRatingDesc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.18rem;
  padding-top: 0.03rem;
}

.titleSection .reviewRating .starRatingDesc span:nth-child(1) {
  margin: 0 0.1rem;
  font-size: 0.12rem;
  font-weight: 500;
  color: var(--neutral900);
}

.titleSection .reviewRating .starRatingDesc span:nth-child(2) {
  font-size: 0.12rem;
  font-weight: 400;
  color: var(--neutral500);
}

.titleSection .priceRow {
  display: flex;
  align-items: center;
}

.titleSection .salePrice {
  font-style: normal;
  font-weight: 600;
  font-size: 0.24rem;
  line-height: 0.34rem;
  text-transform: capitalize;
}

.titleSection .salePrice.highlighted {
  color: #cc0000;
}

.titleSection .originalPrice {
  font-style: normal;
  font-weight: 400;
  font-size: 0.16rem;
  line-height: 0.22rem;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  text-transform: capitalize;
  color: var(--color-original-price);
  margin: 0 0.1rem;
}

.titleSection .discount {
  font-size: 0.11rem;
  line-height: 0.16rem;
  border: 0.01rem solid #d20000;
  color: #d20000;
  padding: 0 0.02rem;
  margin: 0.03rem 0;
  border-radius: 0.02rem;
}

.titleSection .title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 0.15rem;
  width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 0.16rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.titleSection .title .newTag {
  display: inline-block;
  padding: 0 0.07rem 0.01rem 0.07rem;
  border-radius: 0 0.095rem;
  background: rgba(33, 167, 0, 0.75);
  color: #fff;
  font-size: 0.12rem;
  line-height: 0.18rem;
  height: 0.18rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 0.06rem;
}

.purchaseLimit {
  margin-top: 0.1rem;
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: #1a1a1a;
}

.priceChart {
  position: relative;
  width: 100%;
  height: 0.81rem;
  background: url('https://img.flamingo.shop/p/files/Lark20191030-033033.jpeg?100769')
    no-repeat center center;
  background-size: 100%;
}

.priceChart .startPoint {
  font-size: 0.15rem;
  line-height: 0.18rem;
  color: white;
  position: absolute;
  left: 0.34rem;
  bottom: 0.31rem;
}

.priceChart .endPoint {
  font-size: 0.15rem;
  line-height: 0.18rem;
  color: white;
  position: absolute;
  right: 0.24rem;
  bottom: 0.31rem;
}

.priceChart .middlePoint {
  font-size: 0.18rem;
  line-height: 0.24rem;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 0.22rem;
  left: 1.55rem;
  width: 0.7rem;
  text-align: center;
}

.reminderBox {
  width: 100%;
  height: 0.59rem;
  background: url('https://img.flamingo.shop/p/files/Lark20191030-025309.jpeg?100767')
    no-repeat center center;
  background-size: 100%;
}

.promoBar {
  margin-bottom: 0.2rem;
}

.specSection {
  padding: 0;
}

.accordionButton {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.accordionButton::after {
  content: '\e609';
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 0.13rem;
  line-height: 0.26rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.accordionButton.open::after {
  content: '\e60f';
}

.accordionButton:focus {
  outline: none;
}

.accordionPanel {
  padding: 0.16rem 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 0 0.2rem 0;
}

.addToCartBtn {
  /* flex: 1; */
  width: 3.5rem;
  height: 0.52rem;
  line-height: 0.52rem;
  border-radius: 0;
  background: var(--neutral900);
  font-style: normal;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 0.28rem;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  flex-shrink: 0;
}

.addToCartBtn.update {
  text-transform: uppercase;
}

.addToCartBtn .reminderStyle {
  font-size: 0.16rem;
}

.minSliderWrapper .addToCartBtn {
  width: 4.08rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.priceAndCommentWrapper {
  margin-bottom: 0.15rem;
}

.priceAndComment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flashDiscountRowStyle {
  margin-top: 0.03rem;
}

.share {
  position: absolute;
  top: 0;
  right: -0.07rem;
  width: 0.28rem;
  height: 0.28rem;
}

.share::after {
  position: absolute;
  top: 0;
  right: -0.07rem;
  content: '\e60c';
  font-size: 0.28rem;
  color: #000;
  cursor: pointer;
}

.promotionTag {
  display: inline-block;
  width: auto;
  min-height: 0.22rem;
  padding: 0 0.06rem;
  border-radius: 0.02rem;
  line-height: 0.22rem;
  margin-bottom: 0.12rem;
  font-size: 0.14rem;
  background: #FEF7E1;
  border-radius: 0.02rem;
  color: #B8802C;
  font-weight: 400;
}

.clickable {
  cursor: pointer;
}