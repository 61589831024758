.listWrapper {
  display: flex;
  margin-top: 0.2rem;
  max-width: 100vw;
  overflow-x: scroll;
}

.listWrapper::-webkit-scrollbar {
  display: none;
}

.listWrapper .shareItem {
  display: flex;
  flex-direction: column;
  margin-right: 0.21rem;
}

.listWrapper .shareItem .icon {
  width: 0.58rem;
  height: 0.58rem;
  border-radius: 50%;
  margin-bottom: 0.1rem;
  overflow: hidden;
}

.listWrapper .shareItem .name {
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
}

.cover,
.body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--z-index-modal);
}

.cover {
  background-color: var(--bg-modal);
  opacity: 0.6;
}

.body {
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  height: 100%;
}

.content {
  position: relative;
  background-color: #fff;
  max-width: 3.75rem;
  padding: 0.16rem;
}

.closeBtn {
  position: absolute;
  right: 0.12rem;
  top: 0.12rem;
  width: 0.16rem;
  height: 0.16rem;
  background: url('./resources/cancel@2x.png') no-repeat right center;
  background-size: contain;
  cursor: pointer;
}