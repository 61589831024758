.wrapper {
  width: 100%;
  height: 0.28rem;
  background: var(--neutral900);
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  letter-spacing: 0.01rem;
  height: 0.28rem;
  line-height: 0.28rem;
  text-align: center;
  text-transform: uppercase;
}

.wrapper.inDesktop {
  width: 100%;
  height: 0.36rem;
  font-size: 0.12rem;
  line-height: 0.36rem;
  letter-spacing: 0.05rem;
}