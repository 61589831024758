.cover,
.body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: var(--z-index-modal);
}

.cover {
  background-color: var(--bg-modal);
  opacity: 0.7;
}

.body {
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body[position='top'] {
  align-items: flex-start;
}

.body[position='bottom'] {
  align-items: flex-end;
  height: 100%;
}

.content {
  position: relative;
}

.closeBtn {
  position: absolute;
  right: 0.12rem;
  top: 0.12rem;
  width: 0.16rem;
  height: 0.16rem;
  background: url('./resources/cancel@2x.png') no-repeat right center;
  background-size: contain;
  cursor: pointer;
}