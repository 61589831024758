.searchBar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: var(--z-index-flow-clickable);
  background: white;
  width: 3.75rem;
  height: calc(var(--sticky-header-top) + 0.44rem);
  display: flex;
  padding: 0 0.06rem 0 0.2rem;
  padding-top: calc(var(--sticky-header-top) + 0.09rem);
  font-size: 0.15rem;
  line-height: 0.24rem;
  border: 0.01rem solid var(--gray);
}

.searchBar .search {
  background: var(--gray);
}