.rightContent {
  background-color: #67605F;
}

.wrapper {
  background-color: #fff;
  padding: 0.26rem 0.3rem 0.3rem 0.3rem;
  margin-bottom: 0.3rem;
}

.header {
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: var(--neutral900);
}

.priceSection {
  padding-top: 0.13rem;
}

.priceRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  mix-blend-mode: normal;
}

.priceRow.couponHint {
  font-size: 0.11rem;
  line-height: 0.18rem;
  color: #67605F;
  margin: -0.08rem 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.priceRow .titleSection {
  color: #333;
  font-weight: 400;
  padding-right: 0.02rem;
  word-break: break-all;
}

.priceRow .title {
  max-width: 2.6rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  margin-right: 0.07rem;
}

.priceRow .tag {
  display: inline-block;
  font-size: 0.1rem;
  line-height: 0.14rem;
  padding: 0.03rem 0.04rem;
  background: #FFEDEF;
  vertical-align: 0.05rem;
  border-radius: 0.02rem;
}

.priceRow .tag .arrow {
  font-size: 0.12rem;
  margin-left: 0.02rem;
}

.price {
  font-weight: 400;
  white-space: nowrap;
}

.priceRow.text .price::before {
  content: '';
}

.priceRow .price.negative::before {
  content: "- ";
}

.priceRow.orderTotal {
  border-top: 0.01rem solid #d9d9d9;
  padding-top: 0.15rem;
  margin-top: 0.14rem;
  margin-bottom: 0.01rem;
  font-weight: 5rem;
}

.priceRow.totalSaveFee {
  margin-bottom: 0.12rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.priceRow.totalSaveFee .titleSection,
.priceRow.totalSaveFee .price {
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: right;
  color: #D20000;
  margin-top: 0.05rem;
}

.priceRow.totalSaveFee .titleSection .title {
  margin-right: 0;
}

.priceRow.orderTotal .titleSection {
  display: flex;
  align-items: center;
  word-break: break-all;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: var(--neutral900);
  mix-blend-mode: normal;
}

.priceRow.orderTotal .price {
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: right;
  color: var(--neutral900);
  mix-blend-mode: normal;
  opacity: 0.99;
}

.priceRow.freeShipping .price::before {
  content: none;
}

.wrapper .klarna {
  font-size: 0.14rem;
  text-align: center;
}

.wrapper > span {
  font-weight: bold;
}

.btnBox {
  margin-top: 0.17rem;
}

.payBtnBox {
  background-color: #F9C439;
  margin-bottom: 0.11rem;
  height: 100%;
  overflow: hidden;
  height: 45PX;
}

.payBtn {
  width: 100%;
  height: 45PX;
}

.btnBox .checkoutBtn {
  height: 45PX;
  border-radius: 0;
  background: #190808;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.priceRow.discount .titleSection,
.priceRow.discount .price {
  color: #D20000;
}