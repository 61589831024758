.wrapper {
  background: white;
}

.wrapper.inDesktop {
  margin-bottom: 0.08rem;
}

.wrapper .titleSection {
  height: 0.48rem;
  padding: 0 0.18rem;
  color: var(--primary);
  font-size: 0.16rem;
  line-height: 0.48rem;
  font-weight: bold;
  background: url('./resources/arrow-right.png') no-repeat calc(100% - 0.18rem) 50%;
  background-size: 0.05rem 0.09rem;
  display: flex;
  justify-content: space-between;
}

.wrapper .titleSection .subtitle {
  font-weight: normal;
  padding-left: 0.04rem;
}

.wrapper .titleSection.hasRibbon {
  background: none;
}

.wrapper .productSection {
  margin: 0 0 0 0.08rem;
  padding: 0;
}

.wrapper .productSection::-webkit-scrollbar {
  display: none;
}

.wrapper .productSection > * {
  margin-right: 0.07rem;
}

.cardWrapper {
  background: white;
  position: relative;
}

.cardWrapper .image {
  height: 3.68rem;
  margin-right: 0.12rem;
}

.cardWrapper .leftButton {
  position: absolute;
  left: 0.18rem;
  top: 45%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/Left_3x_8d867fe2-1428-4f77-b04c-c144a48fbfce.png?v=1599214611') no-repeat center center;
  background-size: 100%;
  z-index: var(--z-index-layer-1);
}

.cardWrapper .rightButton {
  position: absolute;
  right: 0.18rem;
  top: 45%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/Right_3x_239079a9-8e50-42ed-87a3-fed6392eed9e.png?v=1599214611') no-repeat center center;
  background-size: 100%;
  z-index: var(--z-index-layer-1);
}

.cardWrapper .productSection {
  margin: 0 0 0 0.24rem;
}