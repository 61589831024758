.content {
  width: 92%;
  margin: 0 auto;
  margin-bottom: 0.2rem;
}

.content li {
  list-style-type: disc;
  list-style-position: inside;
  padding-bottom: 0.05rem;
}