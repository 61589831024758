.image {
  border-radius: inherit;
  max-width: 100%;
  max-height: 100%;
}

@supports (object-fit: cover) {
  .image.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@supports (object-fit: contain) {
  .image.contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

/* all classes below are for wrapper */

/* -------------------------------- */

.wrapper {
  border-radius: inherit;
  position: relative;
  display: inline-block;
}

.placeholder {
  display: block;
  position: relative;
  background: var(--gray);
  overflow: hidden;
  padding-top: 100%;
  /* 1 : 1 */
}

.placeholder .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.error {
  background: var(--gray) url('./resources/defaultImage.svg') no-repeat center center;
  background-size: contain;
  vertical-align: bottom;
}