.list:not(.isDesktop) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0.12rem;
}

.list:not(.isDesktop) .cardItem {
  width: 1.7rem;
}

.list.isDesktop {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.list.isDesktop .cardItem {
  width: 20%;
  padding: 0.06rem;
}

.blank {
  width: 100%;
  height: 0.001rem;
  opacity: 0;
}

@media (max-width: 768px) {
  .list.freeColumn.isDesktop .cardItem {
    width: 50%;
    padding: 0.04rem;
  }
}

@media (min-width: 768px) and  (max-width: 1200px) {
  .list.freeColumn.isDesktop .cardItem {
    width: 33.33%;
    padding: 0.06rem;
  }
}

@media (min-width: 1200px) {
  .list.freeColumn.isDesktop .cardItem {
    width: 25%;
    padding: 0.06rem;
  }
}