.grid {
  display: flex;
  flex-wrap: wrap;
  background-color: inherit;
}

.hiddenTitle {
  position: absolute;
  color: transparent;
  width: 0;
  height: 0;
  overflow: hidden;
}

.grid .gridImage {
  display: flex;
}