.wrapper {
  width: 3rem;
}

.accordionButton {
  width: 3rem;
  height: 0.52rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.28rem;
  color: var(--neutral900);
}

.accordionButton:not(.logOut, .WishList)::after {
  content: "\e612";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 0.14rem;
  position: absolute;
  right: 0.2179rem;
}

.accordionButton.link {
  margin-top: 2rem;
}

.accordionButton .icon {
  font-size: 0.26rem;
  margin-right: 0.2rem;
}

.accordionButton.open::after {
  content: "\e612";
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-size: 0.12rem;
  position: absolute;
  right: 0.2179rem;
}

.accordionPanelItem {
  padding-left: 0.76rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.3rem;
  color: #666666;
}

.accordionPanelItem.selected {
  font-weight: 600;
  color: var(--neutral900);
}

.accordionPanelItem:hover {
  background-color: #f5f5f5;
}