.section {
  position: relative;
  background: white;
  padding: 0.09rem 0.12rem;
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: 500;
  line-height: 0.2rem;
}

.section::after {
  content: "\e668";
  position: absolute;
  right: 0.12rem;
  width: 0.18rem;
  height: 0.18rem;
  line-height: 0.18rem;
  text-align: center;
  font-weight: 400;
  color: var(--gray5);
}

.sizeChartWrapper {
  width: 3.75rem;
  background: white;
  padding: 0 0.12rem var(--fixed-footer-bottom) 0.12rem;
  position: relative;
}

.sizeChartWrapper.isDesktop {
  width: 8rem;
}

.sizeChartWrapper .sizeChartTitle {
  font-size: 0.14rem;
  line-height: 0.4rem;
  text-align: center;
  border-bottom: 0.01rem solid var(--gray);
  margin-bottom: 0.04rem;
}

.sizeChartWrapper.isDesktop .scrollArea {
  width: 6rem;
  margin: 0 auto;
}

.sizeChartWrapper:not(.isDesktop) .scrollArea {
  height: 80vh;
}

.sizeChartWrapper .scrollArea::-webkit-scrollbar {
  display: none;
}

.sizeChartWrapper p {
  font-size: 0.13rem;
}

.sizeChartWrapper ul {
  list-style: disc inside;
}

.sizeChartWrapper ul li {
  font-size: 0.13rem;
}

.sizeChartWrapper img {
  width: 100%;
  height: auto;
}