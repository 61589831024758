.wrapper {
  background: #fbf5f2;
  border-radius: 0.15rem;
  overflow: scroll;
  width: 2.95rem;
}

.header {
  font-size: 0.21rem;
  color: #f44831;
  letter-spacing: 0.01rem;
  text-align: center;
  font-weight: bolder;
  font-style: italic;
  padding: 0.16rem 0;
}

.body {
  background: white;
  padding: 0.12rem 0.24rem 0.24rem 0.24rem;
}

.body p {
  font-size: 0.13rem;
  color: #4a4a4a;
  letter-spacing: 0;
  line-height: 0.16rem;
  margin-bottom: 0.05rem;
}

.body .section:not(:last-child) {
  margin-bottom: 0.24rem;
}

.body .secondaryBtn {
  margin-top: 0.1rem;
  height: 0.44rem;
  width: 100%;
  border: 0.01rem solid #000;
  border-radius: 0.02rem;
  background: #000;
  font-size: 0.17rem;
  color: #000;
  letter-spacing: -0.0041rem;
  text-align: center;
  color: #FFF;
}