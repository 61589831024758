.wrapper {
  position: relative;
  padding: 0.17rem 0 0.2rem 0;
  width: 3.91rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper .items {
  width: 3.51rem;
  padding-bottom: 0.09rem;
}

.wrapper .itemsWrapper {
  max-height: 3rem;
  overflow-y: scroll;
  padding-bottom: calc(1.72rem - 0.2rem + 0.1rem -0.02rem);
  box-sizing: content-box;
  box-sizing: initial;
  width: 3.91rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper .itemsWrapper .atcCoupon {
  position: relative;
  width: 3.91rem;
  border-bottom: none;
  margin-bottom: 0.08rem;
  padding-bottom: 0.08rem;
}

.wrapper .itemsWrapper .atcCoupon::after {
  z-index: calc(var(--z-index-flow-clickable) + 1);
  content: ' ';
  width: 3.91rem;
  position: absolute;
  left: 0;
  background-color: #F5F5F5;
  height: 0.08rem;
  bottom: 0;
}

.wrapper .itemsWrapper .atcCoupon > div:last-child {
  width: 3.91rem;
  padding-left: 0.2rem;
}

.wrapper .itemsWrapper .atcCoupon > div:first-child > div:first-child {
  margin-top: 0;
}

.wrapper .itemsWrapper .atcCoupon .header {
  margin-top: 0;
}

.wrapper .itemsWrapper.hasTotalSaveFee {
  padding-bottom: calc(1.72rem - 0.2rem + 0.1rem -0.02rem + 0.16rem);
}

.wrapper .itemsWrapper::-webkit-scrollbar {
  display: none;
}

.wrapper .priceSummaryWrapper {
  position: absolute;
  left: 0;
  bottom: 1.35rem;
  background-color: rgba(0, 0, 0, 0.6);
  width: 3.91rem;
  height: calc(4.29rem + 0.2rem + 0.22rem);
  padding-top: calc(3.16rem - 0.2rem);
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 0.01rem solid #F5F5F5;
  z-index: calc(var(--z-index-flow-clickable) + 2);
}

.wrapper .priceSummaryWrapper.hasTotalSaveFee {
  bottom: 1.51rem;
}

.wrapper .priceSummaryWrapper .content {
  position: absolute;
  bottom: -100%;
  width: 3.91rem;
  background-color: #fff;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: drawerOpenDown;
  animation-name: drawerOpenDown;
}

@-webkit-keyframes drawerOpenDown {
  from {
    opacity: 0;
    bottom: -100%;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes drawerOpenDown {
  from {
    opacity: 0;
    bottom: -100%;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

.wrapper .priceSummaryWrapper .closeButtonRow {
  box-sizing: border-box;
  width: 100%;
  padding: 0.1rem 0.2rem 0 0.2rem;
  display: flex;
  justify-content: flex-end;
  height: 0.46rem;
  border-bottom: 0.01rem solid #F5F5F5;
}

.wrapper .priceSummaryWrapper .closeButtonRow span {
  font-size: 0.16rem;
  color: #333333;
  cursor: pointer;
}

.wrapper .priceSummaryWrapper .content .priceList {
  height: 1.29rem;
  padding: 0.07rem 0.2rem 0 0.2rem;
}

.wrapper .priceSummaryWrapper .content .priceList > div {
  padding-bottom: 0.06rem;
}

.wrapper .items::-webkit-scrollbar {
  display: none;
}

.wrapper .selectedItemsTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #1A1919;
  margin-bottom: 0.08rem;
  background-color: #fff;
  z-index: var(--z-index-aside);
}

.wrapper .unSelectedItemsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  line-height: 0.14rem;
  color: #1A1919;
  height: 0.2rem;
  margin-bottom: 0.08rem;
}

.wrapper .unSelectedItemsTitle .clearBtn {
  cursor: pointer;
}

.wrapper .unSelectedItemsTitle .unsaleIcon {
  margin-left: 0.06rem;
  font-size: 0.18rem;
  line-height: 0.18rem;
  display: inline-block;
  -webkit-transform: translateY(0.01rem);
  transform: translateY(0.01rem);
}

.wrapper .unSelectedItemsTitle .unsaleIcon.active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wrapper .unSelectedItemsTitle span {
  cursor: pointer;
}

.wrapper .items .unSelectedItems {
  width: 3.51rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.wrapper .items .unSelectedItems .item {
  width: 0.57rem;
  height: 0.87rem;
  margin-right: 0.08rem;
  flex-shrink: 0;
}

.tips {
  width: 3.91rem;
  height: 0.22rem;
  background: #FFF2DB;
  border-radius: 0.1rem 0.1rem 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.22rem;
  text-align: center;
  color: var(--neutral900);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tips .container {
  font-size: 0.24rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  white-space: nowrap;
}

.tips .container .highlight {
  color: #D20000;
}

.tips .container .freeShipping {
  font-weight: 500;
}

.wrapper .empty {
  height: calc(2.83rem - 0.17rem - 0.2rem);
}

.wrapper .empty .emptyCartPlay {
  padding-top: 0.5rem;
}

.wrapper .empty .icon {
  width: 0.6rem;
  height: 0.6rem;
  background-size: 0.6rem;
}

.wrapper .empty .title {
  font-size: 0.16rem;
  line-height: 0.22rem;
  margin-bottom: 0.05rem;
}

.wrapper .empty .desc {
  font-size: 0.12rem;
  line-height: 0.16rem;
}

.wrapper .footerWrapper {
  position: absolute;
  width: 3.91rem;
  left: 0;
  bottom: 0.2rem;
}

.wrapper .footerWrapper .content {
  position: relative;
}

.wrapper .footerWrapper .footer {
  position: absolute;
  width: 3.91rem;
  left: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: calc(var(--z-index-flow-clickable) + 3);
}

.wrapper  .cartItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.15rem;
  position: relative;
  padding-left: 0.26rem;
}

.wrapper .cartItem .selectIcon {
  position: absolute;
  top: 0.3rem;
  left: 0;
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.wrapper .selectedItems .cartItem .name {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #4D4D4D;
  padding: 0;
}

.wrapper .selectedItems .cartItem .cartInnerItem {
  flex: 1 1;
}

.wrapper .cartItem .colorPicker {
  margin: 0;
}