.wrapper {
  background: white;
  position: relative;
  max-height: 3.75rem;
  overflow-y: hidden;
}

.wrapper .content {
  padding: 0.04rem 0.08rem 0.08rem 0.08rem;
  z-index: var(--z-index-layer-1);
  position: absolute;
  width: calc(100% - 0.25rem);
  right: 0.2rem;
  left: 0;
  bottom: 0.44rem;
  font-size: 0.14rem;
  background-color: black;
  display: none;
  opacity: 0.6;
  color: white;
}

.wrapper .header {
  position: relative;
  padding: 0.12rem 0.12rem 0.08rem;
}

.wrapper .header .title {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: bold;
  line-height: 0.18rem;
}

.wrapper .header .noComment {
  font-size: 0.12rem;
  line-height: 0.16rem;
  font-weight: 500;
}

.wrapper .images {
  margin: 0 0.12rem;
  padding-bottom: 0.12rem;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.wrapper .image {
  width: 0.84rem;
  height: 1.12rem;
  margin-right: 0.05rem;
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper.inDesktop {
  overflow-x: hidden;
}

.wrapper.inDesktop .desktopShowSlider {
  width: 100%;
  height: auto;
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.wrapper.inDesktop .postWrapper {
  position: relative;
  cursor: pointer;
}

.wrapper.inDesktop .postWrapper {
  position: relative;
  width: 2.3rem;
  height: 3.45rem;
  margin-right: 0.12rem;
  border-radius: 0;
  display: block;
}

.wrapper.inDesktop .postWrapper .image {
  width: 100%;
  height: 100%;
}

.wrapper.inDesktop .content {
  padding: 0.04rem 0.08rem 0.08rem 0.08rem;
  z-index: var(--z-index-layer-1);
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 0.14rem;
  background-color: black;
  display: none;
  opacity: 0.6;
  color: white;
}

@media (max-width: 1074px) {
  .wrapper.inDesktop .postWrapper {
    width: calc(100% - 0.06rem);
    height: 2.88rem;
    margin-right: 0.06rem;
  }
}

@media (min-width: 1074px) and  (max-width: 1366px) {
  .wrapper.inDesktop .postWrapper {
    width: 1.92rem;
    height: 2.88rem;
    margin-right: 0.08rem;
  }
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .wrapper.inDesktop .postWrapper {
    width: 2.102rem;
    height: 3.153rem;
    margin-right: 0.1rem;
  }
}

.content .title {
  font-size: 0.11rem;
  margin-bottom: 0.02rem;
}

.content .subtitle {
  font-size: 0.11rem;
  margin-bottom: 0.02rem;
}

.content .desc {
  font-size: 0.11rem;
  line-height: 0.16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.wrapper.inDesktop .postWrapper:hover .content {
  display: block;
}

.wrapper.inDesktop .slider div {
  margin: 0;
}

.wrapper.inDesktop .leftButton {
  position: absolute;
  left: 0.2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/Left_3x_8d867fe2-1428-4f77-b04c-c144a48fbfce.png?v=1599214611') no-repeat center center;
  background-size: 100%;
  z-index: var(--z-index-layer-1);
  cursor: pointer;
}

.wrapper.inDesktop .rightButton {
  position: absolute;
  right: 0.4rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/Right_3x_239079a9-8e50-42ed-87a3-fed6392eed9e.png?v=1599214611') no-repeat center center;
  background-size: 100%;
  z-index: var(--z-index-layer-1);
  cursor: pointer;
}