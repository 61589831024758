.wrapper {
  display: inline-block;
  width: 100%;
}

.couponWrapper {
  width: 3.51rem;
  height: 0.9rem;
  position: relative;
  display: flex;
  border-radius: 0.06rem;
  margin-bottom: 0.08rem;
  background: #FEF6F3;
  overflow: hidden;
}

.couponWrapper .bg {
  width: 0.4rem;
  height: 0.4rem;
  border-top: 0.2rem solid #FE6429;
  border-right: 0.2rem solid #FE6429;
  border-bottom: 0.2rem solid transparent;
  border-left: 0.2rem solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  font-style: normal;
  font-weight: 800;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #FFFFFF;
}

.checkIcon {
  position: absolute;
  top: 0;
  right: 0.03rem;
  color: #fff;
  font-size: 0.12rem;
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 26%;
  height: 100%;
  padding: 0 0.05rem;
  color: white;
  background: #FE6429;
  position: relative;
}

.leftWrapper::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.24rem;
  height: 0.24rem;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transform: translateX(50%) translateY(-50%);
  transform: translateX(50%) translateY(-50%);
}

.leftWrapper::after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0.24rem;
  height: 0.24rem;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transform: translateX(50%) translateY(50%);
  transform: translateX(50%) translateY(50%);
}

.leftWrapper .discountText {
  font-style: normal;
  font-weight: 600;
  font-size: 0.17rem;
  line-height: 0.26rem;
  color: #FFFFFF;
  text-align: center;
}

.wrapper.isDesktop .leftWrapper .discountText {
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.24rem;
  text-align: center;
  color: #FFFFFF;
}

.rightWrapper {
  position: relative;
  flex: 1 1;
  padding: 0.12rem 0.18rem;
  color: #363233;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleSection {
  flex: 1 1;
}

.rightWrapper .couponTarget {
  font-style: normal;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #FE6429;
  width: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 0.01rem;
}

.wrapper.isDesktop .rightWrapper .couponTarget {
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #FE6429;
}

.rightWrapper .couponTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #FE6429;
}

.rightWrapper .desc li {
  font-style: normal;
  font-weight: 400;
  font-size: 0.1rem;
  line-height: 0.16rem;
  color: #666666;
  width: 2.12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper.isDesktop .rightWrapper .desc li {
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #666666;
}

.rightWrapper .btn {
  position: absolute;
  right: 0.18rem;
  bottom: 0.12rem;
  width: 0.58rem;
  height: 0.2rem;
  background: #FB4C4B;
  border-radius: 0.13rem;
  font-size: 0.1rem;
  line-height: 0.2rem;
  color: white;
  font-weight: bold;
  z-index: var(--z-index-layer-1);
}

.rightWrapper .couponTakenIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.75rem;
  height: 0.6rem;
  background: url('./resources/couponTaken.png') no-repeat left center;
  background-size: 100%;
}