.wrapper {
  position: relative;
  width: 3.3rem;
  height: 100vh;
  background: white;
  padding-top: var(--safe-area-inset-top);
}

.wrapper.inDesktop {
  position: unset;
  width: 100%;
}

.content {
  border-top: 0.01rem solid var(--gray);
  padding-top: 0.04rem;
}

.wrapper.inDesktop .content {
  border-top: unset;
  padding: 0;
}

.pcClearBtn {
  height: 0.36rem;
  width: 100%;
  border-radius: unset;
  color: white;
  background: var(--neutral900);
  font-weight: 700;
  font-size: 0.14rem;
  border: 0.01rem solid var(--neutral900);
  margin-bottom: 0.26rem;
}

.pcClearBtn:hover {
  background: white;
  color: var(--neutral900);
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 0.06rem;
  margin-right: 0.12rem;
  height: 0.43rem;
}

.header .closeBtn {
  margin-top: 0.07rem;
  width: 0.28rem;
  height: 0.28rem;
  background: url('https://img.flamingo.shop/p/files/close.png?v=1579332768') no-repeat;
  background-size: 100%;
}

.header .title {
  font-size: 0.16rem;
  line-height: 0.43rem;
  color: var(--neutral900);
}

.header .clearBtn {
  line-height: 0.43rem;
  font-size: 0.12rem;
  min-width: 0.52rem;
  color: #d30303;
}

.itemWrapper {
  margin-top: 0.05rem;
  display: flex;
  flex-wrap: wrap;
}

.buttonGroup {
  position: fixed;
  bottom: var(--fixed-footer-bottom);
  right: 0;
  width: 3.3rem;
  border-top: 0.01rem solid var(--gray);
  padding: 0.1rem 0.12rem;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  background-color: white;
}

.buttonGroup .clearBtn {
  border: 0.01rem solid var(--neutral400);
  color: var(--neutral900);
  font-size: 0.14rem;
  line-height: 0.32rem;
  height: 0.32rem;
  width: 1.07rem;
  border-radius: 0;
  justify-content: center;
  background: transparent;
}

.doneBtn {
  font-size: 0.14rem;
  line-height: 0.32rem;
  height: 0.32rem;
  width: 1.07rem;
  justify-content: center;
  margin-left: 0.1rem;
  border-radius: 0;
}

.modalWrapper {
  width: 100%;
}