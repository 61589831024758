.mobile {
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.13rem;
}

.mobile .sortBy {
  display: flex;
}

.mobile .sortBy::after {
  content: '';
  margin-top: 0.09rem;
  margin-left: 0.08rem;
  width: 0.1rem;
  height: 0.1rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow.svg');
  background-size: 100%;
}

.mobile .sortBy.withSortListOpen::after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.mobile .sortCategory.highlighted {
  font-weight: bold;
}

.mobile .sortList {
  width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--z-index-flow-clickable);
  background: white;
  font-size: 0.12rem;
  line-height: 0.3rem;
  box-shadow: 0 0.09rem 0.08rem 0 rgb(0 0 0 / 8%), 0 0.09rem 0.08rem 0 rgb(0 0 0 / 8%);
}

.sortItem {
  display: flex;
  font-size: 0.12rem;
  padding: 0.1rem 0.15rem;
}

.sortItem:last-child {
  margin-bottom: 0.1rem;
}

.mobile .sortList .sortItem.selected {
  font-weight: bold;
  color: var(--neutral900);
  padding-left: -0.15rem;
}

.inDesktop.rightInfo {
  display: flex;
}

.inDesktop.rightInfo .sortTitle {
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 0.5rem;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  text-transform: capitalize;
  color: #333333;
}

.inDesktop.rightInfo .currentSort {
  cursor: pointer;
  padding-top: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.12rem;
}

.inDesktop.rightInfo .currentSort .title {
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: #333333;
}

.inDesktop.rightInfo .currentSort::after {
  content: '';
  width: 0.1rem;
  height: 0.1rem;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background: url('./resources/up_arrow.svg') no-repeat;
  background-size: 100%;
  float: right;
}

.inDesktop.rightInfo .currentSort.active::after {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.inDesktop.rightInfo .sortSelector {
  position: relative;
  width: 2rem;
  height: 0.32rem;
  line-height: 0.32rem;
  border: 0.01rem solid var(--gray6);
  background: white;
  margin-top: 0.09rem;
  margin-left: 0.12rem;
}

.rightInfo .sortSelector.dark {
  background-color: #F7F7F7;
  border-color: #F7F7F7;
}

.inDesktop.rightInfo .sortSelector .sortItem {
  cursor: pointer;
  color: var(--neutral900);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 0.12rem;
  font-size: 0.12rem;
  line-height: 0.28rem;
}

.inDesktop.rightInfo .sortSelector .sortItem:hover {
  background: var(--gray);
}

.inDesktop.rightInfo .sortSelector .sortItem.active {
  color: var(--neutral900);
  font-weight: bold;
}

.inDesktop.rightInfo .sortSelector .sortItem:last-child {
  margin-bottom: unset;
}

.inDesktop.rightInfo .sortWrapper {
  position: absolute;
  background: white;
  z-index: 100;
  width: 100%;
  box-shadow: 0 0.02rem 0.08rem 0 rgb(0 0 0 / 8%), 0 0.02rem 0.08rem 0 rgb(0 0 0 / 8%);
  margin-top: 0.08rem;
  top: 0.3rem;
  left: 0;
}