.card {
  margin-top: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card .image {
  width: 0.42rem;
  height: 0.42rem;
  border-radius: 50%;
  border: 0.02rem solid #FFC66E;
  box-shadow: 0 0.01rem 0.08rem 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 0.07rem;
}

.card .name {
  font-size: 0.15rem;
  line-height: 0.18rem;
  margin-bottom: 0.17rem;
}

.card .review {
  position: relative;
  width: 60%;
  font-size: 0.11rem;
  line-height: 0.13rem;
  margin-bottom: 0.24rem;
  font-weight: normal;
}

.card .review::before {
  content: '';
  width: 0.35rem;
  height: 0.35rem;
  background: url('https://img.flamingo.shop/p/files/Lark20191108-012000.png?100887') no-repeat center center;
  background-size: 100%;
  position: absolute;
  left: -0.3rem;
  top: -0.26rem;
}

.card .rating {
  height: 0.2rem;
}