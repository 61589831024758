.box {
  font-size: 0.18rem !important;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 0 !important;
  padding-bottom: 1rem !important;
  background-color: inherit !important;
  padding: 0.6rem;
}

.headerSection {
  background-color: inherit !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: auto !important;
  padding-top: 0.12rem;
}

.headerSection.isDesktop {
  margin-bottom: 0.18rem !important;
  padding-top: 0;
}

.headerSection > span {
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.16rem;
  color: var(--neutral900);
  margin: 0 0.07rem;
}

.headerSection.isDesktop > span {
  background-color: inherit !important;
  text-align: center !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  font-size: 0.26rem !important;
  line-height: 0.28rem !important;
  margin: 0 0.13rem !important;
}

.separator {
  margin-top: 0 !important;
  width: 0.14rem !important;
  height: 0.02rem !important;
}

.separator.isDesktop {
  margin-top: 0 !important;
  width: 0.5rem !important;
  height: 0.03rem !important;
}

.listWrapper {
  margin-top: 0.12rem;
}