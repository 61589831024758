.wrapper {
  width: 3.75rem;
  background: white;
}

.wrapper.isDesktop {
  margin-bottom: 1.2rem;
}

.head {
  width: 3.75rem;
  height: 0.48rem;
  line-height: 0.48rem;
  border-bottom: 0.01rem solid #f5f5f5;
  text-align: center;
  font-weight: bolder;
  font-size: 0.14rem;
  position: relative;
}

.content {
  margin: 0.15rem 0.2rem;
}

.term {
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral900);
  margin: 0.1rem 0 0.15rem 0;
}

.notFitTitle {
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  margin: 0.05rem 0  0.1rem 0;
}

.card {
  margin-bottom: 0.1rem;
}