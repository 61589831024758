.wrapper {
  border-radius: 0.04rem;
  background: white;
  margin: 0;
}

.wrapper.inDesktop {
  width: auto;
}

.wrapper .imageWrapper {
  position: relative;
}

.wrapper .imageWrapper .cardImage {
  width: 1rem;
  height: 1.25rem;
  border-radius: 0.04rem 0.04rem 0 0;
}

.wrapper .imageWrapper .cardImage.inDesktop {
  height: 3.5rem;
  width: auto;
  margin-right: 0.16rem;
  border-radius: 0;
}

.wrapper .imageWrapper .discount {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0.11rem;
  line-height: 0.13rem;
  border-radius: 0.02rem;
  background-color: var(--color-common);
  color: var(--white);
  font-weight: 600;
  padding: 0.02rem;
}

.wrapper .price {
  text-align: center;
  padding: 0.04rem 0;
}

.wrapper .price .salePrice {
  color: var(--primary);
  font-size: 0.13rem;
  line-height: 0.15rem;
  font-weight: bold;
}

.wrapper .price .salePrice.inDesktop {
  font-size: 0.2rem;
  line-height: 0.22rem;
}

.wrapper .price .originalPrice {
  font-size: 0.1rem;
  line-height: 0.12rem;
  color: var(--color-gray);
  text-decoration: line-through;
}

.wrapper .price .originalPrice.inDesktop {
  font-size: 0.14rem;
  line-height: 0.16rem;
}