.wrapper {
  position: fixed;
  right: 0.09rem;
  bottom: 1.5rem;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  z-index: var(--z-index-aside);
}

.image {
  width: 0.45rem;
  height: 0.45rem;
  background-image: url('https://img.flamingo.shop/p/show/b615ad8db4154b45862cae2137d97459.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.close {
  position: absolute;
  top: -0.2rem;
  left: 0.32rem;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 0.16rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  display: flex;
  align-items: center;
  justify-content: center;
}