.vertical {
  margin: 0 0.14rem 0 0.06rem;
}

.vertical .progressWrapper {
  width: 1.12rem;
  height: 0.16rem;
  position: relative;
}

.vertical .text {
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  color: var(--neutral900);
  width: 100%;
  height: 0.16rem;
  white-space: nowrap;
}

.vertical .tip {
  margin-top: 0.02rem;
}

.vertical .skeleton {
  width: 0.86rem;
  height: 0.32rem;
}

.horizontal {
  width: 3.75rem;
  height: 0.5rem;
  padding: 0 0.12rem;
  display: flex;
  align-items: center;
  border-bottom: 0.01rem solid #F6F6F6;
  margin-bottom: 0.12rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.horizontal .icon {
  font-size: 0.2rem;
  margin-right: 0.06rem;
}

.horizontal .bar {
  width: 1.4rem;
  height: 0.16rem;
  margin-left: 0.12rem;
}

.horizontal .green {
  color: #17B35A;
}