.wrapper {
  display: block;
  position: relative;
  margin: 0 0.04rem;
}

.wrapper.inDesktop {
  width: auto;
  margin-bottom: 0.08rem;
  margin: 0 0.24rem;
}

.wrapper .cardWrapper {
  border-radius: 0.08rem;
  overflow: hidden;
}

.wrapper .imageWrapper {
  position: relative;
}

.wrapper .imageWrapper .cardImage {
  width: auto;
  height: auto;
}

.wrapper .imageWrapper .cardImage.inDesktop {
  height: auto;
  width: auto;
  border-radius: 0;
}

.wrapper .discount {
  position: absolute;
  left: -0.07rem;
  top: -0.05rem;
  text-align: center;
  color: var(--white);
  height: 0.31rem;
  width: 0.7rem;
  background: url('https://img.flamingo.shop/p/files/5_14_3x_13f81747-d1f1-4700-99da-067e809f1460.png?v=1633670209') no-repeat;
  background-size: 100%;
  padding-top: 0.03rem;
  margin-top: -0.02rem;
  font-style: italic;
  z-index: 1;
}

.wrapper.inDesktop .discount {
  left: -0.16rem;
  top: -0.2rem;
  margin-top: 0.08rem;
  height: 1.27rem;
  width: 1.65rem;
  background: url('https://img.flamingo.shop/p/files/5_14_3x_13f81747-d1f1-4700-99da-067e809f1460.png?v=1633670209') no-repeat;
  padding-top: 0.08rem;
  background-size: 100%;
}

.wrapper .discount .desc {
  font-size: 0.2rem;
  line-height: 0.2rem;
  font-weight: bold;
}

.wrapper.inDesktop .discount .desc {
  font-size: 0.4rem;
  line-height: 0.4rem;
}

.wrapper.inDesktop .discount .bottom {
  font-size: 0.32rem;
  line-height: 0.36rem;
}

.wrapper .price {
  line-height: 0.14rem;
  background-color: #000000;
}

.wrapper .price .salePrice {
  color: #FFFFFF;
  font-size: 0.12rem;
  line-height: 0.14rem;
  font-weight: bold;
  margin-bottom: 0.01rem;
  margin-left: 0.06rem;
  display: inline-block;
  margin-top: 0.05rem;
  margin-bottom: 0.04rem;
}

.wrapper.inDesktop .price {
  padding: 0.04rem 0 0.08rem 0;
}

.wrapper.inDesktop .price .salePrice {
  font-size: 0.3rem;
  line-height: 0.32rem;
  margin-left: 0.1rem;
}

.wrapper .price .originalPrice {
  font-size: 0.09rem;
  line-height: 0.12rem;
  margin-left: 0.06rem;
  color: #888888;
  text-decoration: line-through;
  display: inline-block;
  margin-bottom: 0.06rem;
}

.wrapper.inDesktop .price .originalPrice {
  font-size: 0.2rem;
  line-height: 0.2rem;
  margin-left: 0.1rem;
}