.wrapper {
  --radioSize: 0.1rem;
  --radioSmallSize: 0.06rem;
  position: relative;
}

.wrapper .topContent,
.wrapper .bottomContent {
  position: relative;
  display: flex;
}

.wrapper .topContent > main,
.wrapper .bottomContent > main {
  flex: 1 1;
  background-color: #fff;
  padding: 0 0.2rem;
}

.wrapper .bottomContent main {
  border-top: 0.01rem dashed rgba(255 155 117, .5) !important;
}

.wrapper .bar {
  width: 100%;
  flex: 1 1;
  background-color: #fff;
}

/* 核心属性base */

.wrapper .circle {
  width: var(--radioSize);
  height: var(--radioSize);
  position: relative;
  font-size: calc(var(--radioSize)*2);
  color: transparent;
  position: absolute;
  bottom: 0;
  left: -0.1rem;
}

.wrapper  .circle.rightCircle {
  bottom: 0;
  right: -0.1rem;
  left: unset;
}

.wrapper .circle.withBorder {
  border: 0.01rem solid red;
}

.wrapper .circle.withBorder::after {
  border: inherit;
  background-color: white;
}

.wrapper .circle::after {
  position: absolute;
  content: '';
  width: 200%;
  height: 200%;
  border-radius: 100%;
  color: inherit;
  transition: .2s;
  -webkit-clip-path: inset(-0.01rem);
  clip-path: inset(-0.01rem);
  z-index: 1;
  background: inherit;
}

.wrapper .circle.rightCircle::after {
  left: -100%;
}