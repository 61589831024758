.list {
  padding: 0.12rem;
  display: flex;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin-top: 0.08rem;
  background: var(--white);
}

.list > *:not(:last-child) {
  margin: 0 0.12rem 0 0;
  flex: 0 0 auto;
}