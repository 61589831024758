.wrapper {
  width: 2.64rem;
  height: 3.192rem;
  box-shadow: 0 0.06rem 0.06rem rgba(0, 0, 0, 0.08);
  border-radius: 0.16rem;
  background-color: #fff;
  background-image: url('https://img.flamingo.shop/p/show/5ebc2c95b4504fbb86919253a2e4550a.png');
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-animation-name: comeIn;
  animation-name: comeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes comeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform: unset;
    transform: unset;
  }
}

@keyframes comeIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    -webkit-transform: unset;
    transform: unset;
  }
}

.wrapper .close {
  position: absolute;
  top: 0.12rem;
  right: 0.12rem;
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 0.16rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wrapper .giftImage {
  position: absolute;
  top: -1.208rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 2.04rem;
  height: 1.936rem;
  background-image: url('https://img.flamingo.shop/p/show/05a17440a30f4d94a08f3b9ba8eb0474.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

.wrapper .roof {
  position: absolute;
  top: 0.2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1.664rem;
  height: 0.44rem;
  background-image: url('https://img.flamingo.shop/p/show/46c306f8ebab4bf980fd8528054c4738.png');
  background-repeat: no-repeat;
  background-size: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  text-align: center;
  text-transform: uppercase;
  padding-top: 0.04rem;
}

.wrapper .freeGiftImage {
  position: absolute;
  top: 0.92rem;
  left: 1.32rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
}

.wrapper .title {
  position: absolute;
  top: 1.92rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  padding: 0 0.16rem;
}

.wrapper .btn {
  position: absolute;
  top: 2.424rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  min-width: 1.264rem;
  height: 0.368rem;
  padding: 0 0.28rem;
  background: #FF8000;
  border-radius: 0.6rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.18rem;
  text-align: center;
  color: #FFFFFF;
  white-space: nowrap;
}

.seconds {
  display: inline-block;
  width: 0.36rem;
}