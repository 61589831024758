.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: black;
}

.wrapper.isApp {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.wrapper.inDesktop {
  width: 3.75rem;
  background: black;
  left: unset;
}

.wrapper .header {
  position: absolute;
  left: 0;
  top: 0.08rem;
  width: 100%;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  color: white;
  z-index: var(--z-index-flow-clickable);
}

.wrapper.inDesktop .header {
  font-size: 0.17rem;
  line-height: 0.32rem;
}

.wrapper .closeBtn {
  position: absolute;
  top: 0.03rem;
  right: 0.12rem;
  width: 0.12rem;
  height: 0.16rem;
  background: url('https://img.flamingo.shop/p/files/close_3x_c45461aa-9fdf-48f8-956a-dde1054ab692.png?v=1585734914') no-repeat right center;
  background-size: contain;
}

.wrapper.inDesktop .closeBtn {
  width: 0.15rem;
  height: 0.15rem;
}

/* content layout */

.imageWrapper {
  background-color: black;
  width: 3.75rem;
  height: calc(100vh - 0.36rem - var(--fixed-footer-bottom) - 0.08rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.imageWrapper.inDesktop {
  margin-bottom: 0.3rem;
}

.imageWrapper .image {
  width: 100%;
  height: 100%;
}

.footerWrapper {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: white;
}

.footerWrapper .content {
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000000 100%);
  padding: 0.1rem 0.12rem;
  pointer-events: none;
}

.footerWrapper .content .name {
  font-size: 0.1rem;
  line-height: 0.14rem;
}

.footerWrapper .content .star {
  height: 0.16rem;
  font-size: 0.1rem;
  line-height: 0.14rem;
  margin-top: 0.08rem;
}

.footerWrapper .content .sku {
  font-size: 0.1rem;
  line-height: 0.14rem;
  margin-top: 0.08rem;
}

.footerWrapper .content .text {
  font-size: 0.12rem;
  line-height: 0.16rem;
  margin-top: 0.08rem;
}

.footerWrapper .footer {
  width: 100%;
  padding: 0 0.12rem calc( var(--fixed-footer-bottom) + 0.08rem) 0.12rem;
  display: flex;
  justify-content: space-between;
  background-color: black;
}

.footerWrapper .footer .price {
  font-size: 0.16rem;
  line-height: 0.36rem;
  font-weight: 600;
}

.footerWrapper .footer .price.hasDiscount {
  color: #FD6D1C;
}

.footerWrapper .footer .price .flashSaleIcon {
  color: #FFC834;
  margin-right: 0.09rem;
}

.footerWrapper .footer .price .discount {
  font-size: 0.1rem;
  line-height: 0.12rem;
  color: #FD6D1C;
  padding: 0.03rem;
  border: 0.01rem solid #FD6D1C;
  margin-left: 0.08rem;
  vertical-align: 0.02rem;
}

.footerWrapper .footer .btn {
  margin: 0.04rem 0;
  font-size: 0.16rem;
  font-weight: 600;
  color: black;
  background: #FD6D1C;
  padding: 0 0.1rem;
  line-height: 0.28rem;
  border-radius: 0.03rem;
}