.wrapper {
  overflow: hidden;
}

.wrapper.disabled {
  opacity: 0.5;
}

.topWrapper {
  color: #FD6D1C;
  padding: 0.1rem 0;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topWrapper .titleWrapper .title {
  font-size: 0.18rem;
  line-height: 0.28rem;
  font-weight: 600;
}

.topWrapper .titleWrapper .title .thumb {
  display: inline-block;
  width: 0.16rem;
  height: 0.16rem;
  background: url('https://img.flamingo.shop/p/show/1fce049faee04a138a26a30f61665722.png') no-repeat center center;
  background-size: 100%;
  margin: 0 0 -0.01rem 0.06rem;
}

.topWrapper .titleWrapper .countdownBar {
  display: flex;
  flex-wrap: wrap;
  color: var(--neutral900);
  justify-content: flex-end;
}

.topWrapper .titleWrapper .countdownBar .expiresIn {
  display: inline-block;
  font-size: 0.1rem;
  line-height: 0.18rem;
  color: var(--neutral900);
  font-weight: 400;
  margin: 0.03rem 0.05rem 0 0;
}

.topWrapper .titleWrapper .countdownBar .cardClassName {
  width: 0.18rem;
  height: 0.18rem;
  border-radius: 0.04rem;
  background-color: var(--neutral900);
  font-style: normal;
  font-size: 0.1rem;
  line-height: 0.18rem;
  text-align: center;
  color: #FFFFFF;
  padding: 0;
  margin-right: 0.02rem;
}

.topWrapper .ruleWrapper {
  position: relative;
}

.topWrapper .ruleWrapper .content {
  max-width: 2.4rem;
}

.topWrapper .countdownBar .separatorClassName {
  color: var(--neutral900);
  margin-right: 0.02rem;
}

.topWrapper .ruleWrapper .selectIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0.2rem;
  height: 0.2rem;
}

.topWrapper .rule,
.topWrapper .discountLimitRule {
  font-size: 0.12rem;
  line-height: 0.16rem;
  margin-top: 0.02rem;
}

.topWrapper .saveFee {
  font-size: 0.12rem;
  line-height: 0.16rem;
  font-weight: 400;
  color: var(--gray5);
  margin-top: 0.02rem;
}

.topWrapper .saveFee .highlighted {
  color: #FE6A00;
}

.bottomWrapper {
  padding: 0.1rem 0;
  font-size: 0.1rem;
  line-height: 0.14rem;
  color: var(--gray5);
  font-weight: 400;
}