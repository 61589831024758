.list {
  display: flex;
  justify-content: space-between;
  padding: 0.12rem;
}

.list.default {
  justify-content: start;
}

.list .column:not(:last-child) {
  margin-right: 0.11rem;
}