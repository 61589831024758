.wrapper {
  position: relative;
  background-image: url('./resources/ci_modal_bg.gif');
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.8rem;
  height: 4.07rem;
}

@-webkit-keyframes showAnimation {
  from {
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showAnimation {
  from {
    -webkit-transform: translateY(2rem);
    transform: translateY(2rem);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.wrapper .content {
  position: relative;
  padding-top: 0.4rem;
  margin: 0 0.21rem;
  color: white;
  text-align: center;
  -webkit-animation: showAnimation 0.6s forwards;
  animation: showAnimation 0.6s forwards;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  opacity: 0;
}

.wrapper .closeBtn {
  position: absolute;
  right: 0.1rem;
  top: -0.1rem;
  width: 0.12rem;
  height: 0.12rem;
  color: white;
  cursor: pointer;
}

.wrapper .content .title {
  font-size: 0.13rem;
  line-height: 0.18rem;
  font-weight: 600;
}

.wrapper .content .subtitle {
  font-size: 0.12rem;
  line-height: 0.16rem;
}

.wrapper .content .discount {
  position: relative;
  font-size: 0.8rem;
  line-height: 0.85rem;
  font-weight: 800;
  margin: 0.1rem 0.16rem;
}

.wrapper .content .discount::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  width: 2rem;
  height: 0.005rem;
  background: rgba(255,255,255, 0.6);
}

.wrapper .content .discount::after {
  content: ' ';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 2rem;
  height: 0.005rem;
  background: rgba(255,255,255, 0.6);
}

.wrapper .submitBtn {
  position: absolute;
  left: 0.24rem;
  bottom: 0.2rem;
  width: 2.28rem;
  height: 0.4rem;
  color: #FF2424;
  background: white;
  border-radius: 0.8rem;
  text-transform: uppercase;
  font-size: 0.18rem;
  line-height: 0.4rem;
  font-weight: 800;
}