.borderedCouponCard {
  overflow: hidden;
  background-color: #FDF1E5;
  border-radius: 0.08rem;
}

.borderedCouponCard .couponInfo {
  border: 0.01rem solid rgba(253, 109, 28, 0.50);
  border-bottom: unset;
  border-top-left-radius: 0.08rem;
  border-top-right-radius: 0.08rem;
  padding: 0.06rem 0.12rem 0.02rem;
}

.borderedCouponCard .couponInfo .couponTitle {
  margin-bottom: 0.02rem;
  color: #FE6A00;
  font-size: 0.14rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.14rem;
  white-space: nowrap;
}

.borderedCouponCard .couponInfo .couponSubTitle {
  max-width: 1.57rem;
  color: #FE6A00;
  font-size: 0.12rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.12rem;
  white-space: nowrap;
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.borderedCouponCard .bar {
  position: relative;
  width: 100%;
  border-top: 0.01rem dashed rgba(253, 109, 28, 0.50);
  height: 0;
}

.borderedCouponCard .bar .leftRadio {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.14rem;
  height: 0.14rem;
  background-color: var(--white);
  border: 0.01rem solid rgba(253, 109, 28, 0.50);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.borderedCouponCard .bar .rightRadio {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.14rem;
  height: 0.14rem;
  background-color: var(--white);
  border: 0.01rem solid rgba(253, 109, 28, 0.50);
  border-radius: 50%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.borderedCouponCard .couponDesc {
  border: 0.01rem solid rgba(253, 109, 28, 0.50);
  border-top: unset;
  border-bottom-left-radius: 0.08rem;
  border-bottom-right-radius: 0.08rem;
  padding: 0.03rem 0.12rem;
}

.borderedCouponCard .couponDesc .couponDate,
.borderedCouponCard .couponDesc .couponRule {
  color: #666;
  font-size: 0.12rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.12rem;
  -webkit-transform: scale(0.666);
  transform: scale(0.666);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  white-space: nowrap;
}

.borderedCouponCard .couponDesc .couponDate.highlighted {
  color: #FE6A00;
}

.cutCouponCard {
  --circle-size: 0.12rem;
  --circle-offset: -0.06rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 100%;
}

.cutCouponCard .body {
  flex-grow: 1;
  margin: 0 calc(var(--circle-size) / 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cutCouponCard .leftSide,
.cutCouponCard .rightSide {
  flex-shrink: 0;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 100%;
}

.cutCouponCard .leftSide {
  position: absolute;
  left: var(--circle-offset);
  -webkit-clip-path: inset(-3.75rem -0.01rem -3.75rem 50%);
  clip-path: inset(-3.75rem -0.01rem -3.75rem 50%);
}

.cutCouponCard .rightSide {
  position: absolute;
  right: var(--circle-offset);
  -webkit-clip-path: inset(-3.75rem 50% -3.75rem -0.01rem);
  clip-path: inset(-3.75rem 50% -3.75rem -0.01rem);
}