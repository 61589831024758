.wrapper {
  position: relative;
  width: 2.75rem;
  min-height: 2.26rem;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.wrapper::before {
  content: '';
  width: 2.75rem;
  height: 1.59rem;
  background: url('https://img.flamingo.shop/p/files/51247f67410444d2a111c0b1d9f17847_720x_3x_0828941e-0de2-4073-898a-57bce24de0ef.jpg?v=1580981701') no-repeat center center;
  background-size: 100%;
}

.wrapper .body {
  padding: 0.18rem 0.22rem 0.16rem 0.22rem;
}

.wrapper .header {
  margin-bottom: 0.2rem;
  font-size: 0.19rem;
  line-height: 0.2rem;
  text-align: center;
}

.wrapper .header h5 {
  display: block;
  margin-bottom: 0.12rem;
}

.wrapper .header .title {
  font-weight: bold;
}

.wrapper .header .content {
  font-size: 0.15rem;
  color: #2D2D2D;
  line-height: 0.23rem;
  margin-left: 0.13rem;
  white-space: nowrap;
}

.wrapper .header .content .item {
  display: flex;
  margin-bottom: 0.02rem;
}

.wrapper .header .content .item .icon {
  width: 0.19rem;
  height: 0.18rem;
  background: url('https://img.flamingo.shop/p/files/Lark20200206-170549.png?v=1580981710') no-repeat center center;
  background-size: 100% 100%;
  margin: 0.03rem 0.08rem 0 0;
}

.wrapper .buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper .buttonGroup .button.highlighted {
  margin-bottom: 0.12rem;
  border: 0;
  color: white;
  font-weight: bold;
  background: #2D2D2D;
}

.wrapper .buttonGroup .button {
  width: 100%;
  height: 0.42rem;
  border-radius: 0.01rem;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: #2D2D2D;
  text-align: center;
}