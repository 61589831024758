.wrapper {
  background: white;
  position: relative;
  display: flex;
  height: 6rem;
  border-radius: 0.18rem;
  overflow: hidden;
}

.wrapper .leftWrapper {
  width: 3.75rem;
}

.wrapper .rightWrapper {
  position: relative;
  width: 3.8rem;
  display: flex;
  flex-direction: column;
}

.wrapper .header {
  display: flex;
  justify-content: space-between;
  font-size: 0.14rem;
  line-height: 0.16rem;
  position: relative;
}

.wrapper .header .closeButton {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.12rem;
}

.wrapper .header .userSection {
  display: flex;
  align-items: center;
}

.wrapper .header .rightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.03rem 0;
}

.wrapper .header .userIcon {
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}

.wrapper .header .userIcon img {
  width: 100%;
}

.wrapper .header .nickname {
  font-size: 0.14rem;
  line-height: 0.2rem;
}

.wrapper .updateTime {
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--gray4);
  font-weight: normal;
}

.wrapper .contentWrapper {
  background: white;
  padding: 0.15rem 0.2rem;
  margin-bottom: 0.08rem;
  border-bottom: 0.01rem solid var(--neutral100);
}

.wrapper .contentWrapper .content {
  margin-top: 0.15rem;
  font-size: 0.14rem;
  line-height: 0.18rem;
  color: var(--neutral900);
  font-weight: normal;
}

.wrapper .linkWrapper {
  padding: 0.15rem 0.2rem;
}

.wrapper .productLink {
  width: 3.21rem;
  margin-bottom: 0.1rem;
  overflow: hidden;
  border: 0.01rem solid var(--neutral500);
}

.wrapper .commentSection {
  background: white;
  padding: 0.05rem 0.2rem 0.82rem 0.2rem;
  margin-bottom: 0.08rem;
}

.wrapper .commentSection .header {
  font-size: 0.18rem;
  line-height: 0.25rem;
  position: relative;
  font-weight: 600;
}

.wrapper .commentSection .commentList >div:not(:last-of-type) {
  border-bottom: 0.01rem solid var(--neutral100);
}

.wrapper .commentList .commentCard {
  padding: 0.1rem 0;
}

.wrapper .commentSection .viewMore {
  text-align: center;
  font-size: 0.12rem;
  line-height: 0.22rem;
  color: var(--neutral900);
  background-color: transparent;
  height: 0.22rem;
}

.wrapper .commentSection .viewMore .arrow {
  font-size: 0.2rem;
  padding-left: 0.03rem;
  vertical-align: -0.04rem;
  color: var(--neutral900);
}

.wrapper .player {
  position: relative;
  width: 100%;
  height: 6rem;
}

.wrapper .player >div {
  width: 100%;
  height: 100%;
}

.wrapper .player >div img {
  object-fit: cover;
}

.wrapper .player.videoPlayer {
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .player.withLikeAnimation::after {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  width: 2.4rem;
  height: 2.4rem;
  background: url('https://media.flamingo.shop/p/files/00.gif') no-repeat center center;
  background-size: 100%;
}

.wrapper .player .views {
  display: flex;
  position: absolute;
  left: 0.16rem;
  bottom: 0.36rem;
  height: 0.18rem;
  text-align: center;
  color: white;
  font-size: 0.13rem;
  line-height: 0.13rem;
  background: rgba(74,74,74,.4);
  border-radius: 0.11rem;
  padding: 0.03rem 0.08rem;
}

.wrapper .player .views::before {
  content: '';
  width: 0.14rem;
  height: 0.08rem;
  background: url('https://img.flamingo.shop/p/files/Lark20200826-030822.png?v=1598425714') no-repeat center center;
  background-size: 100%;
  margin: 0.02rem 0.04rem 0 0;
}

.wrapper .content {
  font-size: 0.13rem;
  line-height: 0.16rem;
}

.wrapper .scrollWrapper {
  flex: 1 1;
  overflow-y: scroll;
}

.wrapper .scrollWrapper::-webkit-scrollbar {
  display: none;
}

.wrapper .recommend {
  margin-bottom: 0.5rem;
}

.wrapper .recommend .title {
  font-size: 0.18rem;
  line-height: 0.25rem;
  font-weight: 600;
  padding: 0.1rem 0.12rem;
  background: white;
}

.wrapper .recommend .list {
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 2.87%);
  padding: 0 0.08rem 0.45rem 0.08rem;
}

.wrapper .interactionBar {
  display: flex;
  margin-top: 0.1rem;
}

.wrapper .share {
  width: 0.2rem;
  height: 0.2rem;
}

.wrapper .share::after {
  content: "\e60c";
  font-size: 0.28rem;
  color: #acacac;
  cursor: pointer;
}

.wrapper .fixedBottomBar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.72rem;
  padding: 0.2rem;
  background: #fff;
  border-top: 0.01rem solid #EEE;
  display: flex;
  justify-content: space-between;
  z-index: var(--z-index-aside);
}

.messageForm {
  background: #F6F6F6;
  border-radius: 0.04rem;
  width: 2.06rem;
  font-size: 0.12rem;
  line-height: 0.32rem;
}

.messageForm input {
  width: 100%;
  padding: 0 0.12rem;
}

.messageForm input::-webkit-input-placeholder {
  color: var(--gray2);
}

.messageForm input:-ms-input-placeholder {
  color: var(--gray2);
}

.messageForm input::placeholder {
  color: var(--gray2);
}

.wrapper .fixedBottomBar .rightButtonWrapper {
  display: flex;
  margin: 0.05rem 0;
}

.wrapper .likeButton,
.wrapper .commentButton {
  font-size: 0.16rem;
  line-height: 0.23rem;
  margin-right: 0.16rem;
}

.wrapper .likeButton::before {
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.04rem;
}

.wrapper .commentButton {
  display: flex;
}

.wrapper .commentButton::before {
  content: '';
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.06rem;
  background: url('./resources/comments.svg') no-repeat left center;
  background-size: contain;
}