.wrapper .backToTopBtn {
  position: fixed;
  right: 0.08rem;
  bottom: calc(var(--fixed-footer-bottom) + 0.2rem);
  z-index: var(--z-index-flow-clickable);
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/BTT.png?v=1599730117') no-repeat center center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.08rem;
}

.wrapper .downAppBtn {
  position: fixed;
  right: 0.08rem;
  bottom: calc(var(--fixed-footer-bottom) + 0.7rem);
  z-index: var(--z-index-flow-clickable);
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/DownLoadAPP_2be88ed4-b4ab-4523-b976-858a3e0b139b.png?v=1599730117') no-repeat center center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.08rem;
}