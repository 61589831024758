.wrapper {
  font-size: 0.12rem;
  line-height: 0.3rem;
  color: rgba(0,0,0,.6);
}

.section:first-of-type {
  color: rgba(0,0,0,.8);
}

.item:hover {
  color: rgba(0,0,0,.8);
}

.section:not(:last-of-type) {
  border-bottom: 0.01rem solid var(--gray);
}