.wrapper {
  margin-left: 0.12rem;
  margin-right: 0.12rem;
}

.wrapper.inDesktop {
  margin: 0;
  border-bottom: unset;
  margin-bottom: 0.36rem;
}

.wrapper:not(.inDesktop) .itemWrapper {
  margin-top: 0.05rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.wrapper.inDesktop .itemWrapper {
  width: 1.88rem;
  margin-top: 0.02rem;
  display: flex;
  flex-wrap: wrap;
}

.wrapper:not(.inDesktop) .itemHeader {
  width: 100%;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.14rem;
  line-height: 0.2rem;
  margin: 0.1rem 0 0.15rem 0;
}

.wrapper.inDesktop .itemHeader {
  width: 100%;
  position: relative;
  height: 0.2rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.itemHeader .title {
  font-weight: 600;
  color: var(--neutral900);
}

.itemHeader .clean {
  position: absolute;
  width: 0.16rem;
  height: 0.16rem;
  right: 0.25rem;
  margin-top: 0.02rem;
  padding-right: 0.05rem;
  background: url('https://img.flamingo.shop/p/files/clean.png?v=1608543730') no-repeat;
  background-size: 100%;
}

.itemHeader .rightWrapper {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.itemHeader .displaySelectedFilter {
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: var(--neutral300);
  margin-right: 0.08rem;
}

.itemHeader .displaySelectedFilter.withCount {
  width: 0.17rem;
  height: 0.17rem;
  border-radius: 50%;
  background-color: var(--neutral300);
  color: white;
  line-height: 0.17rem;
  text-align: center;
  margin: 0.015rem 0.08rem 0.015rem 0;
}

.itemHeader .arrowIcon {
  width: 0.16rem;
  height: 0.16rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: var(--neutral400);
}

.wrapper.inDesktop .itemHeader .arrowIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.16rem;
  height: 0.16rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  text-transform: capitalize;
  color: var(--neutral900);
  text-align: right;
}

.wrapper.inDesktop .filterImageBox {
  flex-basis: 0.2rem;
  margin-right: 0.22rem;
}

.wrapper.inDesktop .filterImageBox:nth-child(5n) {
  margin-right: 0;
}

.filterImageBox .colorImg {
  position: relative;
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.2rem;
  margin-bottom: 0.15rem;
}

.wrapper.inDesktop .filterImageBox .colorImg {
  margin: 0;
  position: relative;
  width: 0.2rem;
  height: 0.2rem;
  margin-top: 0.16rem;
  cursor: pointer;
}

.wrapper:not(.inDesktop) .optionBtn {
  display: flex;
  min-width: 1rem;
  font-size: 0.14rem;
  color: #333333;
  line-height: 0.18rem;
  margin-bottom: 0.16rem;
  cursor: pointer;
  align-items: center;
}

.filterImageBox:nth-last-child(1) .optionBtn {
  margin-bottom: 0;
}

.filterImageBox:nth-last-child(2) .optionBtn {
  margin-bottom: 0;
}

.wrapper .plainItem {
  display: flex;
  font-size: 0.12rem;
  line-height: 0.24rem;
  color: var(--neutral900);
  border: 0.01rem solid var(--gray2);
  border-radius: 0.12rem;
  margin: 0 0.1rem 0.1rem 0;
  padding: 0 0.12rem;
}

.wrapper .plainItem.selected {
  border: 0.01rem solid var(--neutral900);
}

.btnTitle {
  margin-left: 0.12rem;
  font-size: 0.16rem;
  line-height: 0.32rem;
}

.wrapper.inDesktop .btnTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.checkBtn {
  background: url('https://img.flamingo.shop/p/files/uncheck.png?v=1608543730') no-repeat;
  width: 0.24rem;
  height: 0.24rem;
  margin-top: 0.02rem;
  background-size: 100%;
  border-radius: 50%;
  /* border: 1px solid #000000; */
  box-shadow: 0 0 0.01rem 0.01rem #000;
  overflow: hidden;
}

.checkBtn.selected {
  background: url('https://img.flamingo.shop/p/files/checked.png?v=1608543730') no-repeat;
  background-color: #000;
  width: 0.24rem;
  height: 0.24rem;
  margin-top: 0.02rem;
  background-size: 100%;
  border-radius: 50%;
}

.wrapper.inDesktop .sizeBox {
  width: 50%;
}

.wrapper.inDesktop .sizeBox .optionBtn {
  display: flex;
  margin-top: 0.16rem;
  align-items: center;
}

.wrapper.inDesktop .sizeBox .btnTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.wrapper.inDesktop .checkBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.2rem;
  height: 0.2rem;
  cursor: pointer;
  margin: 0;
}

.wrapper.inDesktop .checkBtn.selected {
  background-color: var(--neutral900);
}

.wrapper.inDesktop .checkBtn.selected .pcCheckBtn {
  position: absolute;
  font-size: 0.12rem;
  -webkit-transform: scale(0.67);
  transform: scale(0.67);
  color: #fff;
}