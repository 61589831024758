.sizePicker:not(.pc) {
  background: #F5F5F5;
  border-radius: 0.04rem;
  height: 0.22rem;
  padding-left: 0.06rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-size: 0.12rem;
  font-weight: 400;
  color: var(--neutral900);
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 1.96rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sizePicker:not(.pc) .sizePickerIcon {
  font-weight: bold;
  margin: 0 0.0596rem;
}

.sizePicker:not(.pc) .sizeValue {
  max-width: 1.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0.05rem;
  line-height: 0.22rem;
}

.sizePicker.pc {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #F5F5F5;
  border-radius: 0.04rem;
  height: 0.24rem;
  padding-left: 0.06rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 0.16rem;
  color: var(--neutral900);
  cursor: pointer;
}

.sizePicker.pc .sizePickerIcon {
  font-weight: bold;
  font-size: 0.12rem;
  margin: 0 0.04rem;
}

.sizePicker.pc .sizeValue {
  max-width: 2.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0.04rem;
  line-height: 0.24rem;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: var(--neutral900);
}

.addToCartBtn {
  border-radius: 0;
  background: var(--neutral900);
}

.colorCircle {
  width: 0.14rem;
  height: 0.14rem;
  border-radius: 50%;
  border: 0.01rem solid #FFFFFF;
  margin-right: 0.02rem;
  flex-shrink: 0;
}

.sizePicker .colorPicker {
  width: 0.12rem;
  height: 0.12rem;
}

.sizePicker .colorPicker > div {
  margin: 0 !important;
}

.sizePicker.pc .imageClass {
  margin: 0;
}