.countrySiteWrapper {
  display: flex;
  font-size: 0.14rem;
  line-height: 0.33rem;
}

.countrySiteWrapper .countryIcon {
  width: 0.33rem;
  height: 0.33rem;
  margin-right: 0.04rem;
}

li {
  list-style: none;
}