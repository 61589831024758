.card {
  position: fixed;
  bottom: 0.7rem;
  right: 0.08rem;
  z-index: var(--z-index-aside);
  width: 2.4rem;
  height: 0.44rem;
  background: #E02E3A;
  border-radius: 0.47rem;
  transition: all 0.3s ease;
  padding: 0.01rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card.off {
  width: 0.44rem;
}

.card.off .iconBox {
  width: 0.44rem;
  left: 0.04rem;
  top: 0.06rem;
}

.card.off .iconBox::after {
  content: '';
  display: block;
  width: 0.36rem;
  height: 0.32rem;
}

.card .iconBox {
  position: absolute;
  left: -0.03rem;
  top: 0;
  width: 0.5rem;
  height: 0.44rem;
}

.card .iconBox::after {
  content: '';
  display: block;
  width: inherit;
  height: inherit;
  background-image: url('./resources/un-paid.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.card .title {
  position: absolute;
  left: 0.55rem;
  top: 0.01rem;
  height: 0.42rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.card.off .title {
  opacity: 0;
}

.card .title > span {
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #FFEACD;
  white-space: nowrap;
}

.card .title .cardClassName {
  background-color: white;
  color: var(--neutral900);
  border-radius: 0.04rem;
  height: 0.18rem;
  width: 0.18rem;
  padding: 0.01rem;
  margin-bottom: 0.05rem;
  font-weight: 600;
  font-size: 0.1rem;
}

.card .title .separatorClassName {
  color: white;
  font-size: 0.14rem;
}

.card .countdown {
  margin-top: -0.05rem;
}

.card .closeIcon {
  display: block;
  position: absolute;
  right: 0;
  font-size: 0.12rem;
  color: #FFEACD;
  padding: 0.14rem;
}

.card.off .closeIcon {
  opacity: 0;
  display: none;
}

.card.isDesktop {
  right: 0.09rem;
  bottom: 1.5rem;
  cursor: pointer;
}