.tabContainer {
  position: -webkit-sticky;
  position: sticky;
  z-index: var(--z-index-flow-clickable);
  display: flex;
  justify-content: space-between;
  border-bottom: 0.01rem solid var(--bg-gray);
  top: var(--sticky-header-top);
}

.tabContainer.inApp {
  top: calc(var(--sticky-header-top) + 0.42rem);
}

.tabContainer.inDesktop {
  top: 1.04rem;
}

.tabContainer .rightButton {
  width: 0.48rem;
  height: 0.48rem;
  box-shadow: -0.02rem 0 0.07rem 0.01rem rgba(187,187,187,0.5);
  background: var(--white);
}

.tabContainer .rightButton .arrowDownIcon {
  width: 100%;
  height: 100%;
  background-image: url('./resources/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0.16rem 0.1rem;
}

.tabContainer .rightButton.categoryPanelOpen .arrowDownIcon {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.tabContainer .categoryPanel {
  position: absolute;
  top: 0.47rem;
  z-index: var(--z-index-flow-clickable);
  width: 100%;
  padding: 0.16rem 0.08rem 0 0.08rem;
  display: flex;
  flex-wrap: wrap;
  background: white;
  opacity: 0.95;
  color: #2c2c2c;
  font-size: 0.13rem;
  line-height: 0.15rem;
}

.tabContainer .categoryPanel .category {
  margin: 0 0.16rem 0.16rem 0;
}

.tabContainer .outerScrollTabWrapper {
  flex: 1 1;
  background: var(--white);
  height: 0.48rem;
  overflow: hidden;
}

.tabContainer  .innerScrollTabWrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.tabContainer .tab {
  display: flex;
}

.tabContainer .tab.spread {
  justify-content: space-around;
}

.tabContainer .tab .tabItem {
  display: block;
  padding: 0 0.08rem;
  font-size: 0.13rem;
  text-align: center;
  white-space: nowrap;
  line-height: 0.48rem;
  color: #999;
}

.tabContainer .tab .tabItem.selected {
  /* color: var(--primary); */
  color: var(--neutral900);
  font-weight: bold;
  position: relative;
}

.tabContainer .tab .tabItem.selected::after {
  content: ' ';
  position: absolute;
  bottom: 0.06rem;
  left: 10%;
  width: 80%;
  height: 0.03rem;
  background-color: var(--neutral900);
}