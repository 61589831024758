.navigation {
  position: relative;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 0.01rem solid var(--gray);
}

.navigation::-webkit-scrollbar {
  display: none;
}

.navigation.withNaviDetailOpen .naviWrapper {
  display: none;
}

.search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: var(--z-index-layer-1);
  background: white;
  padding: 0.18rem 0 0.06rem 0;
  margin: 0 0.2rem;
  display: flex;
  font-size: 0.15rem;
  line-height: 0.24rem;
  border-bottom: 0.01rem solid #67605F;
}

.search::before {
  content: "\e60b";
  font-size: 0.18rem;
  width: 0.24rem;
  height: 0.24rem;
  margin-right: 0.08rem;
}

.naviItems {
  padding: 0.1rem 0.2rem 1.1rem 0.2rem;
  margin-bottom: 0.56rem;
}

.naviLineItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.16rem 0;
}

.naviLineItem > span {
  flex: 1 1;
  margin-left: 0.12rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.naviLineItem::after {
  content: "\e612";
  font-size: 0.1rem;
}

.naviLineItem .naviLineItemImage {
  width: 0.44rem;
  height: 0.44rem;
  border: 0.01rem solid #E6E6E6;
  border-radius: 50%;
}

.items li {
  padding: 0.08rem 0;
  line-height: 0.3rem;
}

.items:first-of-type {
  margin-top: 0.08rem;
  border-top: 0.01rem solid var(--gray);
}

.naviItem {
  position: relative;
}

.naviItem::after {
  content: "\e612";
  font-size: 0.1rem;
  position: absolute;
  right: 0;
  padding: 0.08rem 0;
  line-height: 0.3rem;
  margin: auto;
}

.isExpanded .expander::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.naviItem {
  display: flex;
}

.naviItem .icon {
  width: 0.42rem;
  height: 0.42rem;
  margin-right: 0.12rem;
  border-radius: 50%;
  border: 0.01rem solid var(--gray);
}

.naviItem label {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  color: var(--neutral900);
  line-height: 0.42rem;
}

.downloadAppSection {
  padding: 0.1rem 0;
  border-top: 0.01rem solid var(--gray);
}

.downloadAppSection .image {
  margin-top: 0.1rem;
  width: 0.9rem;
}

.naviFooterBar {
  position: fixed;
  left: 0;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: space-around;
  width: 2.7rem;
  border-top: 0.01rem solid var(--bg-gray2);
  border-right: 0.01rem solid var(--gray);
  padding: 0.08rem;
  padding-bottom: calc(var(--fixed-footer-bottom) + 0.08rem);
}

@supports (padding: max(0px)) {
  .naviFooterBar {
    padding-bottom: max(var(--fixed-footer-bottom), 8px);
  }
}

.footerBarItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerBarItem .footerBarIcon {
  font-size: 0.25rem;
}

.footerBarItem .footerBarText {
  font-size: 0.1rem;
  line-height: 0.12rem;
}

.naviDetail {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  z-index: 1;
  background: var(--white);
  overflow-y: scroll;
  border-left: 0.01rem solid var(--gray);
}

.naviDetail.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.naviDetail .header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: var(--z-index-layer-1);
  width: 100%;
  height: 0.44rem;
  line-height: 0.44rem;
  text-align: center;
  background: white;
  border-bottom: 0.01rem solid var(--gray);
  font-weight: bold;
}

.naviDetail .header::before {
  position: absolute;
  left: 0.2rem;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  content: " ";
  height: 0.08rem;
  width: 0.08rem;
  border-width: 0 0.01rem 0.01rem 0;
  border-style: solid;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.naviDetail .content {
  padding-top: 0.08rem;
  margin-left: 0.12rem;
  margin-right: 0.12rem;
  background-color: white;
  padding-bottom: 1.4rem;
}

.countryIcon {
  width: 0.42rem;
  height: 0.42rem;
  padding: 0.05rem;
  margin-right: 0.12rem;
  border-radius: 50%;
  border: 0.01rem solid var(--gray);
}

.liStyle {
  position: relative;
}

.liStyle::after {
  content: "\e612";
  font-size: 0.1rem;
  position: absolute;
  right: 0;
  padding: 0.08rem 0;
  line-height: 0.3rem;
  margin: auto;
}

.liStyle label {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}