.wrapper {
  position: relative;
  width: 100%;
  background: white;
  box-shadow: 0 0 0.05rem rgb(0 0 0 / 35%);
  z-index: calc(var(--z-index-flow-clickable) + 1);
}

.wrapper .content {
  display: flex;
  align-items: center;
  height: 100%;
}

.wrapper .close {
  margin-left: 0.12rem;
  margin-right: 0.08rem;
  min-width: 0.08rem;
  min-height: 0.08rem;
  width: 0.08rem;
  height: 0.08rem;
  background-image: url('./resources/close.svg');
  background-position: center center;
  background-repeat: no-repeat;
}

.wrapper .icon {
  min-width: 0.62rem;
  width: 0.62rem;
  margin-right: 0.13rem;
}

.wrapper .details {
  padding: 0.08rem 0;
  line-height: 0.16rem;
  margin-right: 0.05rem;
}

.wrapper .title {
  font-weight: 600;
  color: #333333;
  padding-bottom: 0.01rem;
  font-size: 0.14rem;
}

.wrapper .description {
  color: #333333;
  font-size: 0.1rem;
  line-height: 0.14rem;
}

.wrapper .reviewLine {
  display: flex;
  align-items: center;
}

.wrapper .stars {
  display: inline-block;
}

.wrapper .reviews {
  display: inline-block;
  margin-left: 0.04rem;
  font-size: 0.1rem;
  color: #333;
}

.wrapper .btn {
  display: inline-block;
  margin-right: 0.12rem;
  padding: 0.06rem 0.14rem;
  line-height: 0.16rem;
  font-size: 0.14rem;
  font-weight: 600;
  text-align: center;
  border: 0.01rem solid var(--neutral900);
  border-radius: 0.03rem;
  margin-left: auto;
  color: var(--neutral900);
}