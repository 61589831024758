.clock,
.text {
  display: inline-block;
}

.spanWrapper {
  display: inline-block;
}

.clock .time {
  width: 0.19rem;
  height: 0.19rem;
  position: relative;
  background-color: white;
  color: var(--neutral900);
  font-size: 0.12rem;
  line-height: 0.16rem;
  margin-right: 0.04rem;
  border-radius: 0.03rem;
  text-align: center;
  /* width: 21px; */
  display: inline-block;
  padding: 0.02rem;
}

.clock .separator {
  margin-right: 0.04rem;
  line-height: 0.21rem;
}