.wrapper:not(.isDesktop) {
  /* height: 243px; */
  background-color: #fff;
}

.wrapper:not(.isDesktop) .title {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-top: 0.09rem;
  padding: 0.09rem 0.12rem 0.07rem;
}

.wrapper:not(.isDesktop) .title > span {
  flex-shrink: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  background-color: #fff;
}

.wrapper:not(.isDesktop) .subTitle {
  margin-left: 0.2rem;
  display: flex;
  overflow-x: scroll;
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #999999;
  white-space: nowrap;
}

.wrapper:not(.isDesktop) .subTitle::-webkit-scrollbar {
  display: none;
}

.wrapper:not(.isDesktop) .subItem {
  margin-right: 0.12rem;
}

.wrapper:not(.isDesktop) .subItem.active {
  color: var(--neutral900);
}

.wrapper:not(.isDesktop) .list {
  width: 3.75rem;
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
  overflow-x: scroll;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 0.12rem;
  overflow-y: hidden;
}

.wrapper:not(.isDesktop) .list .mainImage {
  flex-basis: 1.3rem;
  height: 1.95rem;
  flex-shrink: 0;
  margin-right: 0.08rem;
}

.wrapper:not(.isDesktop) .list::-webkit-scrollbar {
  display: none;
}

.wrapper:not(.isDesktop) .list .imageBox {
  width: 0.96rem;
  position: relative;
  cursor: pointer;
  margin-right: 0.08rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 0.12rem;
}

.wrapper:not(.isDesktop) .list .imageBox .image {
  width: 0.96rem !important;
  outline: none;
}

.wrapper:not(.isDesktop) .list .imageBox >div {
  border-radius: 0;
}

.addIcon {
  position: absolute;
  top: 1.06rem;
  right: 0.08rem;
}

.wrapper.isDesktop {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.wrapper.isDesktop .title {
  display: flex;
}

.wrapper.isDesktop .title > span {
  flex-shrink: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 0.26rem;
  line-height: 0.36rem;
  color: var(--neutral900);
}

.wrapper.isDesktop .subTitle {
  margin-left: 0.3rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.22rem;
  line-height: 0.36rem;
  text-transform: capitalize;
  color: #999999;
  display: flex;
  flex-wrap: wrap;
}

.wrapper.isDesktop .subTitle .subItem {
  margin-right: 0.3rem;
}

.wrapper.isDesktop .subTitle .subItem.active {
  color: var(--neutral900);
}

.wrapper.isDesktop .sliderBox {
  position: relative;
}

.wrapper.isDesktop .list {
  width: auto;
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
  overflow-x: scroll;
  padding-top: 0.25rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 0.65rem;
  position: relative;
}

.wrapper.isDesktop .list::-webkit-scrollbar {
  display: none;
}

.wrapper.isDesktop .list .mainImage {
  flex-basis: 2.9rem;
  height: 4.35rem;
  flex-shrink: 0;
  margin-right: 0.12rem;
}

.wrapper.isDesktop .list .imageBox {
  width: 2.5rem;
  position: relative;
  cursor: pointer;
  margin-right: 0.12rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 0.14rem;
}

.wrapper.isDesktop .list .imageBox .image {
  width: 2.5rem;
}

@media (max-width: 1366px) {
  .wrapper.isDesktop .list .mainImage {
    flex-basis: 24vw;
    height: 36vw;
    flex-shrink: 0;
  }

  .wrapper.isDesktop .list .imageBox {
    width: 19vw;
    position: relative;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0.14rem;
  }

  .wrapper.isDesktop .list .imageBox .image {
    width: 19vw;
  }
}

@media (min-width: 1074px) and  (max-width: 1366px) {
  .wrapper.isDesktop .list .mainImage {
    flex-basis: 2.378rem;
    height: 3.567rem;
    flex-shrink: 0;
    margin-right: 0.1rem;
  }

  .wrapper.isDesktop .list .imageBox {
    width: 2.05rem;
    position: relative;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0.12rem;
    margin-right: 0.1rem;
  }

  .wrapper.isDesktop .list .imageBox .image {
    width: 2.05rem;
  }
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .wrapper.isDesktop .list .mainImage {
    flex-basis: 2.64rem;
    height: 3.96rem;
    flex-shrink: 0;
  }

  .wrapper.isDesktop .list .imageBox {
    width: 2.275rem;
    position: relative;
    cursor: pointer;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0.127rem;
  }

  .wrapper.isDesktop .list .imageBox .image {
    width: 2.275rem;
  }
}

.wrapper.isDesktop .sliderBox .pcSwiperButton {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background: url(https://img.flamingo.shop/p/files/Right_3x_239079a9-8e50-42ed-87a3-fed6392eed9e.png?v=1599214611) no-repeat 50%;
  background-size: 100%;
  border-radius: 100%;
  z-index: var(--z-index-layer-1);
  opacity: 0.7;
  cursor: pointer;
}

.wrapper.isDesktop .sliderBox .pcLeftButton {
  left: 0.2rem;
  top: 34.7%;
  padding-left: 0.05rem;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wrapper.isDesktop .sliderBox .pcRightButton {
  right: 0.2rem;
  top: 34.7%;
  padding-right: 0.05rem;
}

.wrapper.isDesktop .sliderBox .pcLeftButton:hover,
.wrapper.isDesktop .sliderBox .pcRightButton:hover {
  background-color: #fff;
  opacity: 1;
  color: var(--neutral900);
}

.testDiv {
  background-color: gray;
  height: 3rem;
}

.testDiv > div:nth-child(1) {
  width: 3.75rem;
  height: 3rem;
  background-color: pink;
}

.testDiv > div:nth-child(2) {
  width: 3.75rem;
  height: 3rem;
  background-color: pink;
}