.wrapper:not(.isDesktop) {
  border-top: 0.08rem solid var(--neutral50);
  padding: 0.1rem 0.12rem;
}

.wrapper.noBorder {
  border: unset;
}

.wrapper:not(.isDesktop) .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  font-weight: 500;
  line-height: 0.2rem;
}

.wrapper:not(.isDesktop) .header .unsaleIcon {
  margin-left: 0.06rem;
  font-size: 0.14rem;
  display: inline-block;
}

.wrapper:not(.isDesktop) .header .unsaleIcon.active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wrapper:not(.isDesktop) .header .clearBtn {
  text-decoration: underline;
}

.wrapper.isDesktop {
  margin-top: 0.3rem;
  background-color: var(--white);
  padding: 0.3rem;
}

.wrapper.isDesktop .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  font-weight: 500;
  line-height: 0.2rem;
}

.wrapper.isDesktop .header .unsaleIcon {
  margin-left: 0.06rem;
  font-size: 0.14rem;
  display: inline-block;
}

.wrapper.isDesktop .header .unsaleIcon.active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wrapper.isDesktop .header .clearBtn {
  text-decoration: underline;
}