.header {
  width: 100%;
  position: relative;
}

.header .panelImage {
  width: 100%;
}

.header .countDown {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-right: 0.08rem;
}

.header .countDown > span {
  margin-right: 0.12rem;
}

.header .countDown.inDesktop {
  font-size: 0.36rem;
  margin-right: 0.21rem;
}

.header .countDown.inDesktop .counts span {
  font-size: 0.36rem;
  width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  margin-right: 0.12rem;
}

.body.inDesktop {
  padding-bottom: 0.4rem;
}

.body .productSection {
  margin: 0;
  padding: 0.07rem;
}