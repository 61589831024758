.entranceIcon {
  position: relative;
  width: 0.35rem;
  height: 0.35rem;
  /* margin: 0 4px 0 0; */
}

.entranceIcon::before {
  content: "\e61c";
  position: relative;
  font-size: 0.35rem;
  width: 0.35rem;
  height: 0.35rem;
  color: black;
}

.count {
  display: block;
  position: absolute;
  top: -0.04rem;
  right: 0.1rem;
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 50%;
  font-size: 0.1rem;
  line-height: 0.15rem;
  color: white;
  text-align: center;
  background: var(--primary);
}