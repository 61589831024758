.wrapper .backToTopBtn {
  position: fixed;
  right: 0.08rem;
  bottom: calc(var(--fixed-footer-bottom) + 1.45rem);
  z-index: var(--z-index-flow-clickable);
  background: #FFFFFF;
  border: 0.005rem solid #CCCCCC;
  border-radius: 50%;
  width: 0.36rem;
  height: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.3rem;
}