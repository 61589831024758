.wrapper.mobile {
  border-top: 0.08rem solid var(--bg-common);
  padding: 0.12rem 0.12rem 0.01rem;
}

.weAccept {
  font-weight: bold;
  font-size: 0.2rem;
  color: var(--neutral900);
  margin-bottom: 0.16rem;
}

.wrapper.isDesktop .weAccept {
  margin-bottom: 0.16rem;
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: var(--neutral900);
}

.mobile .weAccept {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #1A1919;
}

.imageBox {
  display: flex;
  white-space: normal;
  margin-top: 0.14rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.wrapper.isDesktop .imageBox {
  margin-top: 0.08rem;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.mobile .imageBox {
  margin: 0 0 0.07rem 0;
}

.title {
  font-size: 0.16rem;
  display: flex;
  align-items: center;
}

.wrapper.isDesktop .title {
  height: 0.26rem;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #4A4A4A;
}

.mobile .title {
  margin: 0.1rem 0 0.04rem 0;
  font-size: 0.12rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.mobile .title > span {
  font-size: 0.15rem;
  color: #3dcc32;
  margin-right: 0.04rem;
}

.title .payIcon {
  width: 0.1rem;
  height: 0.12rem;
  background-image: url('https://img.flamingo.shop/p/18c0de9defd8414ea5ee30a331e6a36e.png');
  background-size: 100%;
  display: inline-block;
  margin-right: 0.06rem;
}

.wrapper.isDesktop .title .payIcon {
  width: 0.2rem;
  height: 0.24rem;
  margin-right: 0.1rem;
}

.title .verification {
  display: inline-block;
  height: 0.2rem;
}

.wrapper.isDesktop .title .verification {
  height: 0.26rem;
}

.title .verification::before {
  display: inline-block;
  content: '';
  width: 0.522rem;
  height: 0.2rem;
  background: url('https://img.flamingo.shop/p/files/6cac305a589d7cbb3bd986fc2df7a71e_2e47b4fd-c7ce-40d4-84ba-0a1760d643d8.png?v=1641804255') no-repeat center center;
  background-size: 100%;
  margin: 0 0.0998rem 0 0.06rem;
}

.wrapper.isDesktop .title .verification::before {
  height: 0.2rem;
  width: 0.55rem;
  margin: 0 0.14rem 0 0.08rem;
}

.title .verification::after {
  display: inline-block;
  content: '';
  width: 0.2237rem;
  height: 0.2rem;
  background: url('https://img.flamingo.shop/p/files/1_e9488063-35ae-4401-88f0-fb0c785b927e.png?v=1641804255') no-repeat center center;
  background-size: 100%;
}

.wrapper.isDesktop .title .verification::after {
  width: 0.293rem;
  height: 0.26rem;
}

.wrapper:not(.isDesktop) .imageBox .card {
  flex-basis: 0.35rem;
  height: 0.3rem;
  margin: 0 0.06rem 0.06rem 0;
  background-size: contain;
}

.wrapper.isDesktop .imageBox .card {
  flex-basis: 0.3864rem;
  height: 0.2267rem;
  margin: 0 0.06rem 0.06rem 0;
  background-size: contain;
}

.imageBox .card.paypal {
  background: url("https://img.flamingo.shop/p/show/ed58246c2d594d63ae67639262f4a89e.png") no-repeat
    center center;
}

.imageBox .card.visa {
  background: url("https://img.flamingo.shop/p/show/eb25c5cf30ec40cf9d2309f68c0e6924.png") no-repeat
    center center;
}

.imageBox .card.mastercard {
  background: url("https://img.flamingo.shop/p/show/02ffff1c605c492da8b3c06e031020ac.png") no-repeat
    center center;
}

.imageBox .card.maestro {
  background: url("https://img.flamingo.shop/p/show/5cd7b07f2c5a4755b786e000db4a961d.png") no-repeat
    center center;
}

.imageBox .card.ae {
  background: url("https://img.flamingo.shop/p/show/81e2868b30a14e83a40b84bfd8d69dc3.png") no-repeat
    center center;
}

.imageBox .card.discover {
  background: url("https://img.flamingo.shop/p/show/c2b4a98d732041e78f8b97585330b5ef.png") no-repeat
    center center;
}

.imageBox .card.dc {
  background: url("https://img.flamingo.shop/p/show/a44b5d976fec4948b465302f9b8f1083.png") no-repeat
    center center;
}

.imageBox .card.jcb {
  background: url("https://img.flamingo.shop/p/show/37b6138e608e4b5d9260819b77eae3d0.png") no-repeat
    center center;
}

.imageBox .card.klarna {
  background: url("https://img.flamingo.shop/p/show/b587b5f5638048ce8c4477fdaa9190b1.png") no-repeat
    center center;
}

.imageBox .card.google {
  background: url("https://img.flamingo.shop/p/show/d2d83720d34a4e578e995d33ccc15429.png") no-repeat
    center center;
}

.imageBox .card.apple {
  background: url("https://img.flamingo.shop/p/show/8a600eb05dea424bb3b0be8ee783c76a.png") no-repeat
    center center;
}

.imageBox .card.afterpay {
  background: url("https://img.flamingo.shop/p/show/850ce08d6ade406f89d83dca95d6e1ed.png") no-repeat
    center center;
}

.imageBox .card.cb {
  background: url("https://img.flamingo.shop/p/show/982a957740af425190aa9b987acd7b73.png") no-repeat
    center center;
}