.childItem {
  display: flex;
  justify-content: space-between;
  padding-right: 0.04rem;
}

.childItem.mobileLink::after {
  content: "\e612";
  line-height: 0.46rem;
  font-size: 0.1rem;
}

.childItem.mobileLink .itemIcon {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.12rem;
}

.levelTwoTitle {
  line-height: 0.42rem;
  font-size: 0.12rem;
  text-transform: uppercase;
  color: #4a4a4a;
  display: flex;
  justify-content: space-between;
  padding-right: 0.04rem;
}

.levelTwoTitle.mobileLink::after {
  content: "\e612";
  font-size: 0.1rem;
}

.levelTwoTitle.inDesktop {
  font-size: 0.14rem;
  line-height: normal;
  padding-bottom: 0.15rem;
  font-style: inherit;
  font-weight: 500;
  padding-left: 0;
  border-bottom: none;
  background-color: transparent;
  color: var(--neutral900);
  text-transform: uppercase;
}

.levelTwoTitle.highlight {
  color: var(--primary);
}

.childTitle {
  width: 100%;
  float: left;
  font-size: 0.12rem;
  line-height: 0.46rem;
  padding-left: 0.12rem;
  background-color: white;
  color: #4a4a4a;
  display: flex;
  align-items: center;
}

.childTitle.inDesktop {
  width: auto;
  float: left;
  font-style: inherit;
  font-size: 0.12rem;
  line-height: normal;
  padding-bottom: 0.08rem;
  padding-left: 0;
}

.childTitle.highlight {
  color: var(--primary);
}

.wrapper.inDesktop .childTitle {
  border: 0;
  min-width: 1.5rem;
}

.imgItems {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 0.63rem);
  grid-row-gap: 0.28rem;
  row-gap: 0.28rem;
  grid-column-gap: 0.18rem;
  -webkit-column-gap: 0.18rem;
  column-gap: 0.18rem;
  margin: 0.11rem 0.06rem;
}

.imgItem img {
  width: 0.63rem;
  height: 0.63rem;
}

.imgItem span {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  color: var(--neutral900);
  display: flex;
  justify-content: center;
  margin-top: 0.04rem;
}