.card {
  display: inline-block;
  width: 1.78rem;
  margin-bottom: 0.07rem;
  border-radius: 0.06rem;
  background-color: var(--white);
}

.card.inDesktop {
  width: 100%;
  margin-bottom: 2.282rem;
  border-radius: 0;
}

.card .image {
  width: 1.78rem;
  height: 2.67rem;
  border-radius: 0.04rem 0.04rem 0 0;
}

.card.inDesktop .image {
  width: 100%;
  padding-bottom: 133.3%;
  border-radius: 0;
}

.info {
  padding: 0.04rem 0.04rem 0.06rem 0.04rem;
  display: block;
}

.flexSection {
  display: flex;
  padding: 0.04rem 0;
}

.flexSection >* {
  margin-right: 0.04rem;
}