.couponWrapper {
  flex-shrink: 0;
}

.couponWrapper.large {
  width: 2.97rem;
  height: 1.1rem;
  background: url('https://img.flamingo.shop/p/show/2e621b0d78e8419582ce5fa566695122.png') no-repeat center center;
  background-size: cover;
  margin-bottom: 0.1rem;
  padding-top: 0.3rem;
  padding-left: 0.22rem;
}

.couponWrapper.medium {
  width: 1.69rem;
  height: 0.96rem;
  background: url('https://img.flamingo.shop/p/show/50fe96fbb7d34c90b14672e161f53dc9.png') no-repeat center center;
  background-size: cover;
  padding-top: 0.24rem;
  padding-left: 0.18rem;
  margin-right: 0.04rem;
}

.couponWrapper.small {
  width: 1.8rem;
  height: 0.36rem;
  background: url('https://img.flamingo.shop/p/show/37ae4cab7a8048b08655219ab827d41c.png') no-repeat center center;
  background-size: 100% 100%;
  padding-top: 0.06rem;
  padding-left: 0.1rem;
  margin-right: 0.05rem;
  display: flex;
}

.couponWrapper .title {
  color: #FF2132;
  font-weight: 700;
}

.couponWrapper.large .title {
  font-size: 0.28rem;
  line-height: 0.28rem;
}

.couponWrapper.medium .title {
  font-size: 0.2rem;
  line-height: 0.2rem;
}

.couponWrapper.small .title {
  font-size: 0.08rem;
  flex-shrink: 0;
}

.couponWrapper .title h2 {
  display: inline-block;
}

.couponWrapper.large .title h2 {
  font-size: 0.56rem;
}

.couponWrapper.medium .title h2 {
  font-size: 0.4rem;
}

.couponWrapper.small .title h2 {
  font-size: 0.2rem;
}

.couponWrapper .subTitle {
  color: #F85348;
  font-weight: 500;
}

.couponWrapper.large .subTitle {
  font-size: 0.12rem;
}

.couponWrapper.medium .subTitle {
  font-size: 0.12rem;
}

.couponWrapper.small .subTitle {
  font-size: 0.12rem;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  flex-shrink: 0;
  padding-left: 0.3rem;
  color: #E18D19;
}