.countdownRow {
  display: flex;
  justify-content: center;
  padding: 0.06rem;
  background: inherit;
}

.countdownRow .text {
  font-size: 0.11rem;
  line-height: 0.3rem;
  margin-right: 0.1rem;
}

.countdownRow .countdown span {
  font-size: 0.15rem;
  line-height: 0.3rem;
  width: 0.3rem;
  font-weight: bold;
  border-radius: 0.04rem;
}