.wrapper {
  position: fixed;
  bottom: 0;
  width: 3.75rem;
  padding: 0.08rem;
  padding-bottom: calc(var(--fixed-footer-bottom) + 0.08rem);
  background: #fff;
  border-top: 0.01rem solid var(--bg-gray2);
  display: flex;
  justify-content: space-around;
  z-index: var(--z-index-aside);
}

@supports (padding: max(0px)) {
  .wrapper {
    padding-bottom: max(var(--fixed-footer-bottom), 8px);
  }
}

.imageWrapper {
  position: relative;
  text-align: center;
}

.imageWrapper .count {
  display: block;
  position: absolute;
  top: -0.02rem;
  right: 0.1rem;
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 50%;
  font-size: 0.1rem;
  line-height: 0.15rem;
  color: white;
  text-align: center;
  background: var(--primary);
}

.imageWrapper .redPoint {
  position: absolute;
  top: 0;
  right: 0;
  background: #FF3B30;
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
}

.selected {
  color: #FE8D8C;
}

.icon {
  display: block;
  font-size: 0.26rem;
}

.title {
  font-size: 0.09rem;
  line-height: 0.13rem;
}