.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 3.75rem;
  height: calc(0.64rem + var(--fixed-footer-bottom));
  box-shadow: 0 -0.02rem 0.06rem rgba(102, 102, 102, 0.05);
  background-color: #fff;
}

.wrapper .fixedHeader {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateY(calc(-100% + 0.01rem));
  transform: translateY(calc(-100% + 0.01rem));
}

.wrapper .cartIcon {
  width: 0.35rem;
  height: 0.35rem;
  line-height: 0.35rem;
  color: #000;
  position: absolute;
  top: 0.125rem;
  left: 0.07rem;
}

.wrapper .cartIcon > span {
  font-size: 0.38rem;
}

.wrapper .cartIcon .num {
  position: absolute;
  top: 0;
  left: 0.2rem;
  min-width: 0.15rem;
  height: 0.15rem;
  padding: 0 0.0375rem;
  box-sizing: border-box;
  font-size: 0.1rem;
  display: block;
  border-radius: 0.15rem;
  text-align: center;
  line-height: 0.15rem;
  background: var(--neutral900);
  color: var(--white);
  font-weight: bold;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.wrapper .cartCounts > span {
  display: block;
  -webkit-transform: scale(0.83);
  transform: scale(0.83);
  text-align: right;
}

.wrapper .counts {
  display: flex;
  flex-direction: column;
  justify-content: right;
  cursor: pointer;
}

.wrapper .counts .price {
  font-style: normal;
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.26rem;
  color: var(--neutral900);
  display: flex;
  justify-content: flex-end;
  white-space: pre-wrap;
}

.wrapper .counts .sizePickerIcon {
  margin-left: 0.06rem;
  color: var(--neutral900);
  display: inline-block;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.wrapper .dataRow .counts .sizePickerIcon.active {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.wrapper .counts .saveFee {
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #D20000;
  text-align: right;
}

.wrapper .bagBtn {
  width: 3.51rem;
  height: 0.4rem;
  border-radius: 0;
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--neutral900);
  background-color: #fff;
  box-sizing: border-box;
  border: 0.01rem solid var(--neutral900);
  margin: 0.1rem auto;
}

.wrapper .checkout {
  width: 3.51rem;
  height: 0.4rem;
  border-radius: 0;
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 auto;
}

.wrapper .dataRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 0.2rem 0 0.2rem;
}

.wrapper .dataRow .selectAll {
  display: flex;
  align-items: center;
}

.wrapper .dataRow .selectAll span {
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.18rem;
  color: var(--neutral900);
  margin-left: 0.06rem;
}

.selectIcon {
  cursor: pointer;
  width: 0.16rem;
  height: 0.16rem;
}