.wrapperContainer {
  border-top: 0.08rem solid var(--neutral50);
  border-bottom: 0.08rem solid var(--neutral50);
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: calc(var(--sticky-header-top) + 0.04rem);
  width: 100%;
  background-color: #fff;
  z-index: var(--z-index-notification);
}

.wrapper {
  display: flex;
  width: 100%;
}

.wrapper > div .title {
  margin-bottom: 0.03rem;
}

.wrapper > div .title span {
  font-size: 0.2rem;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wrapper .shipping {
  flex: 1 1;
  padding: 0.08rem 0.1rem 0.06rem;
}

.wrapper .shipping.flexible {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.1rem 0.12rem;
  height: 0.5rem;
}

.wrapper .shipping.flexible .title {
  margin: 0;
  position: relative;
  padding-left: 0.25rem;
  min-height: 0.24rem;
  width: 1.9rem;
  position: relative;
}

.wrapper .shipping.flexible .title::before {
  content: "\e666";
  position: absolute;
  top: 0.02rem;
  left: 0;
  width: 0.2rem;
  height: 0.2rem;
  line-height: 0.2rem;
  font-size: 0.2rem;
}

.wrapper .shipping.flexible.clickable .title {
  width: 1.2rem;
}

.wrapper .shipping.flexible .addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.5rem;
  height: 0.24rem;
  box-sizing: border-box;
  border: 0.01rem solid #000000;
  border-radius: 0.2rem;
  font-weight: 600;
  font-size: 0.12rem;
  color: var(--neutral900);
}

.wrapper .shipping.flexible .progressWrapper {
  width: 1.61rem;
  height: 0.2rem;
}

.wrapper .shipping.flexible .vertical {
  margin: 0;
}

.wrapper .shipping.flexible .verticalProgressChildren {
  height: 0.2rem;
  line-height: 0.2rem;
}

.wrapper .shipping.flexible .verticalProgressText {
  color: #fff;
}

.wrapper .desc {
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #333333;
}

.wrapper .shipping .desc {
  white-space: nowrap;
}

.wrapper .shipping.flexible .desc {
  line-height: 0.16rem;
  font-size: 0.14rem;
  font-weight: 500;
  color: var(--neutral900);
  white-space: inherit;
}

.wrapper .desc span {
  color: #D20000;
  font-weight: 600;
}

.wrapper .desc.green {
  color: #17B35A;
}

.wrapper .shipping .desc .icon {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.16rem;
  color: #333333;
  -webkit-transform: rotate(-90deg) translateX(-0.02rem);
  transform: rotate(-90deg) translateX(-0.02rem);
  font-weight: bold;
  margin-left: 0.02rem;
}

.wrapper .coupon {
  flex-basis: 1.87rem;
  padding-left: 0.12rem;
  position: relative;
  padding: 0.08rem 0.12rem 0.06rem;
}

.wrapper .coupon .title::before {
  content: " ";
  display: inline-block;
  width: 0.18rem;
  height: 0.18rem;
  background: url('./resources/double-coupon.svg') no-repeat;
  margin-right: 0.06rem;
}

.wrapper .coupon::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0.01rem;
  height: 0.3rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #E6E6E6;
}

.wrapper > div .title {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.16rem;
  color: var(--neutral900);
  display: flex;
  align-items: center;
}