.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: var(--gray) url('../../common/Image/resources/defaultImage.svg') no-repeat center center;
}

.wrapper video {
  width: 100%;
  height: 100%;
}

.wrapper .cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.4);
}

.wrapper.inDesktop .paused {
  width: 1rem;
  height: 1rem;
}

.wrapper .paused {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0.6rem;
  height: 0.6rem;
  background: url('https://img.flamingo.shop/p/show/579b4950583e4cc7b5664c60e071b813.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper.inDesktop .voice {
  width: 0.4rem;
  height: 0.4rem;
  left: 0.25rem;
  bottom: 0.25rem;
}

.wrapper .voice {
  left: 0.15rem;
  bottom: 0.09rem;
  position: absolute;
  width: 0.2rem;
  height: 0.2rem;
  background: url('https://img.flamingo.shop/p/show/fc27c10042ae4e7d9726efe2cf3190e3.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .voice.muted {
  background: url('https://img.flamingo.shop/p/show/dab81a69f7f44b959aa2257f0082fa5f.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.04rem;
  overflow: hidden;
}

.wrapper .progress .outerBar {
  background: rgba(255,255, 255, 0.3);
  border-radius: 0;
}

.wrapper .progress .innerBar {
  background: white;
  border-radius: 0;
}

.wrapper .loadingBox {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}