.wrapper {
  display: flex;
}

.wrapper.up {
  flex-direction: column-reverse;
}

.wrapper.down {
  flex-direction: column;
}

.wrapper.left {
  flex-direction: row-reverse;
}

.wrapper.right {
  flex-direction: row;
}

.wrapper .content {
  background: rgba(36, 36, 36, 0.92);
}

.wrapper.white .content {
  background: #FFFFFF;
}

.wrapper.gray .content {
  background: #F6F6F6;
}

.rect {
  background: transparent;
  display: flex;
}

.wrapper.up .rect {
  justify-content: flex-end;
  margin-right: 0.2rem;
  height: 0.08rem;
}

.wrapper.down .rect {
  justify-content: flex-start;
  margin-left: 0.2rem;
  height: 0.08rem;
}

.wrapper.left .rect {
  align-items: flex-end;
  margin-bottom: 0.2rem;
}

.wrapper.right .rect {
  align-items: flex-start;
  margin-top: 0.2rem;
}

.triangle {
  width: 0;
  height: 0;
  border: 0.08rem solid transparent;
}

.triangle.up {
  border-bottom: 0.08rem solid #242424;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.triangle.down {
  border-top: 0.08rem solid #242424;
}

.triangle.left {
  border-right: 0.08rem solid #242424;
}

.triangle.right {
  border-left: 0.08rem solid #242424;
}

.wrapper.white .triangle.up {
  border-bottom: 0.08rem solid #FFFFFF;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.wrapper.white  .triangle.down {
  border-top: 0.08rem solid #FFFFFF;
}

.wrapper.white  .triangle.left {
  border-right: 0.08rem solid #FFFFFF;
}

.wrapper.white  .triangle.right {
  border-left: 0.08rem solid #FFFFFF;
}

.wrapper.gray .triangle.up {
  border-bottom: 0.08rem solid #F6F6F6;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.wrapper.gray  .triangle.down {
  border-top: 0.08rem solid #F6F6F6;
}

.wrapper.gray  .triangle.left {
  border-right: 0.08rem solid #F6F6F6;
}

.wrapper.gray  .triangle.right {
  border-left: 0.08rem solid #F6F6F6;
}

.hand {
  background-image: url('./resources/hand.svg');
  background-size: contain;
  width: 0.45rem;
  height: 0.45rem;
}