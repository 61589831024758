.wrapper {
  width: 3.75rem;
  padding: 0 0.12rem 0.3rem 0.12rem;
  background: #f7f7f7 !important;
  overscroll-behavior-y: contain;
}

.wrapper.inDesktop {
  width: 100%;
  padding: 0;
  padding-top: 0.6rem;
}

.wrapper .header {
  display: flex;
  justify-content: space-between;
  height: 0.48rem;
  line-height: 0.48rem;
  font-size: 0.15rem;
  border-bottom: 0.01rem solid var(--gray2);
}

.wrapper .closeButton {
  width: 0.32rem;
  height: 0.32rem;
  line-height: 0.32rem;
}

.wrapper .closeIcon {
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.32rem;
}

.wrapper .wishButton {
  width: 0.32rem;
  height: 0.32rem;
  line-height: 0.32rem;
  margin-right: 0.05rem;
}

.wrapper .wishIcon {
  font-size: 0.3rem;
  font-weight: bold;
  line-height: 0.3rem;
}

.wrapper .header .rightWrapper {
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}

.contentbody {
  display: flex;
  justify-content: center;
}

.contentbody .rightContent {
  width: 4.24rem;
  height: 7rem;
  min-height: 6.38rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0.6rem;
  z-index: calc(var(--z-index-aside) - 1);
}

.contentbody .leftContent {
  width: 7.44rem;
  margin-right: 0.3rem;
}

.leftContent .freeShippingHintBox {
  padding: 0.2rem 0.3rem;
  margin-bottom: 0.3rem;
}

.leftContent .freeShippingHintBox .freeShippingHighlight {
  background-color: #FEE4B2;
  padding: 0.08rem 0.2rem;
  color: var(--neutral900) !important;
}

.leftContent .freeShippingHintBox .carIcon {
  width: 0.4rem;
  height: 0.4rem;
  font-size: 0.3rem;
  line-height: 0.4rem;
  text-align: center;
  font-weight: 200;
}

.leftContent .freeShippingHintBox .hintText {
  color: var(--neutral900);
  font-weight: normal;
  font-size: 0.18rem;
}

.leftContent .freeShippingHintBox .addBtn {
  font-weight: 500;
  font-size: 0.18rem;
  text-decoration: underline;
}

.leftContent .freeShippingHintBox .addBtn span {
  text-decoration: none;
}

.emptyContent {
  flex: 1 1;
  width: 100%;
  background-color: var(--white);
}

.rightContent .acceptionsBox {
  background-color: var(--white);
  padding: 0.26rem 0.3rem 0.16rem 0.3rem;
}

div.securityCertification {
  background-color: #fff;
  padding: 0.26rem 0.3rem 0.215rem;
}

div.purchaseProtection {
  background-color: #fff;
  padding: 0 0.3rem 0.26rem;
}

div.securityCertification .safeDesc,
div.purchaseProtection .safeDesc {
  color: var(--neutral600);
}

.rightContent .securityCertification .cardTitle::before,
.rightContent .purchaseProtection .cardTitle::before {
  position: unset;
  background-repeat: no-repeat;
  background-size: 0.24rem;
  width: 0.24rem;
  height: 0.24rem;
  line-height: 0.24rem;
  text-align: center;
  font-size: 0.24rem;
  color: var(--safe-green);
  -webkit-transform: unset;
  transform: unset;
  margin-right: 0.12rem;
  font-weight: normal;
}

.rightContent .securityCertification .cardTitle,
.rightContent .purchaseProtection .cardTitle {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  color: var(--neutral900);
}

.rightContent .securityCertification .cardGroup {
  display: grid;
  grid-template-columns: repeat(6, 0.56rem);
  grid-row-gap: 0.08rem;
  row-gap: 0.08rem;
  grid-column-gap: 0.04rem;
  -webkit-column-gap: 0.04rem;
  column-gap: 0.04rem;
  padding-top: 0.13rem;
}