.wrapper {
  padding: 0.08rem 0.12rem;
  background: white;
  display: flex;
  color: var(--color-gray);
  white-space: nowrap;
}

.wrapper.inDesktop {
  padding: 0.2rem 0 0.12rem;
  margin-bottom: -0.08rem;
}

.wrapper .link {
  display: flex;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.14rem;
  color: var(--neutral900);
  opacity: 0.5;
  overflow: visible;
}

.wrapper .link:not(:last-child):after {
  content: ">";
  margin: 0 0.2rem;
}

.wrapper .nolink {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.24rem;
}

.wrapper.inDesktop .link:not(:last-child)::after {
  content: ">";
  margin: 0 0.1rem;
}

.wrapper.inDesktop .nolink,
.wrapper.inDesktop .link {
  overflow: visible;
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: #999999;
}

.wrapper .link:last-child {
  color: var(--neutral900);
}