.buoyWrapper {
  bottom: calc(var(--fixed-footer-bottom) + 1.3rem);
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: var(--z-index-modal);
  right: 0.1rem;
}

.buoyWrapper .icon {
  width: 0.4rem;
  height: 0.4rem;
  background: white;
  border-radius: 50%;
  border: 0.02rem solid var(--neutral900);
  font-size: 0.34rem;
  color: var(--neutral900);
  text-align: center;
  line-height: 0.34rem;
}

.buoyWrapper .count {
  position: absolute;
  top: -0.04rem;
  right: -0.02rem;
  min-width: 0.16rem;
  height: 0.16rem;
  padding: 0.02rem 0.03rem 0 0.03rem;
  font-size: 0.1rem;
  line-height: 0.14rem;
  text-align: center;
  color: #FFFFFF;
  background: #EE1A1A;
  border-radius: 0.2rem;
}

.addCart {
  color: #EE1A1A;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.14rem;
  position: absolute;
  top: -0.12rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-name: showAddCart;
  animation-name: showAddCart;
}

@-webkit-keyframes showAddCart {
  from {
    top: -0.12rem;
  }

  to {
    top: -0.3rem;
  }
}

@keyframes showAddCart {
  from {
    top: -0.12rem;
  }

  to {
    top: -0.3rem;
  }
}