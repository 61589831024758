.pc.wrapper {
  background: none;
}

.pc.wrapper::before {
  background: none;
}

.pc.wrapper .btnWrapper {
  margin-top: -4rem;
}

.pc.wrapper .secondary {
  margin-bottom: 0.4rem;
}

.wrapper {
  background-color: white;
  background-size: 3.75rem 6.67rem;
  height: calc(100vh - 0.4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper .logo {
  content: ' ';
  background-image: url('../../components/Shell/Header/resources/Logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 2rem;
  height: 1.54rem;
}

.button {
  width: 2.95rem;
  height: 0.44rem;
  background: #FF7878;
  border-radius: 0.22rem;
  font-size: 0.17rem;
  color: #FFFFFF;
  letter-spacing: -0.0041rem;
  text-align: center;
}

.secondary {
  width: 3.35rem;
  height: 0.42rem;
  border: 0.01rem solid var(--neutral900);
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.42rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--neutral900);
  background-color: #fff;
  border-radius: 0;
}

.fbBtn {
  margin-top: 0.18rem;
  width: 3.35rem;
  height: 0.42rem;
  background: var(--neutral900);
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.42rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border-radius: 0;
}

.btnWrapper {
  width: 3.75rem;
  text-align: center;
  margin-bottom: 0.2rem;
}