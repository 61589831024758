.wrapper {
  padding-top: 0.2rem;
}

.wrapper .specBox {
  padding: 0 0.12rem 0.1rem;
}

.wrapper .specBox .specLabel {
  margin-bottom: 0.1rem;
  color: var(--neutral500);
  font-size: 0.12rem;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.wrapper .specBox .specLabel.inDesktop {
  font-size: 0.14rem;
}

.wrapper .specBox .specItemBox {
  display: flex;
  width: 3.51rem;
  align-items: flex-start;
  align-content: flex-start;
  grid-gap: 0.1rem;
  gap: 0.1rem;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-bottom: 0.1rem;
}

.wrapper .specBox .specItemBtn {
  display: inline-flex;
  align-items: center;
  height: 0.28rem;
  background-color: var(--white);
  border-radius: 0.17rem;
  border: 0.01rem solid var(--neutral300);
  padding: 0 0.15rem;
  box-sizing: border-box;
  font-size: 0.12rem;
  font-weight: 500;
  color: var(--neutral900);
}

.wrapper .specBox .specItemBtn.inDesktop {
  font-size: 0.14rem;
}

.wrapper .specBox .specItemBtn.leng {
  padding: 0 0.12rem;
}

.wrapper .specBox .specItemBtn.active {
  border: 0.01rem solid var(--neutral900);
}

.wrapper .specBox .specItemBtn .dot {
  display: inline-block;
  width: 0.12rem;
  height: 0.12rem;
  border: 0.01rem solid var(--neutral200);
  border-radius: 50%;
  margin-right: 0.04rem;
}

.wrapper .specBox .specItemBtn .dot img {
  float: left;
}

.wrapper .specFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 0.01rem solid var(--neutral200);
  padding: 0.1rem 0.12rem;
  box-sizing: border-box;
}

.wrapper .specFooter .button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.06rem 0.3rem;
  box-sizing: border-box;
  height: 0.32rem;
  font-size: 0.14rem;
  font-weight: 600;
  color: var(--neutral900);
  line-height: normal;
  text-transform: uppercase;
}

.wrapper .specFooter .button.clear {
  background-color: var(--white);
  border: 0.01rem solid var(--neutral400);
}

.wrapper .specFooter .button.done {
  margin-left: 0.1rem;
  background-color: var(--neutral900);
  color: var(--white);
}