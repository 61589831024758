.wrapper {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wrapper .banner {
  min-height: 1.42rem;
  flex-basis: 100%;
  background-color: #EDF6EB;
  padding: 0.2rem 0.12rem;
}

.wrapper .banner .title {
  display: flex;
  align-items: center;
}

.wrapper .banner .title > span {
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: var(--safe-green);
}

.wrapper:not(.isDesktop) .banner .title::before {
  content: "";
  flex-shrink: 0;
  background-image: url('./resources/cart.svg');
  background-size: 0.5rem;
  background-repeat: no-repeat;
  width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  font-size: 0.5rem;
  margin-right: 0.16rem;
  color: var(--safe-green);
}

.wrapper .banner .subTitle {
  margin-top: 0.12rem;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--safe-green);
}

.wrapper .body {
  flex-basis: 3.75rem;
  padding: 0 0.12rem;
  overflow: hidden;
}

.wrapper .body .title {
  margin-top: 0.12rem;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: var(--neutral900);
}

.wrapper .body .desc {
  margin-top: 0.12rem;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  position: relative;
  padding-left: 0.08rem;
}

.wrapper .body .desc::before {
  content: ".";
  position: absolute;
  top: -0.05rem;
  left: 0;
  font-weight: bold;
  font-size: 0.18rem;
  color: var(--neutral900);
}

.wrapper .image {
  flex-basis: 2.48rem;
  height: 2.23rem;
  margin: 0.2rem 0;
}

.wrapper.isDesktop {
  padding-bottom: 0.32rem;
}

.wrapper.isDesktop .banner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.32rem;
}

.wrapper.isDesktop .banner .bannerInfo {
  position: relative;
  min-width: 7rem;
  padding-left: 0.65rem;
}

.wrapper.isDesktop .banner .bannerInfo .title::before {
  content: "\e667";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  font-size: 0.5rem;
  color: var(--safe-green);
}

.wrapper.isDesktop .body {
  flex-basis: 4.22rem;
  padding: 0;
}

.wrapper.isDesktop .image {
  margin: 0;
  margin-left: 0.3rem;
}