.header {
  height: 1rem;
  width: 100%;
  color: var(--neutral900);
  background: white;
  border-bottom: 0.01rem solid var(--gary);
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.header .title {
  height: 0.6rem;
  width: 2.3rem;
  display: block;
  position: absolute;
  top: 0.2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: url('./resources/Logo.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  text-indent: -100%;
}

@media (max-width: 768px) {
  .header .title {
    left: 0;
    -webkit-transform: unset;
    transform: unset;
  }
}

.header .searchBar {
  width: 80%;
  height: 0.36rem;
  border: 0.01rem solid #979797;
  max-width: 4.85rem;
  opacity: 0.4;
  margin-bottom: 0.08rem;
}

.header .searchBar .innerSearchBar {
  height: 100%;
  font-size: 0.14rem;
  line-height: 0.36rem;
}

.header img {
  max-width: 1.2rem;
}

.rightWrapper {
  width: 100vw;
  background: #f7f8fa;
}

.iconWrapper {
  display: flex;
  margin-left: 0.22rem;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0.3rem;
}

.iconWrapper .active {
  background-color: white;
}

.icon {
  display: flex;
  width: 0.56rem;
  height: 0.9rem;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon:hover {
  background: rgba(255, 255, 255, 0.2);
}

.icon.user::before {
  display: block;
  content: '\e628';
  font-weight: 600;
  margin-top: 0.02rem;
  font-size: 0.26rem;
  color: var(--neutral900);
}

.icon.cart {
  min-width: 0.56rem;
  width: auto;
}

.icon.cart::before {
  display: block;
  content: '\e61c';
  font-size: 0.36rem;
  color: var(--neutral900);
}

.icon.wishlist::before {
  display: block;
  content: '\e617';
  font-size: 0.36rem;
  color: var(--neutral900);
}

.icon.currency::before {
  display: block;
  content: '';
  width: 0.3rem;
  height: 0.3rem;
  background: url('https://img.flamingo.shop/p/files/Worldwide_1x.png?v=1600237732') no-repeat center center;
  background-size: 100%;
}

.country {
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: #000000;
}

.icon .count {
  margin-left: 0.1rem;
  min-width: 0.1rem;
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: #000000;
}

.cart.icon .count {
  margin-left: -0.04rem;
  margin-right: 0.04rem;
}

.floatPanel {
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0.06rem 0.06rem 0 rgba(0,0,0,.08);
  z-index: var(--z-index-aside);
  padding: 0.12rem 0.18rem;
  min-width: 3.3rem;
  color: var(--neutral900);
}

.currencyPanel .section {
  color: var(--neutral900);
  font-size: 0.14rem;
  font-weight: 700;
}

.currencyPanel .section:first-of-type {
  margin-bottom: 0.18rem;
}

.currencyPanel .section h5 {
  display: flex;
  font-size: 0.12rem;
  margin-top: 0.12rem;
}

.currencyPanel h5.usd::before {
  content: '';
  width: 0.3rem;
  height: 0.2rem;
  background: url('https://img.flamingo.shop/p/files/Lark20200916-052744.png?v=1600248467') no-repeat center center;
  background-size: 100%;
  margin-right: 0.04rem;
}

.floatPanel.cart {
  padding: 0;
  overflow: hidden;
  right: -0.3rem;
  z-index: calc(var(--z-index-aside) + 1);
}

.floatPanel.cart.fixTop {
  position: fixed;
  top: 0;
  right: 0;
}