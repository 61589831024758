.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-size: 100%;
  color: white;
}

.desktop {
  margin-bottom: 0.7rem;
}

.image {
  width: 100vw;
}