.wrapper {
  padding-bottom: var(--safe-area-inset-bottom);
}

.page {
  position: relative;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.cartOpen {
  overflow: hidden;
}

.cartOpen .content {
  overflow: hidden;
  height: 100vh;
}

.cartOpen .content > * {
  pointer-events: none;
}

.page .coverCart {
  width: 3rem;
  /* height: 100vh; */
  bottom: 0;
  height: auto;
  position: fixed;
  top: 0;
  right: -3rem;
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  opacity: 0;
  z-index: var(--z-index-aside);
  border-left: 0.01rem solid var(--gray);
  overflow: auto;
  padding-top: var(--safe-area-inset-top);
}

.cartOpen .coverCart {
  -webkit-transform: translate(-3rem);
  transform: translate(-3rem);
  opacity: 1;
}