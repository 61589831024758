.wrapper {
  width: 3rem;
  background-color: white;
}

.wrapper.toolTip {
  position: fixed;
  width: 3rem;
  background-color: white;
  bottom: 0.1rem;
  right: 0.6rem;
  z-index: 999999;
}

.tipTitle {
  width: 100%;
  line-height: 0.4rem;
  text-align: center;
  background-color: black;
  font-size: 0.16rem;
  color: white;
  font-weight: 700;
}

.tipContent {
  padding: 0.1rem;
}

.desc1 {
  font-size: 0.14rem;
  line-height: 0.25rem;
  text-align: center;
  font-weight: 500;
  color: #4a4a4a;
}

.priceOff {
  font-size: 0.34rem;
  font-weight: 700;
  color: #d10000;
  position: relative;
  margin-top: 0.1rem;
  text-align: center;
  line-height: 0.3rem;
}

.tipApp {
  display: flex;
}

.appQrCode {
  width: 1.3rem;
  height: 1.3rem;
  background-size: 100%;
  margin: 0.15rem;
}

.line {
  height: 1.3rem;
  margin-top: 0.15rem;
  border-left: 0.01rem dashed #000;
}

.downApp {
  padding: 0.15rem;
  justify-content: center;
}

.downAndroid {
  margin-top: 0.2rem;
  display: flex;
  line-height: 0.4rem;
  background: white;
}

.downAndroid::before {
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/android.png?v=1599730184') no-repeat center center;
  background-size: 0.4rem 0.4rem;
  margin-right: 0.04rem;
}

.downIphone {
  margin-top: 0.2rem;
  line-height: 0.4rem;
  display: flex;
  background: white;
}

.downIphone::before {
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  background: url('https://img.flamingo.shop/p/files/iphone_94678678-c0fb-46f4-a68d-6a61d504155f.png?v=1599730184') no-repeat center center;
  background-size: 0.4rem 0.4rem;
  margin-right: 0.04rem;
}