.switchBtn {
  position: relative;
  width: 1.25rem;
  height: 0.53rem;
  display: flex;
  align-items: center;
}

.switchBtn .bg {
  width: 1.25rem;
  height: 0.44rem;
  background: #EFEFEF;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.18rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  letter-spacing: -0.004rem;
  color: #000000;
}

.switchBtn .selected {
  position: absolute;
  top: 0;
  width: 0.7rem;
  height: 0.53rem;
  border-radius: 0.4rem;
  background-color: #fff;
  border: 0.03rem solid #E3E3E3;
  box-sizing: border-box;
  /* transition: all 0.3s ease; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  letter-spacing: -0.004rem;
  color: #000000;
}

.switchBtn.right .selected {
  left: 0.54rem;
}

.switchBtn.left .selected {
  left: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper:not(.isDesktop) .tableBox {
  width: 3.27rem;
  overflow-x: scroll;
  padding: 0.2rem 0;
}

.wrapper:not(.isDesktop) .tableBox::-webkit-scrollbar {
  display: none;
}

.wrapper:not(.isDesktop) .tableBox .table {
  margin: 0 auto;
}

.tableBox .trElement:nth-child(1) span,
.tableBox .tdElement:first-child span {
  font-weight: 600;
  color: var(--neutral900);
}

.tableBox .trElement.active,
.tableBox .tdElement.active {
  background-color: #f1f1f1;
}

.tableBox .tdElement.highlight {
  background-color: #dcdcdc;
}

.tableBox .trElement:nth-child(1) .tdElement,
.tableBox .tdElement:first-child {
  background: #F7F8FA;
}

.tableBox .tdElement {
  height: 0.4rem;
  padding: 0 0.06rem;
}

.wrapper:not(.isDesktop).isSmallSize .tableBox .tdElement {
  min-width: 0.65rem;
  padding: 0 0.06rem;
}

.tableBox .table,
.tdElement {
  border: 0.01rem solid #E6E6E6;
}

.tableBox .tdElement div {
  display: flex;
  align-items: center;
  height: 100%;
}

.tableBox .tdElement div span {
  width: 100%;
  font-weight: 500;
  font-size: 0.12rem;
  color: #333333;
  text-align: center;
  line-height: 0.2rem;
  white-space: nowrap;
}

.guideTips {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  letter-spacing: -0.004rem;
  color: #999999;
  margin-bottom: 0.28rem;
}

.guideInfo {
  width: 100%;
  position: relative;
  padding-right: 1.23rem;
  padding-top: 0.18rem;
  border-top: 0.01rem solid #E6E6E6;
}

.guideInfo .guideImage {
  position: absolute;
  top: 0.12rem;
  right: 0;
  width: 1.15rem;
  height: 2.29rem;
}

.guideInfo h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 0.12rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  margin-bottom: 0.06rem;
}

.guideInfo h3.infoHeader {
  margin-bottom: 0.08rem;
}

.guideInfo p {
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #333333;
  margin-bottom: 0.08rem;
}

.wrapper.isDesktop > div:first-child {
  align-self: flex-start;
}

.wrapper.isDesktop .switchBtn {
  width: 0.92rem;
  height: 0.36rem;
  cursor: pointer;
}

.wrapper.isDesktop .switchBtn .bg {
  width: 0.92rem;
  height: 0.32rem;
  display: flex;
  padding: 0 0.14rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #000000;
}

.wrapper.isDesktop .switchBtn .selected {
  position: absolute;
  top: 0;
  width: 0.44rem;
  height: 0.33rem;
  border-radius: 0.4rem;
  background-color: #fff;
  border: 0.02rem solid #E3E3E3;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-align: center;
  color: #000000;
}

.wrapper.isDesktop .switchBtn.right .selected {
  left: 0.48rem;
}

.wrapper.isDesktop .tableBox {
  width: 100%;
  padding: 0.27rem 0;
}

.wrapper.isDesktop .tableBox .table {
  width: 100%;
}

.wrapper.isDesktop .trElement:nth-child(1) span,
.wrapper.isDesktop .tdElement:first-child span {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.wrapper.isDesktop .tdElement {
  height: 0.54rem;
  padding: 0;
}

.wrapper.isDesktop .tdElement div span {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: var(--neutral900);
}

.wrapper.isDesktop .guideTips {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.14rem;
  color: #999999;
  margin-bottom: 0.26rem;
}

.wrapper.isDesktop .guideInfo {
  padding-right: 1.12rem;
  padding-top: 0.26rem;
  padding-bottom: 0.9rem;
}

.wrapper.isDesktop .guideInfo .guideImage {
  position: absolute;
  top: 0.3rem;
  right: 0;
  width: 1.12rem;
  height: 2.22rem;
}

.wrapper.isDesktop .guideInfo h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: var(--neutral900);
  margin-bottom: 0.14rem;
}

.wrapper.isDesktop .guideInfo h3.infoHeader {
  font-size: 0.16rem;
  margin-bottom: 0.22rem;
}

.wrapper.isDesktop .guideInfo p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #333333;
  margin-bottom: 0.15rem;
}