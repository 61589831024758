.rc-slider {
  position: relative;
  height: 0.14rem;
  padding: 0.05rem 0;
  width: calc(100% - 0.26rem);
  margin-left: 0.13rem;
  border-radius: 0.06rem;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 0.02rem;
  border-radius: 0.06rem;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 0.02rem;
  border-radius: 0.06rem;
  background-color: var(--neutral900);
}

.rc-slider-handle {
  position: absolute;
  top: -0.06rem;
  width: 0.26rem;
  height: 0.26rem;
  cursor: pointer;
  cursor: grab;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 0.08rem 0 #00000026;
  touch-action: pan-x;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: transparent;
}

.rc-slider-handle:focus {
  outline: none;
}

.rc-slider-handle-click-focused:focus {
  border-color: transparent;
}

.rc-slider-handle:hover {
  border-color: transparent;
}

.rc-slider-handle:active {
  border-color: transparent;
  cursor: grabbing;
}

.rc-slider-mark {
  position: absolute;
  top: 0.18rem;
  left: 0;
  width: 100%;
  font-size: 0.12rem;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 0.02rem;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -0.02rem;
  margin-left: -0.04rem;
  width: 0.08rem;
  height: 0.08rem;
  border: 0.02rem solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot-active {
  border-color: var(--neutral900);
}

.rc-slider-dot-reverse {
  margin-right: -0.04rem;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}

.rc-slider-vertical {
  width: 0.14rem;
  height: 100%;
  padding: 0 0.05rem;
}

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 0.04rem;
}

.rc-slider-vertical .rc-slider-track {
  left: 0.05rem;
  bottom: 0;
  width: 0.04rem;
}

.rc-slider-vertical .rc-slider-handle {
  margin-left: -0.05rem;
  touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 0.18rem;
  height: 100%;
}

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 0.04rem;
}

.rc-slider-vertical .rc-slider-dot {
  left: 0.02rem;
  margin-bottom: -0.04rem;
}

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -0.04rem;
}

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -0.04rem;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
  animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
  animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -99.99rem;
  top: -99.99rem;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 0.04rem 0 0.08rem 0;
}

.rc-slider-tooltip-inner {
  padding: 0.06rem 0.02rem;
  min-width: 0.24rem;
  height: 0.24rem;
  font-size: 0.12rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 0.06rem;
  box-shadow: 0 0 0.04rem #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 0.04rem;
  left: 50%;
  margin-left: -0.04rem;
  border-width: 0.04rem 0.04rem 0;
  border-top-color: #6c6c6c;
}