.searchRecommendSections .section {
  padding: 0.12rem;
  background: white;
  font-size: 0.13rem;
  line-height: 0.15rem;
}

.searchRecommendSections .section .sectionTitle {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.searchRecommendSections .section .sectionTitle .clearIcon {
  width: 0.12rem;
  height: 0.15rem;
  background: url("./resources/trash-can.png") no-repeat right center;
  background-size: 100%;
}

.searchRecommendSections .section .content {
  display: flex;
  flex-wrap: wrap;
}

.searchItem {
  font-size: 0.11rem;
  display: inline-block;
  padding: 0.04rem 0.08rem;
  margin: 0 0.08rem 0.08rem 0;
  border: 0.01rem solid var(--gray);
  border-radius: 0.18rem;
  color: #666;
  cursor: pointer;
}

.searchItem.hot::before {
  display: block;
  content: "";
  width: 0.09rem;
  height: 100%;
  background: url("./resources/hot.png") no-repeat center center;
  background-size: 100%;
  float: left;
  margin-right: 0.03rem;
}