.wrapper {
  min-height: 100vh;
  background-color: rgba(246, 246, 246, 1);
}

.personCenterWrapper {
  margin-bottom: 0.08rem;
}

.personCenterWrapper.noMargin {
  margin-bottom: 0;
}

.card,
.noLoginCard {
  position: relative;
  width: 100%;
  height: 1.18rem;
  display: flex;
  padding: 0.3rem 0.24rem 0.2rem 0.24rem;
  padding-top: calc(var(--safe-area-inset-top) + 0.28rem);
  background-color: #fff;
}

.noLoginCard {
  align-items: center;
}

.noLoginCard .noLoginCardTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 0.28rem;
  color: var(--neutral900);
  text-transform: uppercase;
}

.noLoginCard::after {
  content: '\e612';
  font-size: 0.1rem;
  font-weight: bold;
  font-style: normal;
  color: rgba(26, 26, 26, 1);
  margin-left: 0.1rem;
}

.card::after {
  content: '\e612';
  position: absolute;
  top: calc(var(--safe-area-inset-top) + 0.54rem);
  right: 0.12rem;
  font-size: 0.1128rem;
  font-weight: bold;
  font-style: normal;
  color: rgba(26, 26, 26, 1);
}

.noLoginCard .avatar,
.card .avatar {
  width: 0.68rem;
  height: 0.68rem;
  border-radius: 50%;
  margin-right: 0.1rem;
  flex-shrink: 0;
  background-color: #f6f6f6;
}

.noLoginCard .avatar img,
.card .avatar img {
  width: 100%;
  height: 100%;
}

.card .rightWrapper {
  padding: 0.12rem 0;
  font-size: 0.13rem;
  line-height: 0.15rem;
  background-size: 0.14rem 0.17rem;
}

.card .rightWrapper .user {
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
}

.card .rightWrapper .name {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 0.18rem;
  line-height: 0.2rem;
  color: var(--neutral900);
  min-width: 0.01rem;
  max-width: 1.66rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card .rightWrapper .vip {
  margin-left: 0.04rem;
  width: 0.57rem;
  height: 0.16rem;
}

.card .rightWrapper.noName .vip {
  margin-left: 0;
  margin-bottom: 0.05rem;
}

.card .rightWrapper .desc {
  font-style: normal;
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.14rem;
  color: var(--neutral900);
}

.downloadGuideCard {
  width: 100%;
  height: 0.5rem;
  background-color: #fff;
  box-shadow: inset 0 -0.03rem 0.05rem 0 rgb(122 62 6 / 6%);
}

.downloadGuideCard .content {
  position: relative;
  height: 100%;
  margin: 0 0.12rem;
  background: url('https://img.flamingo.shop/p/show/bfb9aeea4f27418b9fc9735f6728a84e.png') no-repeat center center;
  background-size: contain;
  display: flex;
  align-items: center;
}

.downloadGuideCard .content p {
  margin-left: 0.12rem;
  font-weight: 400;
  font-size: 0.1rem;
  line-height: 0.14rem;
}

.downloadGuideCard .content .highlighted {
  font-weight: 600;
}

.downloadGuideCard .content .btn {
  padding: 0 0.16rem;
  height: 0.23rem;
  line-height: 0.23rem;
  font-size: 0.12rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  background: var(--neutral900);
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.downloadGuideCard .content .close {
  position: absolute;
  top: 0;
  right: 0;
  background: #D9D9D9;
  width: 0.3rem;
  height: 0.3rem;
  font-size: 0.16rem;
  display: flex;
  border-bottom-left-radius: 100%;
  padding-left: 0.08rem;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  color: #fff;
  border-top-right-radius: 0.1rem;
}

.section {
  background-color: #fff;
  height: 1.28rem;
  overflow: hidden;
  margin-bottom: 0.08rem;
}

.section .header {
  height: 0.4rem;
  padding: 0 0.12rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.16rem;
  color: var(--neutral900);
}

.section .header .viewMore {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: right;
  letter-spacing: -0.003rem;
  color: #333333;
}

.section .header .viewMore::after {
  content: '\e612';
  font-size: 0.1rem;
  font-weight: bold;
  font-style: normal;
  color: rgba(26, 26, 26, 1);
  margin-left: 0.04rem;
}

.section .content {
  padding: 0 0.12rem;
  display: flex;
  justify-content: space-between;
}

.sectionItem .imageWrapper {
  display: flex;
  justify-content: center;
  width: 0.36rem;
  height: 0.36rem;
  margin: 0 auto 0.08rem;
  position: relative;
}

.sectionItem .imageWrapper .count {
  display: block;
  position: absolute;
  top: -0.09rem;
  left: 0.27rem;
  min-width: 0.18rem;
  height: 0.18rem;
  padding: 0 0.03rem;
  border-radius: 0.1rem;
  font-size: 0.1rem;
  line-height: 0.18rem;
  color: #fff;
  text-align: center;
  background: rgba(26, 26, 26, 1);
}

.sectionItem .image {
  width: 0.36rem;
  height: 0.36rem;
  margin: 0 auto;
}

.sectionItem .title {
  width: 0.7rem;
  height: 0.36rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-style: normal;
  font-weight: 400;
  font-size: 0.13rem;
  line-height: 0.18rem;
  text-align: center;
  letter-spacing: -0.002rem;
  color: var(--neutral900);
  word-break: break-all;
}

.plainItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.12rem;
  background-color: #fff;
}

.plainItem .image {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.12rem;
}

.plainItem .title {
  font-style: normal;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.17rem;
  color: #333333;
}

.plainItem::after {
  content: '\e612';
  font-size: 0.1rem;
  font-weight: bold;
  font-style: normal;
  color: rgba(26, 26, 26, 1);
}

.countryIcon {
  width: 0.26rem;
  height: 0.26rem;
  margin-right: 0.105rem;
  margin-left: -0.025rem;
}

.liStyle {
  display: flex;
  padding: 0.16rem 0.12rem;
}