.wrapper {
  background-color: #fff;
  margin-top: 0.68rem;
}

.wrapper.isDesktop {
  margin-top: 0;
}

.relatedSearches {
  padding: 0 0.12rem;
  background-color: #fff;
}

.relatedSearches.isDesktop {
  background-color: #fff;
  padding: 0;
}

.relatedSearches > div {
  display: inline-block;
  height: 0.22rem;
  color: #666;
  line-height: 0.22rem;
  padding: 0 0.1rem;
  box-sizing: border-box;
  border: 0.005rem solid #999;
  margin-right: 0.12rem;
  margin-bottom: 0.12rem;
  font-size: 0.12rem;
  box-sizing: content-box;
}

.relatedSearches.isDesktop > div {
  height: 0.24rem;
  line-height: 0.24rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  border: 0.01rem solid #999;
  cursor: pointer;
  padding: 0 0.12rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  color: #666666;
}

.relatedSearches.isDesktop > div:hover {
  border-color: var(--neutral900);
  color: #333;
}

.header {
  position: relative;
  padding: 0.12rem 0.12rem 0.08rem;
  background-color: #fff;
}

.header .title {
  font-size: 0.14rem;
  color: var(--neutral900);
  font-weight: bold;
  line-height: 0.18rem;
}

.header .noComment {
  font-size: 0.12rem;
  line-height: 0.16rem;
  font-weight: 500;
}