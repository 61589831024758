.wrapper {
  position: relative;
}

.emptyCartPlay {
  background: #FFF;
  width: 100%;
  padding: 0.1rem 0.12rem 0.2rem 0.12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyCartPlay.isDesktop {
  width: 3.5rem;
  margin: 0 auto;
  padding: 0.6rem 0 0 0;
}

.emptyCartPlay .icon {
  background-size: 0.45rem;
  width: 0.45rem;
  height: 0.45rem;
}

.emptyCartPlay.isDesktop .icon {
  background-size: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
}

.iconCartClass {
  background-image: url('https://img.flamingo.shop/p/show/3e628c8fd2e8416491d0bb510a880b3b.png');
}

.emptyCartPlay.isDesktop .title {
  margin-top: 0.2rem;
  font-size: 0.16rem;
}

.emptyCartPlay .title {
  margin-top: 0.1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: normal;
  text-align: center;
  color: var(--neutral900);
}

.emptyCartPlay .desc {
  margin-top: 0.05rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: normal;
  text-align: center;
  mix-blend-mode: normal;
  color: var(--neutral500);
}

.emptyCartPlay.isDesktop .desc {
  font-size: 0.14rem;
}

.childrenWrapper {
  border-top: 0.08rem solid #f5f5f5;
}

.emptyCartPlay .shopBtn {
  margin-top: 0.1rem;
  max-width: 2.85rem;
  width: auto;
  padding: 0 0.2rem;
  height: 0.36rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  border-radius: 0;
  background: var(--white);
  color: var(--neutral900);
  border: 0.01rem solid var(--neutral900);
  text-align: center;
  text-transform: uppercase;
}

.emptyCartPlay .shopBtn.primary {
  background: var(--neutral900);
  color: var(--neutral50);
}

.emptyCartPlay .shopBtn.unLoginPrimary {
  margin-top: 0.08rem;
  background: inherit;
  color: var(--neutral900);
  border: 0.01rem solid var(--neutral900);
  width: 2.85rem;
}

.emptyCartPlay.isDesktop .shopBtn.unLoginPrimary {
  margin-top: 0.08rem;
}

.emptyCartPlay .shopBtn .btn {
  border-radius: unset;
}

.emptyCartPlay.isDesktop .shopBtn {
  margin-top: 0.2rem;
  width: 2.85rem;
  height: 0.36rem;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.2rem;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
}

.emptyCartPlay.isDesktop .shopBtn > div {
  line-height: 0.44rem;
}

.signInOrRegister {
  margin-top: 0.1rem;
  background: var(--neutral900);
  color: var(--neutral50);
  width: 2.85rem;
  height: 0.36rem;
  line-height: 0.36rem;
  font-weight: 600;
  font-size: 0.14rem;
  font-style: normal;
  border-radius: 0;
  text-align: center;
  text-transform: uppercase;
}

.signInOrRegister.isDesktop {
  margin-top: 0.2rem;
}