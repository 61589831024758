.page {
  padding: 0.65rem 0.12rem 0.2rem 0.12rem;
  background-color: #fff;
}

.page.isDesktop {
  background-color: #fff;
}

.page.notFound {
  padding-top: 0.65rem;
}

.page h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: normal;
  text-align: center;
  color: var(--neutral500);
  mix-blend-mode: normal;
  text-align: center;
}

.page.isDesktop h1 {
  margin: 0 auto;
  width: 3.5rem;
  font-size: 0.14rem;
  line-height: normal;
  text-align: center;
}

.page .btnWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.page button {
  display: flex;
  padding: 0.08rem 0.2rem;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 0.14rem;
  font-weight: 600;
  line-height: 0.2rem;
  border-radius: 0;
  max-width: 2.85rem;
  width: auto;
  height: 0.36rem;
  background: inherit;
  color: var(--neutral900);
  border: 0.01rem solid var(--neutral900);
  flex: 1 1 auto;
}

.page button:first-child {
  background: var(--neutral900);
  color: var(--neutral50);
}

.page.notFound {
  background: #fff url('./resources/404.png') no-repeat center top 0.1rem;
  background-size: 0.45rem;
}

.page.error {
  background: #fff url('./resources/500.png') no-repeat center top 0.1rem;
  background-size: 0.45rem;
}

.page.isDesktop.notFound {
  background: #fff url('./resources/404.png') no-repeat center top 0.6rem;
  background-size: 0.6rem;
}

.page.isDesktop.error {
  background: #fff url('./resources/500.png') no-repeat center top 0.6rem;
  background-size: 0.6rem;
}

.page.isDesktop.notFound {
  padding: 1.4rem 0.2rem 0.6rem 0.2rem;
}

.page.isDesktop.error {
  padding: 1.4rem 0.2rem 0.6rem 0.2rem;
}

.page.isDesktop .btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page.isDesktop button {
  flex-grow: 0;
  width: 2.85rem;
  height: 0.36rem;
  font-style: normal;
  border-radius: 0;
  margin-top: 0;
  font-weight: 600;
  font-size: 0.14rem;
  line-height: 0.36rem;
}

.page button:nth-child(2) {
  margin-top: 0.08rem;
}

.page .withOneButtonInRow {
  margin-top: 0.1rem;
}

.page.isDesktop .withOneButtonInRow {
  width: 6.13rem;
  margin-top: 0.2rem;
}

.page .withTwoButtonInRow {
  margin-top: 0.1rem;
}

.page.isDesktop .withTwoButtonInRow {
  width: 100%;
  margin-top: 0.2rem;
}

.withTwoButtonInRow button {
  width: 2.85rem;
}