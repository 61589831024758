.wish {
  font-size: 0.3rem;
}

.wish.noBackground {
  font-size: 0.35rem;
}

.wish::after {
  margin-right: 0.1rem;
  content: "\e617";
  font-size: inherit;
  text-align: center;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background-color: rgba(82, 82, 82, 0.7);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.wish.wished::after {
  margin-right: 0.1rem;
  content: "\e615";
  font-size: inherit;
  text-align: center;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background-color: rgba(82, 82, 82, 0.7);
  border-radius: 50%;
  color: #fff;
}

.wish.noBackground::after {
  font-size: inherit;
  width: 0.35rem;
  height: 0.35rem;
  line-height: 0.35rem;
  color: #000;
  background-color: transparent;
}

.wish.noBackground.wished::after {
  font-size: inherit;
  width: 0.35rem;
  height: 0.35rem;
  line-height: 0.35rem;
  color: var(--neutral900);
  background-color: transparent;
}

.wish.inDesktop::after {
  margin-right: 0;
  content: "\e617";
  font-size: 0.51rem;
  text-align: center;
  display: block;
  width: 0.51rem;
  height: 0.51rem;
  line-height: 0.51rem;
  color: #000;
}

.wish.wished.inDesktop::after {
  margin-right: 0;
  content: "\e615";
  font-size: 0.51rem;
  text-align: center;
  display: block;
  width: 0.51rem;
  height: 0.51rem;
  line-height: 0.51rem;
  color: #fc6d6d;
}