.categoryPage {
  background: white;
  display: flex;
  --category-height: calc(100vh - 0.8rem - 0.54rem);
}

.categoryPage .navigateGroup {
  width: 1rem;
  background: #F5F6F7;
  height: var(--category-height);
  overflow-y: scroll;
  /* padding-bottom: var(--fixed-footer-bottom); */
  padding-bottom: 2rem;
}

.categoryPage .LayoutContent {
  width: 2.81rem;
  padding: 0.12rem;
  height: var(--category-height);
  overflow-y: scroll;
  padding-bottom: 2rem;
}

.categoryPage .navigateGroup::-webkit-scrollbar {
  display: none;
}

.categoryPage .navigateGroup .navigateItem {
  width: 100%;
  height: 0.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-transform: capitalize;
  color: var(--neutral900);
  word-break: break-word;
  display: flex;
  align-items: center;
  padding: 0 0.1rem;
}

.categoryPage .navigateGroup .navigateItem.active {
  background-color: #fff;
}

.navigateLink {
  color: var(--primary);
}

.LayoutContent::-webkit-scrollbar {
  display: none;
}

.noScroll {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}