.stay {
  scroll-behavior: unset;
}

.collectionTag:not(.isDesktop) {
  display: inline-block;
  max-width: 1.7rem;
  min-height: 0.18rem;
  background: #FEF7E1;
  border-radius: 0.02rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.1rem;
  line-height: 0.18rem;
  color: #B8802C;
  padding: 0 0.04rem;
}

.collectionTag.isDesktop {
  display: inline-block;
  width: auto;
  min-height: 0.16rem;
  background: #FEF7E1;
  border-radius: 0.02rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.18rem;
  color: #B8802C;
  margin-top: 0.15rem;
  padding: 0 0.06rem;
}