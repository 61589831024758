.loading {
  height: 4.8rem;
}

.loadingList {
  background: var(--white);
  height: 0.95rem;
  width: 3.59rem;
  margin: 0.08rem;
  border-radius: 0.08rem;
}