.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 0.06rem;
}

.wrapper::after {
  content: url('./resources/arrow-bold.svg');
  position: absolute;
  right: 0.1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wrapper.disabled::after {
  content: '';
}

.wrapper .image {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.06rem 0 0 0.06rem;
}

.wrapper .rightWrapper {
  background: white;
  flex: 1 1;
  max-width: calc(100% - 0.6rem);
  padding: 0.07rem 0.2rem 0.07rem 0.1rem;
  border-radius: 0 0.06rem 0.06rem 0;
}

.wrapper .title {
  flex: 1 1;
  font-size: 0.14rem;
  line-height: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #707070;
  margin-bottom: 0.04rem;
  white-space: normal;
  font-weight: normal;
}

.wrapper .priceWrapper {
  display: flex;
}

.wrapper .priceWrapper .price {
  color: var(--primary);
  font-size: 0.16rem;
  line-height: 0.22rem;
  font-weight: 600;
  margin-right: 0.1rem;
}

.wrapper .priceWrapper .originalPrice {
  font-size: 0.14rem;
  line-height: 0.22rem;
  color: var(--gray4);
  text-decoration: line-through;
}