.discountWrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  width: var(--flashWidth);
  overflow: hidden;
  --flashWidth: 0.4rem;
  --triangleHeight: 0.11rem;
}

.discountWrapper .discount {
  width: var(--flashWidth);
  height: var(--flashWidth);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFE14D;
  position: relative;
  z-index: var(--z-index-flow-clickable);
}

.discountWrapper .discount .text {
  -webkit-transform: translateY(calc(var(--triangleHeight)/2));
  transform: translateY(calc(var(--triangleHeight)/2));
  margin-bottom: 0.03rem;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.13rem;
  color: var(--neutral900);
}

.discountWrapper .discount .icon {
  -webkit-transform: translateY(calc(var(--triangleHeight)/2));
  transform: translateY(calc(var(--triangleHeight)/2));
  font-size: 0.18rem;
  line-height: 0.18rem;
  color: var(--neutral900);
}

.discountWrapper .triangle {
  -webkit-transform: translate(-0.02rem, -0.01rem);
  transform: translate(-0.02rem, -0.01rem);
  width: 0;
  height: 0;
  border-top: var(--triangleHeight) solid #FFE14D;
  border-bottom: var(--triangleHeight) solid transparent;
  border-right: 0.22rem solid transparent;
  border-left: 0.22rem solid transparent;
}

/* 尺寸改变 */

.discountWrapper:not(.isDesktop) {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}

.discountWrapper.small:not(.isDesktop) {
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.discountWrapper.small.isDesktop {
  -webkit-transform: scale(0.775);
  transform: scale(0.775);
}

/* 其他组件 */

.flashIcon {
  color: #FFC834;
  font-size: 0.15rem;
  line-height: 0.15rem;
  display: inline-block;
}

.flashDiscountRowWrapper {
  display: flex;
  align-items: center;
  margin-top: 0.08rem;
}

.flashDiscountRowWrapper .desc {
  color: #D20000;
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.12rem;
  margin-right: 0.08rem;
}

.flashDiscountRowWrapper .discount {
  background: #FFE14D;
  width: 0.34rem;
  height: 0.16rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flashDiscountRowWrapper .discount span {
  font-weight: 600;
  font-size: 0.2rem;
  font-style: normal;
  color: var(--neutral900);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.flashDiscountRowWrapper.isDesktop .desc {
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.14rem;
  margin-right: 0.1rem;
}

.flashDiscountRowWrapper.isDesktop .discount {
  width: 0.4rem;
  height: 0.21rem;
}

.flashDiscountRowWrapper.isDesktop .discount span {
  font-size: 0.2rem;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}