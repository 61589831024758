.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.9;
  padding: 0 0.12rem;
  background-color: var(--primary);
  background-size: 100%;
  color: white;
}

.wrapper .title {
  font-size: 0.21rem;
  font-weight: bold;
  max-width: calc(100% - 1.1rem);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.countdownContainer {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0.16rem;
  color: white;
  line-height: 0.16rem;
}

.countdownContainer .countdownText {
  font-size: 0.12rem;
  line-height: 0.16rem;
  font-weight: bold;
  margin-bottom: 0.04rem;
}

.countdown span {
  font-weight: bold;
  line-height: 0.2rem;
}

.ribbon {
  color: inherit;
}