.icon {
  background: url('./resources/address_unchecked.svg') no-repeat;
  background-size: contain;
  width: 0.18rem;
  height: 0.18rem;
  cursor: pointer;
}

.icon.radio.selected {
  background-image: url('./resources/address_checked.svg');
}

.icon.checkbox.selected {
  background-image: url('https://img.flamingo.shop/p/show/43d2475b596a46acb290171b137f947d.png');
  background-color: var(--neutral900);
  border-radius: 50%;
}