.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: black;
}

.wrapper .video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper .maskWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
}

.wrapper .maskWrapper .header {
  margin: 0.25rem 0.25rem 0 0.25rem;
  display: flex;
  justify-content: space-between;
}

.wrapper .maskWrapper .header .pagination {
  font-size: 0.18rem;
  font-weight: 600;
}

.wrapper .maskWrapper .header .right {
  display: flex;
  align-items: center;
}

.wrapper .maskWrapper .header .right .voice {
  width: 0.26rem;
  height: 0.26rem;
  margin-right: 0.26rem;
  background: url('https://img.flamingo.shop/p/show/46c4dff14824439d89dc1e310c946d3a.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .maskWrapper .header .right .voice.muted {
  background: url('https://img.flamingo.shop/p/show/a884d4d28c56407d9ae22f5a58f682f1.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .maskWrapper .header .right .close {
  width: 0.26rem;
  height: 0.26rem;
  background: url('https://img.flamingo.shop/p/show/5cf9003dda4047779278fe794ac52428.png') no-repeat right center;
  background-size: 100% 100%;
}

.wrapper .maskWrapper .paused {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0.8rem;
  height: 0.8rem;
  background: url('https://img.flamingo.shop/p/show/579b4950583e4cc7b5664c60e071b813.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .maskWrapper .paginationArrow .left {
  position: absolute;
  top: 50%;
  left: 0.12rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.36rem;
  height: 0.36rem;
  background: url('https://img.flamingo.shop/p/show/e225827292ff408e8939efb1c94842de.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .maskWrapper .paginationArrow .right {
  position: absolute;
  top: 50%;
  right: 0.12rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.36rem;
  height: 0.36rem;
  background: url('https://img.flamingo.shop/p/show/5af1e1acf3f84c92a77fd2d5a746ad8d.png') no-repeat center center;
  background-size: 100% 100%;
}

.wrapper .maskWrapper .progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.04rem;
  overflow: hidden;
}

.wrapper .maskWrapper .progress .outerBar {
  background: rgba(255,255, 255, 0.3);
  border-radius: 0;
}

.wrapper .maskWrapper .progress .innerBar {
  background: white;
  border-radius: 0;
}