.wrapper {
  width: 100%;
  position: relative;
}

.wrapper .panel {
  background-image: url('./resources/bj-_1_.webp');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.wrapper.us .panel {
  background-image: url('./resources/us-bj-_1_.webp');
  background-size: cover;
}

.wrapper .bgTitle {
  font-size: 0.18rem;
  font-weight: 800;
  line-height: 0.2rem;
  letter-spacing: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 0.36rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: var(--neutral900);
  z-index: 1;
  white-space: nowrap;
}

.wrapper.us .bgTitle {
  color: transparent;
}

.wrapper .closeBtn {
  position: absolute;
  right: 0.16rem;
  top: 0.36rem;
  width: 0.12rem;
  height: 0.12rem;
  color: white;
  cursor: pointer;
}

.wrapper.us .closeBtn {
  top: 0;
  color: #acacac;
}

.wrapper.hideEmail .bgTitle,
.wrapper.hideEmail .closeBtn {
  top: 0.3rem;
}

.panel {
  padding: 0.2rem 0.12rem 0.26rem;
  width: 2.95rem;
  height: 100%;
  position: relative;
  overflow: visible;
  border-radius: 0.16rem;
}

.panel form {
  margin-top: 0.6rem;
  width: 100%;
  overflow: visible;
}

.wrapper.us .panel form {
  margin-top: 0.7rem;
}

.panel.success {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-name: panelSlideIn;
  animation-name: panelSlideIn;
}

.includes {
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  letter-spacing: -0.005rem;
  text-transform: uppercase;
  color: var(--neutral900);
  margin-bottom: 0.18rem;
}

.mainInput {
  color: #111;
  height: 0.34rem;
  line-height: 0.16rem;
  font-size: 0.12rem;
  width: 100%;
  background-color: var(--white);
  padding: 0 0.12rem;
  text-align: left;
  margin-bottom: 0.07rem;
}

.mainInput::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.mainInput:-ms-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.mainInput::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.submitBtn {
  background: #000000;
  -webkit-backdrop-filter: blur(0.02rem);
  backdrop-filter: blur(0.02rem);
  width: 100%;
  height: 0.4rem;
  font-weight: 700;
  font-size: 0.16rem;
  line-height: 0.4rem;
  text-align: center;
  text-transform: uppercase;
  color: #FEE3BD;
  border-radius: 0.21rem;
}

.wrapper.hideEmail .submitBtn {
  margin-top: 0.2rem;
}

.wrapper.us .submitBtn {
  color: #FEE3BD;
  background: #000000;
}

.privacyWrapper {
  width: 120%;
  font-style: normal;
  font-weight: 400;
  height: 0.16rem;
  font-size: 0.12rem;
  line-height: 0.16rem;
  letter-spacing: -0.005rem;
  color: white;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: left;
  transform-origin: left;
  margin-bottom: 0.16rem;
}

.wrapper.us .privacyWrapper {
  color: white;
}

.underline {
  text-decoration: underline;
}

.couponContainer {
  margin: 0.1rem 0 0.1rem 0;
}

.couponContainer::-webkit-scrollbar {
  display: none;
}

.couponBox {
  width: 100%;
  margin: 0.05rem auto 0.05rem;
  border-radius: 0.06rem;
  overflow: hidden;
}

.couponInfo {
  padding: 0.06rem 0.1rem 0;
  margin-bottom: -0.03rem;
}

.couponInfo .couponTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 0.23rem;
  line-height: 0.27rem;
  color: #EE1A1A;
}

.couponInfo .couponSubtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #EE1A1A;
}

.couponDesc {
  padding: 0 0.1rem 0.06rem;
  margin-top: -0.05rem;
}

.couponDesc .couponDate {
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: var(--neutral600);
  margin-bottom: 0.02rem;
  -webkit-transform: scale(.83);
  transform: scale(.83);
  -webkit-transform-origin: left;
  transform-origin: left;
  white-space: nowrap;
}

.couponDesc .couponDate.highlighted {
  color: #EE1A1A;
}

.couponDesc .couponRule {
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.12rem;
  color: var(--neutral600);
  -webkit-transform: scale(.83);
  transform: scale(.83);
  -webkit-transform-origin: left;
  transform-origin: left;
  white-space: nowrap;
}