.wrapper .section {
  background: white;
  margin-bottom: 0.08rem;
}

.wrapper .sectionWithPadding {
  padding: 0.16rem 0.12rem;
}

.wrapper .image {
  width: 3.75rem;
  height: 3.75rem;
}

.flexSection {
  display: flex;
}

.flexSection>* {
  margin-right: 0.06rem;
}

.skeleton {
  height: 0.12rem;
}

.pcMainContent {
  display: flex;
  margin-top: 0.5rem;
}

.smallImages {
  flex-basis: 0.76rem;
  display: inline-flex;
  flex-direction: column;
}

.smallImages .smallImage {
  width: 0.66rem;
  height: 0.99rem;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
}

.wrapper.isDesktop {
  margin: 0;
  padding: 0 0.25rem 0.4rem;
  margin: 0 auto;
  width: 11.98rem;
}

.wrapper.isDesktop .image {
  flex-basis: 7.02rem;
  padding-top: 65%;
}

.wrapper:not(.isDesktop) {
  background-color: #fff;
}

.wrapper:not(.isDesktop) .sectionWithPadding {
  padding: 0.1rem 0.12rem 0.16rem;
}

.wrapper:not(.isDesktop) .section {
  margin: 0;
}

.wrapper:not(.isDesktop) .specialPadding {
  overflow: hidden;
  margin: 0;
  padding: 0 0.12rem;
  padding-bottom: 0.14rem;
}

.wrapper:not(.isDesktop) .skeleton.price {
  margin: 0.11rem 0;
}

.tips {
  height: 0.3rem;
  color: #0e0e10;
  font-size: 0.14rem;
  line-height: 0.3rem;
}

.main {
  width: 3.75rem;
  display: flex;
  overflow-x: scroll;
  padding-bottom: 0.02rem;
}

.main::-webkit-scrollbar {
  display: none;
}

.main .mainImage {
  flex-shrink: 0;
  width: 2.48rem;
  height: 3.72rem;
  margin-right: 0.08rem;
}

.wrapper.isDesktop .rightSection {
  width: 4.2rem;
  height: 9.16rem;
  margin-left: 0.12rem;
  flex-shrink: 0;
}

@media (max-width: 1366px) {
  .wrapper.isDesktop {
    width: 100%;
  }
}

@media (min-width: 1074px) and  (max-width: 1366px) {
  .wrapper.isDesktop {
    width: 9.92rem;
  }
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .wrapper.isDesktop {
    width: 10.91rem;
  }
}