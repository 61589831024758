.wrapper {
  overflow-y: scroll;
  width: 3.75rem;
  background: white;
  padding: 0 0.12rem var(--fixed-footer-bottom) 0.12rem;
}

.wrapper.isDesktop {
  z-index: 2;
  position: absolute;
  margin-top: 0.04rem;
  width: 2.75rem;
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.1);
  padding: 0;
}

.wrapper.isDesktop.translated {
  margin-top: -0.01rem;
}

.wrapper .itemWrapperClass {
  justify-content: center;
}