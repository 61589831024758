.wrapper {
  display: block;
  position: relative;
}

.wrapper.inDesktop {
  width: auto;
}

.wrapper .cardWrapper {
  overflow: hidden;
  width: 1.03rem;
  height: 2.05rem;
  background-color: white;
}

.wrapper.inDesktop .cardWrapper {
  overflow: hidden;
  width: auto;
  height: auto;
}

.wrapper .imageWrapper {
  position: relative;
}

.wrapper .imageWrapper .cardImage {
  width: auto;
  height: auto;
}

.wrapper .imageWrapper .cardImage img {
  display: inline;
}

.wrapper .imageWrapper .cardImage.inDesktop {
  height: auto;
  width: auto;
  border-radius: 0;
}

.wrapper .price {
  align-items: center;
  width: 100%;
  background-color: white;
  height: 0.51rem;
}

.wrapper.inDesktop .price {
  height: 0.52rem;
  display: flex;
  align-items: center;
}

.wrapper .dealCardFlashIcon {
  margin: 0.05rem 0.02rem 0 0.05rem;
}

.wrapper.inDesktop .dealCardFlashIcon {
  margin: 0 0.12rem 0 0.06rem;
  font-size: 0.16rem;
  line-height: 0.26rem;
}

.wrapper .price .salePrice {
  font-style: normal;
  font-weight: bold;
  font-size: 0.14rem;
  line-height: 0.2rem;
  color: #000;
  margin-bottom: 0.01rem;
}

.wrapper.inDesktop .price .salePrice {
  margin-bottom: 0;
}

.wrapper.inDesktop .price .salePrice.highLight {
  color: #CC0000;
}

.wrapper .price .salePrice.highLight {
  color: #CC0000;
}

.wrapper.inDesktop .price {
  padding: 0;
}

.wrapper.inDesktop .price .salePrice {
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  text-transform: capitalize;
  color: var(--neutral900);
  margin-left: 0.1rem;
  display: flex;
  align-items: center;
}

.wrapper .price .originalPrice {
  font-size: 0.12rem;
  line-height: 0.16rem;
  margin-left: 0.22rem;
  color: #888888;
  text-decoration: line-through;
  font-weight: 400;
}

.wrapper.inDesktop .price .originalPrice {
  margin-left: 0.1rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.12rem;
  line-height: 0.16rem;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  text-transform: capitalize;
  color: #808080;
}

@media (max-width: 1200px) {
  .wrapper.inDesktop .price {
    height: 0.52rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}