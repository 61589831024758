.wrapper {
  margin-left: 0.12rem;
  margin-right: 0.12rem;
}

.wrapper.inDesktop {
  margin: 0;
  position: relative;
}

.itemWrapper {
  width: 3rem;
  margin-top: 0.05rem;
}

.itemWrapper.inDesktop {
  width: auto;
  margin-top: 0.2rem;
}

.itemHeader {
  width: 100%;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 0.2rem;
  font-size: 0.14rem;
  cursor: pointer;
  margin: 0.1rem 0 0.15rem 0;
}

.itemHeader.inDesktop {
  height: 0.18rem;
  padding-top: 0;
  font-size: 0.14rem;
  margin: 0;
}

.itemHeader .title {
  font-weight: 600;
  color: var(--neutral900);
}

.clean {
  width: 0.15rem;
  height: 0.15rem;
  background: url("https://img.flamingo.shop/p/files/clean.png?v=1608543730") no-repeat;
  background-size: 100%;
}

.itemHeader .clean {
  position: absolute;
  top: 0.03rem;
  right: 0.28rem;
  padding-right: 0.05rem;
}

.cleanBox {
  width: 100%;
  height: 0.2rem;
  margin-bottom: 0.12rem;
  display: flex;
  justify-content: flex-end;
}

.cleanBox .clean {
  width: 0.2rem;
  height: 0.2rem;
}

.itemHeader .arrowIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.16rem;
  height: 0.16rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.2rem;
  text-transform: capitalize;
  color: var(--neutral400);
}

.itemWrapper.inDesktop {
  position: absolute;
  top: 0.17rem;
  left: 0;
  width: 1.88rem;
  height: 0.16rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.itemHeader.inDesktop .arrowIcon {
  top: 0;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  width: 0.16rem;
  height: 0.16rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.18rem;
  text-transform: capitalize;
  color: var(--neutral900);
  text-align: right;
}

.itemHeader .upArrow.inDesktop {
  top: 0.02rem;
}

.upArrow.active {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rangeInfo {
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.priceInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.22rem;
}

.priceStart {
  font-size: 0.12rem;
  color: var(--neutral900);
  line-height: 0.16rem;
}

.priceEnd {
  font-size: 0.12rem;
  color: var(--neutral900);
  line-height: 0.16rem;
}

.filterRange span {
  color: red;
}