.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}