.wrapper {
  /* position: absolute; */
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 3.78rem;
  padding-top: 0.6rem;
  padding-bottom: 0.2rem;
  background-color: #fafafa;
}

.row {
  display: flex;
  max-width: 12.2rem;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  flex-wrap: wrap;
}

.ftLeftWrapper {
  flex: 1 1;
}

.leftTopWrapper {
  display: flex;
}

.leftBottomWrapper {
  margin-top: 0.7rem;
}

.companyTitle {
  width: 100%;
  color: #666;
  font-weight: 600;
  font-size: 0.12rem;
}

.companyInfoItem {
  padding: 0 0.1rem;
  color: #999;
  font-size: 0.12rem;
  line-height: 0.12rem;
  text-decoration: underline;
  margin-bottom: 0.1rem;
  border-right: 0.01rem solid #ccc;
}

.companyInfoItem:first-child {
  padding-left: 0;
}

.companyInfoItem:last-of-type {
  border: 0;
}

.leftItem {
  padding-bottom: 0.6rem;
  padding-right: 0.5rem;
}

.itemTitle {
  font-size: 0.15rem;
  color: var(--neutral900);
  font-weight: 700;
}

.itemChildTitle {
  display: flex;
  color: #666;
  font-size: 0.14rem;
  line-height: 0.23rem;
}

.ftRightWrapper {
  flex: 1 1;
}

.rightTopWrapper {
  display: flex;
}

.rightSocialWrapper {
  flex: 6 1;
}

.socialIcon {
  margin-top: 0.2rem;
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.2rem;
}

.rightAppWrapper {
  flex: 4 1;
  margin-left: 1rem;
}

.rightBottom {
  margin-top: 0.25rem;
}

.image {
  margin-top: 0.1rem;
  margin-right: 0.1rem;
  height: 0.32rem;
  width: 0.512rem;
}

.rightMiddle {
  margin-top: 0.25rem;
}

.rightMiddle .itemTitle {
  text-transform: uppercase;
}

.inputEmailWrapper {
  margin-top: 0.1rem;
  display: flex;
  height: 0.36rem;
}

.emailForm {
  width: 4.5rem;
  height: 0.36rem;
  padding: 0 0.12rem;
  outline: 0;
  border: 0.01rem solid #ccc;
}

.emailForm input {
  width: 100%;
  line-height: 0.36rem;
  padding: 0 0.12rem;
}

.submitBtn {
  width: 1.5rem;
  text-align: center;
  height: 0.36rem;
  line-height: 0.36rem;
  background: #222;
  color: white;
  font-weight: 700;
}