.wrapper {
  display: block;
  background-color: var(--white);
}

.wrapper.inDesktop {
  display: flex;
  flex-direction: column;
  background: white;
}

.mainWrapper {
  display: flex;
  margin-top: 0.57rem;
}

.headerWrapper {
  height: 0.58rem;
}

.header {
  display: flex;
  height: 0.58rem;
  width: 100%;
  justify-content: space-between;
  background: #f6f6f6;
  align-items: center;
  padding: 0 0.4rem;
}

.header.isImageTags {
  padding: 0;
}

.header.noFilter {
  width: 97.7%;
}

.header .leftInfo {
  display: flex;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header .leftInfo .title {
  line-height: 0.5rem;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 0.16rem;
  text-transform: capitalize;
  color: #333333;
}

.header .leftInfo .goodsNum {
  position: relative;
  line-height: 0.5rem;
  margin-left: 0.1rem;
  margin-right: 0.12rem;
  white-space: nowrap;
  font-weight: 400;
  font-size: 0.12rem;
  text-transform: capitalize;
  color: #999999;
  -webkit-transform: translateY(0.02rem);
  transform: translateY(0.02rem);
}

.leftArea {
  width: 2.2rem;
  background: white;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0.2rem;
  left: 0;
  flex-shrink: 0;
}

.leftArea.noFilter {
  width: unset;
}

.filterContent {
  z-index: 1;
}

.rightArea {
  width: calc(100% - 2.18rem);
  padding-left: 0.68rem;
}

.rightArea.noFilter {
  width: 100%;
  padding-left: 0.1rem;
}

.line {
  color: #ccc;
}

.titleBar {
  background: white;
  display: flex;
  height: 0.365rem;
  padding-left: 0.14rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.365rem;
  text-transform: capitalize;
  color: var(--neutral900);
}

.titleBar .title {
  margin-right: 0.12rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.12rem;
  text-align: center;
  text-transform: capitalize;
  color: var(--neutral900);
}

.titleBar .goodsNum {
  color: #999;
  font-size: 0.12rem;
}

.readMoreBar {
  background: #fff;
  font-size: 0.12rem;
  text-align: center;
  padding-bottom: 0.08rem;
}

.desktopContainer {
  padding: 0.3rem 0.8rem;
}

.collectionTagsBox {
  background: #f6f6f6;
  padding-bottom: 0.2rem 0;
}

.wrapper.isImageTags .collectionTagsBox,
.wrapper.isImageTags .headerWrapper .header {
  background-color: #fff;
}

.wrapper.isImageTags .headerWrapper .header .title {
  margin: 0;
}

.stickyBox {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: calc(var(--z-index-flow-clickable) - 1);
}

.stickyBox.showHeader {
  top: var(--sticky-header-top);
}

.stickyBox.tiny {
  -webkit-transform: translateY(-0.52rem);
  transform: translateY(-0.52rem);
}

.white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation-name: comeIn;
  animation-name: comeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  z-index: calc(var(--z-index-flow-clickable) + 2);
}

@-webkit-keyframes comeIn {
  from {
    height: 1.2rem;
    background-color: #fff;
  }

  to {
    height: 0;
    background-color: unset;
  }
}

@keyframes comeIn {
  from {
    height: 1.2rem;
    background-color: #fff;
  }

  to {
    height: 0;
    background-color: unset;
  }
}

.out {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation-name: goOut;
  animation-name: goOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  z-index: calc(var(--z-index-flow-clickable) + 2);
}

@-webkit-keyframes goOut {
  from {
    height: 1.2rem;
    background-color: #fff;
  }

  to {
    height: 0;
    background-color: unset;
  }
}

@keyframes goOut {
  from {
    height: 1.2rem;
    background-color: #fff;
  }

  to {
    height: 0;
    background-color: unset;
  }
}