.wrapper {
  position: relative;
  -webkit-animation: showAnimation 0.6s linear;
  animation: showAnimation 0.6s linear;
}

@-webkit-keyframes showAnimation {
  from {
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes showAnimation {
  from {
    -webkit-transform: translateY(-2rem);
    transform: translateY(-2rem);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: url('./resources/supplement_card.webp');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 4.72rem;
  border-radius: 0.2rem;
}

.wrapper.us::after {
  background-image: url('./resources/us-bj-_1_.webp');
  background-size: contain;
}

.wrapper .bgTitle {
  font-size: 0.18rem;
  font-weight: 800;
  line-height: 0.2rem;
  letter-spacing: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: -0.18rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: var(--neutral900);
  white-space: nowrap;
}

.wrapper.us .bgTitle {
  color: transparent;
}

.wrapper .card {
  width: 2.95rem;
  padding: 0.2rem 0.12rem 0.26rem;
}

.wrapper .card .header {
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.2rem;
  text-align: center;
  color: white;
  position: relative;
  z-index: var(--z-index-layer-1);
}

.wrapper.us .card .header {
  color: transparent;
}

.wrapper .card .submitBtn {
  width: 100%;
  height: 0.4rem;
  color: #FEE3BD;
  background: black;
  -webkit-backdrop-filter: blur(0.02rem);
  backdrop-filter: blur(0.02rem);
  border-radius: 0.2rem;
  margin-top: 0.11rem;
  text-transform: uppercase;
  font-size: 0.16rem;
  display: block;
  margin: 0 auto;
  font-weight: 700;
}

.wrapper.us .card .submitBtn {
  color: #FEE3BD;
  background: #000000;
}

.wrapper .card .link {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  text-align: center;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #CCCCCC;
  margin-top: 0.13rem;
}

.closeBtn {
  background-image: url('https://img.flamingo.shop/p/show/b77f048116664996b0cca5731adf05ae.png');
  background-size: contain;
  top: -0.5rem;
  width: 0.16rem;
  height: 0.16rem;
  z-index: calc(var(--z-index-modal) + 99999);
  opacity: 0.5;
}

.wrapper .card .container {
  width: 100%;
  height: 3.1rem;
  margin: 0.1rem auto;
  overflow-y: scroll;
}

.wrapper .card .container.withEmailInput {
  max-height: 2.22rem;
}

.wrapper .card .container::-webkit-scrollbar {
  display: none;
}

.wrapper .card .container .images {
  width: inherit;
  margin: 0 auto;
}

.wrapper .card .container .couponImage {
  margin-bottom: 0.1rem;
}

.wrapper .couponBox {
  width: 100%;
  margin-bottom: 0.1rem;
  border-radius: 0.06rem;
  overflow: hidden;
}

.wrapper .card .couponInfo {
  padding: 0.06rem 0.1rem 0;
  margin-bottom: -0.03rem;
}

.wrapper .card .couponInfo .couponTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 0.21rem;
  line-height: 0.21rem;
  color: #EE1A1A;
  margin-bottom: 0.02rem;
}

.wrapper .card .couponInfo .couponSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: #EE1A1A;
}

.wrapper .card .couponDesc {
  padding: 0 0.1rem 0.06rem;
  margin-top: -0.05rem;
}

.wrapper .card .couponDesc .couponDate {
  font-weight: 500;
  font-size: 0.1rem;
  line-height: 0.12rem;
  color: #666666;
  margin-bottom: 0.02rem;
}

.wrapper .card .couponDesc .couponDate.highlighted {
  color: #EE1A1A;
}

.wrapper .card .couponDesc .couponRule,
.wrapper .card .couponDesc .couponMaxOff {
  font-style: normal;
  font-weight: 500;
  font-size: 0.1rem;
  line-height: 0.14rem;
  color: #666666;
}

.wrapper .card .couponDesc .couponMaxOff {
  margin-bottom: 0.02rem;
}

.mainInput {
  color: #111;
  height: 0.34rem;
  line-height: 0.16rem;
  font-size: 0.12rem;
  width: 100%;
  background-color: var(--white);
  padding: 0 0.12rem;
  text-align: left;
  margin-bottom: 0.07rem;
}

.mainInput::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.mainInput:-ms-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.mainInput::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 0.12rem;
  line-height: 0.16rem;
  color: var(--neutral300);
}

.privacyWrapper {
  width: 120%;
  font-style: normal;
  font-weight: 400;
  height: 0.16rem;
  font-size: 0.12rem;
  line-height: 0.16rem;
  letter-spacing: -0.005rem;
  color: white;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: left;
  transform-origin: left;
  margin-bottom: 0.16rem;
}

.underline {
  text-decoration: underline;
}